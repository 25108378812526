:root {
  --border: #161c2b;
  --brown: #f0ebe1;
  --black: #372717;
  --gray: #756658;
}

.faq-link {
  color: #40a9ff !important;
}
.faq-link:hover {
  text-decoration: underline !important;
}
/* FAQ */
.l-container {
  /* max-width: 800px; */
  /* width: 500px; */
  margin-top: 0;
}

.accordion-item {
  /* background-color: #fff; */
  border: none !important;
  margin: 0.5rem 0 !important;
}

.align-center {
  display: block;
  margin: 0 auto;
}

.align-right {
  float: right;
  overflow: hidden;
}

.accordion {
  padding: 0px 0;
  color: #fff;
}

.accordion-title {
  text-align: center;
  text-align: center;
  color: #000;
  font-size: 60px;
  position: relative;
}

.accordion-logo {
  display: inline-block;
  vertical-align: middle;
  width: 45px;
}

.accordion-list {
  margin: 30px;
  padding-left: 0;
  list-style: none;
}

.accordion-button {
  background: #161c2b !important;
  padding: 0.5rem 0.5rem;
  font-size: 1.1rem !important;
  line-height: 1.4;
  color: #ccc;
  border: 1px solid #161c2b;
  box-shadow: 0px 0px 4px 5px #161c2b;
  /* border-left: none; */
  /* border-right: none;
  border-bottom: none; */
  position: relative;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
  transform: rotate(-180deg);
}

.accordion-button:not(:first-of-type) {
  border-top: none;
}

.accordion-button:after {
  content: "+";
  font-size: 30px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.accordion-button.is-active:after {
  content: "-";
}

.accordion-panel {
  display: none;
  background: var(--border);
  color: #fff;
  text-align: left;
  overflow: hidden;
  padding: 0 1rem;
  font-size: 0.9rem;
}

.accordion-panel ul {
  padding: 0 1rem;
  list-style: none;
}

.accordion-panel ul li {
  margin: 0.5rem 0;
  line-height: 1.6;
}

.accordion-panel p {
  margin: 10px 0;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  font-size: 0.95rem;
  background-color: var(--border);
  box-shadow: -1px 0px 4px 5px #161d30;
  /* box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125); */
}
@media (max-width: 780px) {
  .accordion-list {
    margin-top: 24px 11px;
    padding-left: 0;
    list-style: none;
  }
  .accordion-button:after {
    content: "+";
    margin-right: -0.8rem !important;
    display: inline-block;
  }
  .accordion-button > span {
    padding-right: 1rem;
  }
}

/* *:after, *:before
box-sizing inherit */

.accordion {
  width: 100%;
  max-width: 86%;
}
.panel {
  background-color: #161c2b;
}
.panel__label {
  position: relative;
  display: block;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 8px 60px 8px 25px;
  font-weight: 500;
  font-size: 17px;
  font-family: inherit;
  transition: color 0.2s linear;
  cursor: pointer;
}
.panel__label:focus {
  outline: none;
}
.panel__label:after,
.panel__label:before {
  content: "";
  position: absolute;
  right: 25px;
  top: 50%;
  width: 14px;
  height: 2px;
  margin-top: -2px;
  background-color: #ffffff;
}
.panel__label:before {
  transform: rotate(-90deg);
  transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.panel[aria-expanded="true"] .panel__content {
  opacity: 1;
}
.panel[aria-expanded="true"] .panel__label {
  color: #ffffff;
}
.panel[aria-expanded="true"] .panel__label:before {
  transform: rotate(0deg);
}
.panel__inner {
  overflow: hidden;
  will-change: height;
  transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.panel__content {
  margin: 5px 40px 25px;
  font-size: 0.9rem;
  font-weight: 400;
  opacity: 0;
  transition: opacity 0.3s linear 0.18s;
}
.panel:not(:last-child) {
  margin-bottom: 3px;
}

.panel__content ul,
.panel__content ul li {
  list-style: disc !important;
}

.panel__content ol,
.panel__content ol li {
  list-style: decimal !important;
}

@media (max-width: 782px) {
  .remove-max-width {
    max-width: unset;
  }
  .panel__content ul {
    padding-left: 0;
  }
}
