
.kyc-page {
  /* background-color: #07163e;
  font-family: "Inter"; */
  // background-color: var(--white);
}

#kyc-hr {
  margin: 0;
  height: 3px;
  width: 100%;
  background-color:var(--color-primary);
}

.step-indicator-container,
.step-name-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step-indicator-container {
  position: relative;
  top: -50%;
}

.step-name-container {
  min-width: 102%;
  margin-left: -1%;
  font-size: 12px;
  font-weight: bold;
  color: var(--color-dark-grey);
}
.step-name {
  margin-top: -10px;
}
.step-name.active {
  color: var(--color-primary);
}

.step-indicator {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--white);
  border: var(--primary-border);
}

.step-indicator.active {
  background-color: var(--color-primary);
}

#kyc-form.form-container {
  @include whiteBoxStyle;
  border: var(--light-border);
  margin-top: 4vh;
  padding: 3rem;
  border-radius: 20px;
  margin-bottom: 3rem;
}

.form-sections-container {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
}
.kyc-second-page.form-sections-container {
  flex-direction: column !important;
}
.form-sections-container#second-step {
  width: 80%;
}

.form-section {
  max-width: 25vw;
}

.form-section.three {
  max-width: 10vw;
  position: relative;
}

.form-section.three > button {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.kyc-page .ant-form-item-control-input-content .ant-input,
.kyc-page .ant-form-item-control-input-content .ant-picker {
  // border: none ;
  // border-bottom: 1px solid #29489b ;
  background-color: transparent;
  color: var(--color-dark-grey);
  outline: none;
}

.kyc-page .ant-form-item-control-input-content .ant-picker {
  width: 100%;
}

.kyc-page .ant-form-item-control-input-content .ant-picker:hover {
  background-color: var(--white);
  color: var(--color-dark-grey);
}

.kyc-page .ant-form-item-control-input-content .ant-input:not(:empty),
.kyc-page .ant-form-item-control-input-content .ant-input:focus {
}

#residenceCountry,
.kyc-page .ant-form-item-control-input-content .ant-input:focus,
.kyc-page
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector:focus,
.kyc-page .ant-form-item-control-input-content .ant-picker #dob {
  // outline: none !important;
  // border: none;
  // box-shadow: none;
}

.kyc-page .ant-row.ant-form-item {
  margin-bottom: 4px;
}

.kyc-page .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-group-addon {
  background-color: transparent;
  color: var(--color-dark-grey);
  // border: none;
  // border-bottom: 1px solid #29489b !important;
  // box-shadow: none !important;
}

#residenceCountry,
#prefix {
  margin-top: -6px;
  padding-left: 10px;
}

.ant-select-item-option,
.ant-select-selection-search-input {
  border: 0px !important;
  outline: none !important;
  box-shadow: none !important;
  // background-color: #07163e;
  // color: white;
}

.ant-select-item-option:hover,
.ant-select-item-option-active {
  // background-color: white;
  // color: #07163e;
}

.rc-virtual-list {
  // background-color: #07163e;
}
.pt-120pxx {
  padding-top: 120px;
}
.kyc-page h2 {
  margin-bottom: 2rem;
  margin-top: 1.2rem;
  font-size: 2rem;
}
// .kyc-page button {
//   background: linear-gradient(
//     0deg,
//     hsla(244, 28%, 46%, 1) 0%,
//     hsla(294, 46%, 53%, 1) 100%
//   );
//   padding: 7px 12px;
//   border: none;
//   border-radius: 5px;
//   color: white;
// }

.kyc-page input[type="file"]::-webkit-file-upload-button {
  border-radius: 6px;
  border: none;
}

.file-field-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.file-field-container .title {
  font-weight: 500;
  margin-bottom: 0;
  font-size: 15px;
}

.file-field-container .subtitle {
  font-weight: 300;
  font-size: 12px;
}

.file-field-container#address {
  margin-bottom: 10vh;
}

.cta-container {
  position: relative;
  margin-top: 10vh;
}

.buttons-container {
  position: absolute;
  right: 0;
  bottom: 20px;
}

#confirm-button {
  margin-left: 30px;
}
#kyc-form .form-section {
  padding: 0;
}
#kyc-form h5 {
  color: var(--color-dark-grey) ;
  font-size: 0.95rem;
}
#kyc-form input::placeholder,
#kyc-form .ant-select-selection-item {
  // color: #ccc !important;
}
@media (max-width: 1087px) {
  .form-sections-container > .one,
  .form-sections-container > .two {
    max-width: 100%;
    margin: 0 1rem;
  }
}

@media (max-width: 992px) {
  .form-sections-container {
    flex-direction: column;
  }
  .form-section.three {
    max-width: unset;
    position: static;
  }
  .form-sections-container > .one,
  .form-sections-container > .two {
    max-width: 100%;
    margin: 0 0.5rem;
  }
  .form-sections-container > .one {
    background-color: var(--white);
    color: var(--color-dark-grey);
    padding: 1rem !important;
    border-radius: 10px;
  }
  .form-sections-container > .two {
    background-color: var(--white);
    color: var(--color-dark-grey);
    margin-top: 1.5rem;
    padding: 1rem !important;
    border-radius: 10px;
  }
  .form-section.three {
    text-align: right;
    margin-top: 2rem;
  }
  .form-section.three > button {
    position: static;
    /* right: 20px; */
    /* bottom: 20px; */
  }
  .form-sections-container#second-step {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .kyc-page h2 {
    margin-top: -1rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
  .step-indicator {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white);
  }
  #kyc-form {
    width: 95%;
    margin: auto;
    padding: 2rem !important;
  }
}

/* KYC NEW ID CARD  */
#second-step {
  /*Temp*/
  width: 100% !important;
}

.kuc-id-container {
  width: 100%;
}
.kyc-doc-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--white);
  color: var(--color-dark-grey);
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.choose-doc select {
  // background-color: #1c3065;
  padding: 2px 5px;

  // background-color: #273c75;
  padding: 2px 7px 2px 0;
  font-size: 0.8rem;
  min-width: 153px;
  border-radius: 2px;
}

.choose-doc select option {
  // color: #fff !important;
}
.kyc-doc-row p {
  margin-bottom: 0.5rem;
  font-size: 0.85rem;
}
.upload-both {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  margin: 0 1rem;
}
.upload {
  padding: 0.5rem 0 0.5rem 1rem;
  border-radius: 10px;
  // background-color: #132555;
}
.upload p {
  display: flex;
  align-items: center;
}
.upload p:first-of-type {
  margin-bottom: 0;
}
.upload p img {
  margin-left: 1rem;
  max-width: 30px;
  filter: brightness(0);
}
.upload span small {
  font-weight: 200;
}
.add-more-id {
  /* width: 100%; */
  margin: 1.2rem 0;
  margin-left: auto;
  /* If not required sTYles */
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--color-dark-grey);
}

.box-style {
  background: var(--sidebar-bg);
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 2rem;
}
.upload-both.adderss-upload-proof {
  display: grid;
  grid-template-columns: 1fr;
}

label.gradient-button {
  width: max-content;
}

/* .kyc-page button.delete {
  background: #dc3545 !important;
}
.gradient-button.delete {
  background: #dc3545 !important;
} */
/* .kyc-page button.delete:hover {
  color: #fff !important;
} */
@media (max-width: 992px) {
  #kyc-form {
    padding: 3rem 1rem !important;
  }
  .kyc-doc-row {
    flex-direction: column;
  }
  .choose-doc {
    width: 100%;
    text-align: left;
    margin-bottom: 1rem;
  }
  .upload-both {
    margin-bottom: 1rem;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .upload p {
    font-size: 0.8rem;
  }
  .upload p img {
    max-width: 25px;
    margin-left: 5px;
    /* font-size: 0.85rem; */
  }
}

@media (max-width: 544px) {
  #kyc-form {
    padding: 0 !important;
  }
  #kyc-form h3 {
    font-size: 1.2rem;
    margin-bottom: -10px;
  }
  .upload-both {
    display: grid;
    grid-template-columns: 1fr;
  }
  .buttons-container {
    /* position: absolute; */
    right: 20px;
    /* bottom: 20px; */
  }
  .detail-match-text {
    padding: 0 1rem;
    max-width: 90%;
    margin: 2rem auto;
  }
  .next-button {
    margin-right: 1rem;
  }
}

@media (max-width: 1001px) {
  #kyc-dob tr {
    display: table-row !important;
  }
  .ant-picker-cell {
    display: table-cell !important;
    vertical-align: inherit !important;
    border: none !important;
  }
  .ant-picker-content tbody tr {
    display: table-row !important;
    vertical-align: inherit !important;
  }
  .ant-picker-content tbody tr {
    margin: 0 !important;
    background-color: #fff !important;
  }
  .ant-picker-content table td {
    display: table-cell !important;
    border-bottom: none;
    border: none !important;
  }
  .form-field-container td {
    display: table-cell !important;
  }
  #mobileNumber {
    background: transparent !important;
  }
}

.upload > input {
  height: 0;
}
.custom-choose-file {
  background-color: rgba(247, 247, 247, 0.12);
  color: var(--color-dark-grey);
  font-size: 0.8rem;
  border-radius: 3px;
  text-align: center;
  padding: 0.2rem 0.5rem;
  max-width: 110px;
  width: 100%;
  border: var(--light-border);
}

/* NOTEs */
.notes {
  border-radius: 10px;
  padding: 1rem;
  .note {
  
    @include whiteBoxStyle;
    border: var(--light-border);
    margin: 1.5rem 0;
    padding: 1rem;
    border-radius: 10px;
    ul {
      color: var(--color-dark-grey);
      font-weight: 500;
    }
  }
}


.kyc-page input:-webkit-autofill,
.kyc-page input:-webkit-autofill:hover,
.kyc-page input:-webkit-autofill:focus,
.kyc-page input:-webkit-autofill:active {
  /* background: transparent !important;
  .kyc */
  transition: background-color 5000s ease-in-out 0s !important;
}
.kyc-page input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-dark-grey) !important;
}
