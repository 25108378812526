

.my-account {
  /* min-height: 100vh;
  height: 100%; */
  /* background-image: url('../images/ecosytemBackground.png'); */
  /* background-image: url("../components/pages/images/ecosytemBackground.png"); */
  /* background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 1rem;
  padding: 1rem; */
}

.btn.login-submit-btn{
  background-color: #7366F0 !important;

}
.btn.login-submit-btn:hover  {
  background-color: #7366F0 !important;


}