.usecases {

  &-page{

    max-width: 70rem;
    margin: 2rem auto;
    text-align: center;
  }
  &__boxes{
   
    @include gridLayout(440px , 2rem);
    margin: 2rem auto;
    padding: 1rem;
    text-align: center;

    &.boxes-3{
    @include gridLayout(320px , 0rem);

    }
  }
  &__box{
    background-color: var(--color-primary);
    color: var(--white);
    font-weight: 600;
    border-radius: 20px;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      max-width: 350px;
      max-height: 230px;
      // margin-bottom: -2rem;
    }
    h3{
   font-weight: 600;
      margin-top: 1rem;
      color: #fff;
    }
    h5{
      font-weight: 500;
      margin-top: 1rem;
      color: #fff;
    }
    p{
      max-width: 80%;
      margin: auto;
      margin-bottom: 1.2rem;
    }
  }
  &__text{
    position: relative;
    z-index: 1;
    margin-top: auto;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // button{
    //   margin-top: auto;

    // }
  }
}

.cms-page{
  .centered{
    top: 0;
    left: 0;
    inset: 0;
    height: 100%;
    margin: auto;
    flex-direction: column;
    // scroll-margin: ;
  }
}