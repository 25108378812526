.notification{
  // background-color: red;
  height: 40px;
  background: url('../../../components/pages/images/notification.gif');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  > *{
    text-align: center;
    color: var(--white);
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1.1rem;
   
    a{
      text-decoration: underline;
      color: var(--white);
      &:hover{
        color: var(--white);
        text-decoration: underline;
        transform: scale(1.05);
        filter: brightness(1);
      }
    }
  }
  @media (max-width:695px) {
    // background-size: contain;
    background-position: center right;
    > *{

      font-size: 0.95rem;
      line-height: 1;
    }
  }
  @media (max-width:400px) {
    > *{

      font-size: 0.85rem;
    }
  }
}
.header-fixed{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}
header{
  background-color: var(--white);
  color: var(--color-dark-grey);
  box-shadow: var(--header-shadow);
 
  width: 100%;
  z-index: 9999;
  background-size: cover;
  background-position: center top;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    padding: 10px 0;
  }
  
  a{
    color: inherit;
  }

  .new-collection{
    display: flex;
    align-items: center;
    > img{
      margin-left: 5px;
      max-width: 70px;
    }
  }
}
.item-dropdown {
  position: absolute;
  top: 46px;
  right: 0;
  min-width: 200px;
  z-index: 999;
  /* padding: 0.5rem; */
}
.item-dropdown {
  width: max-content;
  position: absolute;
  background: var(--white);
  border-radius: 5px;
  margin-right: 2.5%;
  padding: 0 0;
  animation: smoothDrop 0.2s ease;
  -webkit-animation: smoothDrop 0.2s ease;
  box-shadow: 0 4px 10px 0 rgba(10, 10, 10, 0.2);
  z-index: 1;
  .dropdown {
    position: relative;
    text-align: center;
    box-shadow: 0 4px 10px 0 rgba(10, 10, 10, 0.2);
    a {
      color: var(--color-dark-grey) !important;
      text-transform: none;
      font-weight: normal;
      letter-spacing: normal;
      display: block;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 8px 20px 8px 20px;

      min-width: 130px;

      width: 100%;
      text-align: left;
      &:hover {
        color: var(--white) !important;
        background: var(--color-primary);
        i{
          background-color: var(--white);
          color: var(--color-primary);
        }
      }
      &:last-child {
        border-bottom: none;
      }
      i {
        padding: 7px 8px;
        background: #3fb8e17a;
        border-radius: 30px;
        width: 28px;
        font-size: 12px;
        margin-right: 5px;
      }
    }
  }
}
