.usecase-detail-page{
  @import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500;600;700;800;900&family=Montserrat:wght@400;500;600;700;800;900&family=Roboto:ital,wght@0,500;0,700;0,900;1,900&display=swap');
  line-height: 1.8;
  li{
    list-style: disc !important;
  }
  .how-install-water img{
    height:100%!important		}
  h1,h2,h3,h4,h5,h6{
  font-family: 'Montserrat', sans-serif;
  font-weight: 700 ;
  }

  .wp-block-heading{
   @media (max-width:280px) {
    font-size: 23px;
   } 
  }
  // .testimonial-hide{
  //   display: none;
  // }

  // [data-id="561ce2fe"],
  // [data-id="4eda6d2c"],
  // [data-id="e4c3817"],
  // [data-id="5e2045a"],
  // [data-id="29b6f4ea"],
  // [data-id="67eb7627"],
  // [data-id="7f39fcd6"],
  // [data-id="6c81f291"],
  // [data-id="51f2ce51"],
  // [data-id="791dbf63"]{
  //   display: none;
  // }
  figure {
    margin: 1.5rem auto;
  }
  .faq-wp{
    h2{
      font-size: 22px !important;
      padding: 0.6rem 0.5rem;
      line-height: 1.2;
      font-weight: 400;
    }
    i{
      margin-top: 18px;
      right: 15px;
    }

    ul 
      li {
        border-bottom: 1px solid #333 !important;
        list-style: none !important;
        p{
          &:first-of-type{
            display: none;
        }
      }
    }
  }
  #features p{
    font-size: 1.2rem;
  }

  .slick-slide p{
    font-size: 1rem;

  }
  .elementor-image-box-description{
    font-size: 1.3rem !important;
  }
  .elementor-widget-container ,
  .elementor-widget-container p,
  .elementor-widget-container div{
    font-size: 1.1rem;
    font-weight: 400;
  }
  .elementor-section-boxed{
    padding: 40px 0 !important;
  }
  .elementor-icon-box-description{
    font-size: 1rem !important;
    font-weight: 400;
  }
  .elementor-element-ef970f2{
    h2, h3{
      font-weight: 500;
    }
  }
  .ellipsis-text{
    // padding:  1rem;
    margin: 0 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  // .elementor-element-40eb920e, 
  // .elementor-element-5deaa4ce  {
  //   div , p{
  //     font-size: 1.2rem;
  //     font-weight: 400;
  //   }
  // }
  .elementor-element-30c932c {
    div , p{
      font-size: 1.2rem;
      font-weight: 400;
    }
  }
  a{
  text-decoration: none;
  transition: all 0.4s ease;
  font-weight: 800;
  }
  .navbar{
  box-shadow: 0px 12px 20px rgb(0,0,0,0.1)!important;
  }
  .logo{
  width: 150px!important;
  }
  section{
  /* padding-top: 30px; */
  padding-bottom: 80px;
  z-index:2;
  position: relative;
  }
  .overlay{
  position: absolute;
  top:0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#00e0fe, #1107fe);
  opacity: 0.7;
  z-index: -1;
  }
  .bg-cover{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed!important;
  }
  .hero-section{
  min-height: 100vh!important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  }
  .btn{
  padding: 14px 22px;
  transition: all 0.5s ease;
  }
  .btn-main{
  background: linear-gradient(to right,#00e0fe, #1107fe)!important;
  border:none!important;
  border-radius: 100px!important;
  font-weight: 800;
  color: #fff;
  }
  .btn:hover{
  color: #fff;
  transform: translateY(-5px);
  }
  .section-intro{
  margin-bottom: 40px;
  }
  .nav-item{
  padding: 0px 17px!important;
  }
  .btn-outline-secondary{
  border:1px solid #3FB8E1!important;
  padding: 5px 20px!important;
  color: #3FB8E1!important;
  font-weight: 400!important;
  }
  .btn-outline-secondary:hover{
  border:1px solid #3FB8E1!important;
  color: #3FB8E1!important;
  background-color: #f8f9fa!important;
  }
  .fa-search{
  color: #3FB8E1!important;
  }
  .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
  background-color: #3FB8E1!important;
  border: 1px solid #3FB8E1!important;
  border-radius: 5px;
  padding: 5px 20px!important;
  color: #fff;
  }
  .nav-link {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 20px;
  /* identical to box height */
  text-align: center;
  text-transform: capitalize;
  color: #3C3C3C!important;
  }
  .btn{
  padding: 5px 10px!important;
  }
  .top-light{
  background-color: #F6FBFF!important;
  }
  .nav-link-text{
  padding: 20px 12px!important;
  font-family: 'Montserrat'!important;
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #3C3C3C;
  }
  .social-icons {
  display: -webkit-inline-flex;
  }
  .social-icons {
  background-size: cover;
  }
  .social-icons i{
  padding: 0px 30px!important;
  color: #000!important;
  }
  .card-bg{
  height: 65vh;
  background-color: #2D8BD1!important;
  border: 1px solid #2D8BD1!important;
  color: #fff;
  border-radius:8px!important;
  }
  .col-lg-6{
  padding-top: 20px;
  }
  .btn-light{
  padding: 10px 25px!important;
  color: #2D8BD1!important;
  }
  .btn-primary{
  background-color: #3FB8E1!important;
  padding: 10px 25px!important;
  color: #fff!important;
  border: 1px solid #3FB8E1;
  }
  .btn-light:hover{
  color: #2D8BD1!important;
  }
  .features h6{
  color: #3FB8E1;
  font-weight: 600;
  line-height: 28px;
  text-transform: capitalize;
  }
  .features h2{
  font-weight: 600;
  color: #000;
  }
  .features p{
  font-size: 20px !important;
  color: #3C3C3C;
  font-weight: 500;
  line-height: 28px;
  }
  .img-container {
  position: relative;
  text-align: center;
  color: #000;
  }
  .bottom-left-use {
  position: absolute;
  bottom: 40px;
  left: 70px;
  }
  .bottom-left-use2 {
  position: absolute;
  bottom: 30px;
  left: 330px;
  }
  .bottom-left-use3 {
  position: absolute;
  bottom: 30px;
  left: 330px;
  }
  .bottom-left-use4 {
  position: absolute;
  bottom: 30px;
  left: 330px;
  }
  .bottom-left {
  position: absolute;
  bottom: 100px;
  left: 14px;
  }
  .top-left {
  position: absolute;
  top: 25px;
  left: 14px;
  text-align: center;
  }
  .top-center {
  position: absolute;
  top: 250px;
  left: 14px;
  text-align: center;
  }
  .top-left p{
  font-size: 16px;
  }
  .bottom-left p{
  font-size: 16px;
  }
  .top-center p{
  font-size: 16px;
  }
  .top-right {
  position: absolute;
  top: 25px;
  right: 20px;
  }
  .top-center-right {
  position: absolute;
  top: 250px;
  right: 20px;
  }
  .bottom-right-use{
  position: absolute;
  bottom: 30px;
  right: 100px;
  }
  .bottom-right-use2 {
  position: absolute;
  bottom: 30px;
  right: 300px;
  }
  .bottom-right {
  position: absolute;
  bottom: 100px;
  right: 20px;
  }
  .top-right p{
  font-size: 16px;
  }
  .bottom-right p{
  font-size: 16px;
  }
  .top-center-right p{
  font-size: 16px;
  }
  .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  }
  .text-width{
  width: 25%;
  }
  @media screen and (max-width:768px) {
  .img-container {
  display:none
  }
  }
  .img-bg{
  background-color: #3FB8E1;
  border: 1px solid #3FB8E1;
  border-radius: 50px; 
  padding: 20px 20px;
  }
  .img-bg-use{
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 50px; 
  padding: 5px 5px;
  }
  .sensors h2{
  font-weight: 600;
  color: #000;
  text-align: center;
  }
  .usecase-card{
  background: rgba(63, 184, 225, 0.1);
  border: 1.59216px solid #3FB8E1;
  border-radius: 22.2902px;
  }
  .advantages h2{
  font-weight: 600;
  color: #000;
  text-align: left;
  }
  .benifits h2{
  font-weight: 600;
  color: #000;
  text-align: left;
  }
  .gen h2{
  font-weight: 600;
  color: #000;
  text-align: left;
  }
  .uses h2{
  font-weight: 600;
  color: #000;
  }
  .card-size{
  height: 50vh;
  }
  .card-img-top {
  position: absolute;
  top: -50px;
  left: 50%;
  margin-left: -80px;
  width: 150px !important;
  height: 150px;
  }
  .card-test {
  margin-top: 40px;
  padding-top: 40px;
  }
  .text-danger-glow {
  color: #ff4141;
  text-shadow: 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00, 0 0 60px #f00, 0 0 70px #f00, 0 0 80px #f00;
  }
  .blink {
  animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
  }
  @keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }
  }
  .pad-left-blog{
  padding-left: 90px;
  }
  @media (max-width: 767px) {
  .carousel-inner .carousel-item > div {
  display: none;
  }
  .carousel-inner .carousel-item > div:first-child {
  display: block;
  }
  .mar-top{
  padding-top: 10px!important;
  } 
  .pad-left-blog{
  padding-left: 0px;
  }
  .d-flex a{
  padding: 5px 0px;
  }
  .blogs h1{
  font-size:4rem!important;
  }
  }
  .carousel-inner .carousel-item.active,
  .carousel-inner .carousel-item-next,
  .carousel-inner .carousel-item-prev {
  display: flex;
  color: #000;
  }
  /* medium and up screens */
  @media (min-width: 768px) {
  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
  transform: translateX(25%);
  }
  .carousel-inner .carousel-item-start.active, 
  .carousel-inner .carousel-item-prev {
  transform: translateX(-25%);
  }
  }
  .carousel-inner .carousel-item-end,
  .carousel-inner .carousel-item-start { 
  transform: translateX(0);
  }
  .d-flex a{
  color: #000;
  padding: 5px 10px;
  opacity: 0.6;
  }
  .flex-center {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  }
  .blogs h1{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 10rem;
  line-height: 154px;
  text-align: center;
  text-transform: capitalize;
  color: #3C3C3C;
  }
  .text-underline{
  text-decoration:underline;
  }
  .footer p{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: .95rem;
  line-height: 25px;
  /* or 25px */
  color: #3C3C3C;
  }
  .product p{
  font-weight: 500;
  font-size: .9rem;
  line-height: 1.4;
  }
  .titles{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #3C3C3C;
  }
  .border-end-new{
  border-right:3px solid #3FB8E1!important;
  }
  .col-text{
  color: #3FB8E1;
  font-weight: 700;
  }
  .flex-1{
  text-align: left;
  display: flex;justify-content: center;align-items: center;
  }
  .flex-prop{
  
  display: flex;justify-content: center;align-items: center;
  }
  .mar-left{
  margin-left: 1.5rem;
  }
  .slick-prev:before,
  .slick-next:before {
  color: black!important;
  }
  
  .slick-dots li button:before {
  color: #3FB8E1!important;
  opacity: 1!important;
  font-weight: 700;
  
  }
  .slick-dots li.slick-active button:before {
  color: #000!important;
  }
  .your-item {
  position: relative;
  }
  .your-item:after {
  content: '';
  height: 80%;
  width: 3px;
  position: absolute;
  right: 0;
  top: 30px;
  background-color: #3FB8E1; 
  }
  .centered {
    position: absolute;
    // top: 50%;
    // left: 50%;
    // width: 90%;
    width: 100%;
    padding: 1rem;
    line-height: 1.3;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  .bg-container {
    position: relative;
    text-align: center;
    color: white;
  }
  @media screen and (max-width:991px) {
  
  .flex-prop{
    display: inline;
  
  text-align: left;
  }
  .flex-1{
  text-align: center;
  display: inline!important;
  flex-direction: column-reverse!important;
  }
  .your-item {
  display: none;
  }
  .mar-left{
  margin-left: 0rem;
  }
  .parent {
  flex-direction: column-reverse;  
  }
  .flex-prop h4{
    padding-top: 20px;
    text-align: center;
  }
  .flex-prop p{
    text-align: center;
  }
  }
  .parent {
  display: flex;
  }
  
  .flex-prop p{
    max-width: 450px;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .bg-white-pool{
    background-color: #fff;
    border-radius: 25px;
  }
  
  @media screen and (max-width:600px) {
   
  }
}