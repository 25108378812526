html {
  scroll-behavior: smooth;
}

.header-for-buy {
  position: fixed;
}

.header-for-buy.sticky {
  /* position: fixed;
  top: 0; */
  background-color: #161a30;
}

.main-page-section {
  background-image: url("../images/back.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 2rem;
}

.main-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* header{
    background-color: transparent !important;
} */
.main-page .text-side {
  flex: 1;
}

.main-page .text-side h1 {
  width: 90%;
  max-width: 450px;
  border-bottom: 2px solid #fff;
  padding-bottom: 1.5rem;
}

.main-page .image-side {
  width: 100%;
  flex: 0.9;
}

.main-page .image-side img {
  width: 100%;
  height: 100%;
  margin-top: 1.5rem;
}

.pre-order-btn {
  display: inline-block;
  border-radius: 20px;
  padding: 1rem;
  background-color: #7167e8;
  color: #fff;
  font-weight: 600;
  min-width: 200px;
  text-align: center;
  /* width: 100%; */
}

.pre-order-btn:hover {
  color: #fff;
  filter: brightness(90%);
}

.explore-btn {
  display: inline-block;
  border-radius: 20px;
  padding: 1rem;
  background-color: #7167e8;
  color: #fff;
  font-weight: 600;
  min-width: 200px;
  text-align: center;
  font-size: 16px;
  /* width: 100%; */
}

.what-nfm {
  display: flex;
}

.what-nfm .what-nfm-text-side {
  flex: 0.8;
}

.what-nfm-image-side {
  flex: 1.2;
}

.what-nfm-image-side .image {
  width: 100%;
}

.what-nfm-image-side .image img {
  width: 100%;
}

.what-nfm-section {
  padding: 50px 0;
}

.what-nfm-section h1 {
  margin: 1rem auto 2rem auto;
}

.what-nfm-section .what-nfm {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.what-nfm-section .what-nfm .what-nfm-text-side {
  flex: 0.9;
  margin-right: 2rem;
}

.what-nfm-section .what-nfm .what-nfm-text-side p {
  /* flex: 0.9   ; */
  /* margin-right: 2rem; */
  line-height: 1.8;
  margin-top: 1.2rem;
  /* max-width: 400px; */
  font-size: 1.2rem;
}

.what-nfm-section .what-nfm .what-nfm-image-side {
  flex: 0.9;
}

.pre-order-btn.yellow {
  display: block;
  width: 100%;
  margin: 1rem 0;
  background-color: #d2f155;
  color: #000;
}

.pre-order-btn.yellow:hover {
  color: #000 !important;
}

.what-nfm-section {
  border-bottom: 2px solid #fff;
}

.what-nfm-section .what-nfm.reverse {
  flex-direction: row-reverse;
}

.what-nfm-section .what-nfm.reverse .what-nfm-text-side {
  flex: 0.9;
  margin-left: 2rem;
}

.pre-order-btn.green {
  background-color: #62ffca;
  display: block;
  width: 100%;
  color: #000;
  margin: 1.5rem 0;
}

.pre-order-btn.green:hover {
  color: #000 !important;
}

.p-section {
  border-bottom: 2px solid #fff;
  padding: 50px 0;
}

.p-section p {
  max-width: 700px;
  margin: auto;
  line-height: 1.8;
  text-align: center;
}

.form-section {
  padding: 50px 0;
  /* border-bottom: 2px solid #fff; */
}

/* select option {
  color: #000;
  background-color: transparent;
} */

/* .form-section input,
.form-section select,
.form-section textarea {
  width: 100%;
  padding: 0.3rem 0.5rem;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
} */

/* .form-section label {
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.form-section input::placeholder,
.form-section select::placeholder,
.form-section textarea::placeholder {
  color: #fff;
} */

.half-fields {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 25px;
}

/* .half-fields > a:last-of-type {
  background-color: var();
  color: #000;
} */

.buy-section {
  padding: 50px 0;
  max-width: 70rem;
  padding: 1rem;
  margin: auto;
}

.buy-section h2 {
  font-size: 1.5rem;
  text-align: center;
}

.three-boxes-nfts {
  margin: 2rem 0;
  margin-top: 3.5rem;
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.buy-nft-image {
  max-width: 250px;
  height: 250px;
  margin: auto;
  border-radius: 5px;
  overflow: hidden;
}

.buy-nft-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.box-nft-buy {
  text-align: center;
  /* background: red; */
}

.box-nft-buy h3 {
  text-align: center;
  margin: 1rem auto;
}


/* CHANGE STYLES */


input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}




.buy-section button {
  outline: none;
  margin: 1rem 0;
  margin-bottom: 2rem;
  border: none;
  cursor: pointer;
}

.termsbuy {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.termsbuy > div {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  /* justify-content: center; */
}

.termsbuy > div > label {
  font-size: 0.9rem;
  color: #fff;
  margin-left: 0.2rem;
  /* margin-bottom: 0.5rem; */
}

.termsbuy > div > label a {
  color: #7167e8;
  /* margin-bottom: 0.5rem; */
}

.termsbuy p {
  font-size: 1.2rem;
}

.minus.disabled {
  filter: brightness(0.5);
  cursor: not-allowed;
}

.plus.disabled {
  filter: brightness(0.5);
  cursor: not-allowed;
}

#soldNFM-tier {
  max-width: 300px;
  margin-left: auto;
  padding: 10px;
  border: var(--light-border);
  border-radius: 5px;
  color:var(--color-dark-grey)
}

#soldNFM-tier option {
  /* background-color: #1b2235; */
  color: var(--color-dark-grey);
}

/* #soldNFM-tier  */

/* //Market Place Ui Issue*/
@media (max-height: 620px) {
  .landing-main.marketplace-landing .text-btn h1 {
    padding: 20px 0;
    margin-bottom: 0;
  }

  .text-btn p {
    font-size: 0.9rem;
  }

  .buttons-landing-main {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    /* margin-left: 2rem; */
  }
}

@media (max-width: 440px) {
  .buttons-landing-main {
    margin-left: 0;
  }

  .landing-main.marketplace-landing .text-btn h1 {
    padding: 20px 0;
    margin-bottom: 0;
  }
}

@media (max-width: 300px) {
  .landing-main.marketplace-landing .text-btn h1 {
    padding: 15px 0;
    margin-bottom: 0;
    font-size: 1.8rem;
  }

  .text-btn p {
    font-size: 0.85rem;
  }

  .buttons-landing-main {
    margin-top: 0.2rem;
    flex-direction: column;
  }

  .buttons-landing-main a:last-child {
    margin-left: 0;
    margin-top: 0.5rem;
  }

  .ecosystem {
    padding: 2rem 0.5rem;
  }
}
