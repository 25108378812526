.gateway-page {
  .qr-banner {
    width: 100%;
    height: 100%;
    max-height: 90vh;
    overflow: hidden;
    position: relative;
  }
  .qr-banner img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .qr-banner h1 {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    color: #fff;
    font-weight: 900;
    font-size: 2.5rem;
    padding: 2rem 0;
    background: rgba(0, 0, 0, 0.005);
  }
  .qr-information {
    max-width: 65rem;
    margin: 4rem auto 0 auto;
    padding: 1rem;
    color: var(--color-dark-grey);
  }
  .qr-information > h2 {
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
  }
  .qr-information > p {
    text-align: center;
    font-weight: 400;
    margin-bottom: 3rem;
    margin-top: 1rem;
  }
  .qr-infos {
    /* display: flex;
      justify-content: space-between;
      align-items: stretch;     */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
    grid-gap: 2rem;
    /* flex-wrap: wrap; */
  }
  .qr-info {
    text-align: center;
    flex: 1;
    margin: 0 1.5rem;
    background: var(--sidebar-bg);
    background-color: rgba(63, 184, 225, 0.2);
    border: 1px solid var(--color-primary);
    border-radius: 16px;
    padding: 2rem;
    margin-bottom: 1rem;

    &:hover {
      filter: brightness(100%);

      h3 {
        text-decoration: underline;
      }
    }
  }

  .qr-info h3 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }
  .qr-info p {
    color: var(--color-dark-grey);
    text-align: center;
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1.6;
  }
  .qr-info__icon {
    max-width: 90px;
    margin: 2rem auto;
  }
  .qr-info__icon img {
    /* max-width: 160px; */
    /* max-height: 140px; */
    /* aspect-ratio:  1 / 1; */
    /* margin: auto; */
    width: 100%;
    height: 100%;
  }

  @media (max-width: 899px) {
    .qr-info {
      margin: 0 1rem;
      padding: 1.2rem;
    }
    &.mt-90pxx {
      margin-top: 85px;
    }
  }
  @media (max-width: 899px) {
    .qr-info {
      margin: 0 1rem;
      padding: 1.2rem;
    }
  }
}

.join-community {
  text-align: center;
  h2 {
    padding-top: 2rem;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
    color: var(--color-dark-grey);
    margin: 3rem 0 2rem 0;
  }
  .community-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    & > a {
      margin: 1rem;
      transition: 0.2s linear;
      color: var(--color-dark-grey);
      &:hover {
        transform: scale(1.05);
        filter: brightness(90%);
      }
    }
    p {
      margin-top: 0.3rem;
    }
  }
}
