.custom-modal-container {
  /* min-width: 60vw; */
  color: black;
  max-width: 60em;
  width: 90%;
  margin: auto;
  /* max-height: calc(100vh - 85px); */
  height: 70vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999 !important;
  /* background-color: rgba(0, 0, 0, 1); */
  border: 1px solid var(--color-primary);
  background-color: white;

  /* border: 1px solid #ccc; */
  border-radius: 5px;
  padding: 20px;
  /* box-shadow: 0 5px 20px; */
  overflow: auto;
}
