
/* NEW HOMEPAGE */
.new-homePage {
  // background-image: url("../images/home/bgNewHome.png");
  // background-size: cover;
  // background-repeat: no-repeat;
  padding-bottom: 2rem;
}
.new-homePage section {
  margin: 2rem auto;
  padding: 3rem 1rem;

  @media (max-width:550px) {
      padding: 1.5rem 1rem;
  }
}
.new-homePage img {
  width: 100%;
  height: 100%;
}
.new-homePage .home-container {
  max-width: 70rem;
  margin: auto;
}
.new-homePage .mx-50rem {
  max-width: 50rem;
  margin: auto;
}
.home-secondary-head {
  color: #fff;

  font-weight: 500;
  font-size: 2.13rem;
  line-height: 1.6;
  /* identical to box height */
  margin-bottom: 1.5rem;
  text-align: center;
}
.new-homePage .hero-new {
  min-height: 65vh;
  max-height: 75vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(420px, 100%), 1fr));
  grid-gap: 2rem;
  align-items: center;
  background-color: rgba(7, 16, 59, 0.5);
  // background-image: url('../images/home/Snowflakesncashgalaxy.png');
  background-blend-mode: multiply;
  background-size: cover;
}

.hero-new .hero-new__text {
  max-width: 550px;
  width: 100%;
  padding: 1rem;
}
.hero-new__text h1 {
  font-size: 2.5rem;
}
.hero-new__text p {
  font-size: 1.2rem;
}

.new-homePage .green-btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  text-align: center;
  font-weight: 400;
  min-width: 110px;
  color: #fff;
  margin-right: 1rem;
  margin-bottom: 1rem;
  background: linear-gradient(95.75deg, #59d3fc 2.65%, #554dde 97.33%), #3485ff;
  box-shadow: 0px 1.13324px 0px rgba(0, 0, 0, 0.08),
    inset 0px -1.13324px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.transparent-blurBox {
  background: rgba(255, 255, 255, 0.1);
  border: 1.016px solid rgba(255, 255, 255, 0.3);
  border-radius: 12px;
}
.p-small {
  padding: 1.2rem;
}

.offering-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  grid-gap: 1.5rem 2rem;
}

.our-teams-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  grid-gap: 1.5rem 2rem;
}

.offer-card {
  width: 100%;
}

.team-card p {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 0.5rem;
}
.offer-image,
.team-image {
  width: 100%;
}

.our-crypto-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* max-width: 50rem ; */
}
.crypto-card {
  width: 250px;
  margin: 1rem;
  border: none;
}
.crypto-card .crypto-image {
  max-height: 70px;
  text-align: center;
}
.crypto-image img {
  height: 45px;
  width: 180px;
}
.media-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 1.2rem;
  max-width: 50rem;
  margin: auto;
}
.media-card {
  display: grid;
  place-content: center;
}
.iot-cards {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  grid-gap: 1.2rem;
  margin: auto;
}
.iot-aall {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 1.2rem;
}
.iot-card {
  display: grid;
  place-content: center;
}
.our-tech-stack img {
  border-radius: 12px;
}
/* .grid-2-space{
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-start: 2;
} */
.huobi-pair {
  display: none;
  background: #fff;
  padding: 2rem 1rem;
}
.home-secondary-head.color-blue {
  color: #16245c !important;
  text-align: left;
}
.huobi-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.huobi__logo {
  max-width: 220px;
}
.ncash-words {
  display: flex;
  align-items: center;
  margin-top: 1.2rem;
  /* justify-content: ; */
}
.ncash-words p {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.2rem 2rem;
}
.ncash-words p:first-of-type {
  padding-left: 0;
}
.ncash-words p:not(:last-of-type) {
  border-right: 2px solid #fff;
}
.home-carousel {
  /* margin-top: -10vh; */
}
.home-carousel .slick-prev{
  z-index:1;
  left:1% ;
  margin-top: 0.5rem;
}
.home-carousel .slick-next{
  z-index:1;
  right:2%;
  margin-top: 0.5rem;
}
/* .home-carousel .slick-prev::before{
  content: url('../images/home/Logos/Left.png');
  width: 30px;
  height: 30px;
  display: block;
 
}
.home-carousel .slick-next::before{
  content: url('../images/home/Logos/Right.png');
  width: 30px;
  height: 30px;
  display: block;
} */
.home-carousel .slick-slide{
  /* width: 100% !important; */
}
.home-carousel .slick-next::before{
  font-size: 2.5rem;
}
.home-carousel .slick-prev::before{
  font-size: 2.5rem;
}

.home-play-btn img {
  margin-right: 0.5rem;
  height: 25px;
  width: 25px;
}
@media only screen and (min-width: 1850px) {
  .crypto-image img {
    height: 47px;
    width: 210px;
  }
}
@media only screen and (max-width: 1050px) {
  .hero-new__text {
    margin: auto;
  }
}
@media only screen and (max-width: 1152px) {
  .new-homePage .hero-new {
    min-height: 80vh;
    max-height: unset;
    margin-top: 80px;
  }
}

@media only screen and (max-height: 700px) {
  .new-homePage .hero-new {
    min-height: 70vh;
    max-height: unset;
  }
}
@media only screen and (max-width: 768px) {
  .iot-cards {
    grid-template-columns: 1fr;
  }
  /* .new-homePage .hero-new{
    min-height: 80vh;
    max-height: unset;
  
  } */
  .crypto-card .crypto-image {
    max-height: unset;
  }
  .crypto-image img {
    height: auto;
    width: 100%;
  }
  .ncash-words p {
    font-size: 1.1rem;
    padding: 0.2rem 1.5rem;
  }
  .hero-new__image {
    max-width: 95%;
    max-height: 320px;
    margin: auto;
    text-align: center;
  }
  .hero-new__image img {
    height: 100%;
    /* margin-left: -2rem; */
  }
  .hero-new__text h1 {
    font-size: 1.8rem;
  }
  .hero-new__text p {
    font-size: 1rem;
  }
  .home-secondary-head {
    font-size: 1.7rem;
  }
  .home-carousel .slick-prev{
 
    margin-top: 0rem;
  }
  .home-carousel .slick-next{
   
    margin-top: 0rem;
  }
  .home-carousel .slick-next::before{
    font-size: 1.5rem;
  }
  .home-carousel .slick-prev::before{
    font-size: 1.5rem;
  }
}
