
.btn-brand{
  width: 100%;
  outline: none;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--color-primary);
  color: var(--color-primary) !important;
  // font-weight: 500;
  border-radius: 5px;
  transition: all .2s;
} 
.btn-brand.mx-w{
  width: max-content;
}
.btn-brand.px-05{
  padding: 0.5rem 0.5rem;
}
.btn-brand.delete-btn{
  max-width: 150px;
  border-color: #E1252A;
  color: #E1252A !important;
  &:hover{
    background-color: #E1252A;
  }
}
.btn-brand.btn-sm{
  padding: 0.3rem 0.5rem;
}
.btn-brand.btn-transparent{
  background: transparent;
}

.btn-brand.btn-active{
  background-color: var(--color-primary);
  color: var(--white) !important;
}
.btn-brand:hover{
  background-color: var(--color-primary);
  color: var(--color-white) !important;
  transform: scale(1.03);
}

.btn-brand:active{
  transform: scale(0.97);
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.2);
}
.btn-brand.btn-white:hover{
  color: var(--color-primary) !important;
  background-color: var(--white);
}

.btn-connect-wallet{
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  > p:first-of-type{
    flex: 1;
  }
  > &:last-child{
    margin-left: 0.5rem;
  }  
}