
/* ACIVITY FILTER */
.activity-filter select {
  width: 150px;
  margin: -1rem auto;
  color: #fff;
  border: 3px solid #353a4b;
  padding: 0.5rem;
  border-radius: 3px;
  /* margin-top: -3rem; */
}

.activity-filter select option {
  padding: 1.3rem 0.5rem !important;
  background: #161c30;
  color: #fff;
}

@media (min-width: 1000px) {
  .activity-filter {
    margin-top: -2rem;
  }
}

table {
  /* border: 1px solid #ccc; */
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  border-radius: 15px;
  overflow: hidden;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  /* background-color: #f8f8f8; */
  /* border: 1px solid #ddd; */
  padding: 0.35em;
  vertical-align: middle;
  background-color: var(--sidebar-bg);

}
table.back-white tr{
  background-color: var(--white);
}
table th {
  font-size: 16px;
  font-weight: 700;
}

table th,
table td {
  font-weight: 600;
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.82em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

/*  Change in design With sidear */
@media screen and (max-width: 1101px) {
  table {
    border: 0;
  }

  table th {
    font-size: 0.78em;
    letter-spacing: 0em;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    /* border-bottom: 3px solid #ddd; */
    display: block;
    margin-bottom: 0.625em;
    
  }

  table td {
    border-bottom: 1px solid #2c2c2c;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: capitalize;
  }

  table td:last-child {
    border-bottom: 0;
  }

  .center-in-mobile {
    text-align: center;
  }

  .counter.in-mobile-right {
    justify-content: flex-end;
  }

  .trx-id {
    display: inline-block;
    max-width: 168px;
    color: #34c77b;
  }

  .green-text-lat-long {
    color: #34c77b;
  }

  .de-table.table-rank .d-plus {
    color: #fff;
  }
}

#freez {
  text-decoration: underline !important;
}

#block {
  text-decoration: underline !important;
  color: red;
}

@media (min-width: 982px) {
  [data-label="Address"] {
    text-align: left;
  }
  [data-label="Address"] span{
    padding-left: 10px;
    display: inline-block;
  }
}

input[type="submit"].btn-pay {
  display: block;
  margin: 0 auto;
  padding: 8px 20px;
  background: #008000;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 3px #111;
  cursor: pointer;
}

.overflow-text {
  overflow-wrap: break-word;
}

@media (max-width: 1082px) {
  .ant-popover-placement-top .ant-popover-arrow {
    left: unset !important;
    right: 10% !important;
    /* transform: translateX(-100%); */
  }

  .address-text-table {
    display: inline-block;
    max-width: 240px;
  }

  .btn-main1,
  .btn-main-delete {
    margin: 0;
  }

  .btn-main1.ml-2p {
    margin-left: 1rem !important;
  }

  .de-table.table-rank td {
    padding: 12px 0;
  }

  tbody tr {
    margin: 1.5rem 0;
    // background-color: ;
    border-radius: 10px;
    background-color: var(--sidebar-bg);

  }
}

@media (max-width: 380px) {
  .address-text-table {
    display: inline-block;
    max-width: 182px;
  }
}

/* DEVICE MANAGEMENT SCRENS */
.max-width-90 {
  max-width: 60px;
  word-break: break-all;
  text-align: center;
}

.device-manage-screen .de-table.table-rank td {
  /* padding: 10px 0; */
  font-weight: 400;
}

.device-manage-screen .de-table.table-rank th {
  /* padding: 15px 0; */
  font-weight: 600;
}

.device-manage-screen table th {
  font-size: 0.85rem;
}


// Theme mATach
table th{
color: var(--color-dark-grey);
border-bottom: var(--light-border);
}
table td{
color: var(--color-dark-grey);
  border-bottom: var(--light-border);
}
