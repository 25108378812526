
.green-text {
  color: var(--green-text);
}


/* BACKGROUND BUTTON */
.gradient-box {
  background: hsla(244, 28%, 46%, 1);

  background: linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background-image: -moz-linear-gradient(
    173deg,
    rgb(217, 69, 225) 0%,
    rgb(91, 96, 215) 100%
  );
  background-image: -webkit-linear-gradient(
    173deg,
    rgb(217, 69, 225) 0%,
    rgb(91, 96, 215) 100%
  );
  background-image: -ms-linear-gradient(
    173deg,
    rgb(217, 69, 225) 0%,
    rgb(91, 96, 215) 100%
  );
  min-width: 160px;
  border-radius: 15px;
  padding: 2px;
  border: none;
  outline: none;
}

.gradient-text {
  background: hsla(244, 28%, 46%, 1);

  background: linear-gradient(
    45deg,
    rgb(113, 109, 161) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );
  background: linear-gradient(
    173deg,
    rgb(217, 69, 225) 0%,
    rgb(91, 96, 215) 100%
  );

  /* background: -moz-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  ); */
  /* background: var(--text-end); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.box-det {
  display: block;
  margin: 1.9rem 0;
  /* display: block; */
}

.field {
  background: var(--sidebar);
  /* max-width: 250px; */
  border-radius: 15px;
  padding: 10px;
  color: #fff;
  text-align: center;
}

.field.active {
  background-color: transparent;
}

.border-field {
  margin-bottom: 0;
  max-width: 200px;
  font-size: 0.9rem;
}


/* STAKE CONTAINER */
.stake-container {
  max-width: 70em;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.top-section {
  margin-top: 120px;
}

.stake-options {
  color: #fff;
  /* flex: 0.6; */
}

.stake-options h6 {
  font-size: 1.1rem;
  margin-top: 2.5rem;
  /* margin-bottom: 1rem; */
}

.stake-options p {
  margin-top: 1.2rem;
  font-size: 0.95rem;
  max-width: 350px;
  line-height: 1.6;
}

.stake-box {
  flex: 1.2;
  /* background-color: rgba(0,0,0,.5); */
  width: 100%;
  max-width: 45em;
  border-radius: 20px;
  padding: 1rem;
  background-color: rgba(29, 36, 56, 0.733);
  // background-image: url(../images/back.png);
  background-size: cover;
  background-position: center center;
  /* border-radius: 50px; */
  /* background-image: -moz-linear-gradient( 173deg, rgb(217,69,225) 0%, rgb(91,96,215) 100%);
  background-image: -webkit-linear-gradient( 173deg, rgb(217,69,225) 0%, rgb(91,96,215) 100%);
  background-image: -ms-linear-gradient( 173deg, rgb(217,69,225) 0%, rgb(91,96,215) 100%); */
  /* position: absolute; */
  /* left: 936px; */
  /* top: 133px; */
  /* width: 1271px; */
  /* height: 809px; */
  z-index: 33;
}

.stake-unstake-switchs {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .stake-unstake-switchs .gradient-box:last-of-type{
    border-top-left-radius: 0;
} */
.gradient-box {
  z-index: 2;
}

.br-0-lb.gradient-box {
  /* border-top-right-radius: 0; */
  /* border-bottom-right-radius: 0; */
  margin-right: -1.5rem;
  z-index: 93;
  /* z-index: -1; */
}

.box-det {
  /* z-index: 9; */
}

.neagtive-index {
  z-index: 1;
}

/*  */
.balance {
  /* background: red; */
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 2fr;
  margin: 1rem 0;
}

.borderd-field-boxed {
  display: flex;
  width: 100%;
  justify-content: center;
}

.light-small-text {
  color: #fff;
  font-size: 0.85rem;
}

.balance h5 {
  font-size: 1.1rem;
  color: #fff;
  margin-top: 0.2rem;
}

.balance > div:not(.borderd-field-boxed) {
  padding: 0.8rem 1rem;
}

.borderd-field-boxed > div {
  border: 1px solid var(--text-end);
  padding: 0.8rem 1.6rem;
}

.enter-amount-box {
  position: relative;
  width: 100%;
}

.inputs {
  margin-top: 1.5rem;
}

.enter-amount-box input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid rgb(18, 255, 231);
  padding: 0.5rem 0.3rem;
  color: #fff;
  font-size: 1.11rem;
}

.enter-amount-box button {
  color: rgb(18, 255, 231);
  line-height: 1.2;
  background-color: transparent;
  outline: none;
  border: none;
  position: absolute;
  right: 0;
  bottom: 5px;
  cursor: pointer;
}

.terms {
  margin-top: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(18, 255, 231);
  font-size: 0.9rem;
}

.terms p {
  margin-bottom: 0;
}

.stake-btn-center {
  text-align: center;
  margin: 2rem auto;
  margin-top: 3rem;
}

.stake-btn {
  background-color: transparent;
  border: 2px solid var(--text-end);
  outline: none;
  padding: 0.5rem 2rem;
  font-size: 1.5rem;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 700;
}

/* Rewards */
.rewards {
  margin-top: 5rem;
}

.dfjb {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rewards .gradient-text {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.rewards .stake-box p {
  color: #f2f2f2;
}

.text-right {
  text-align: right;
}

.rewards .stake-box .date {
  margin: 1.5rem 0;
}

.total-stake-amount {
  margin: 3rem 0;
}

.total-stake-amount h4 {
  margin-top: 0.3rem;
}

/* SWAP */
.text-white {
  color: #fff;
}

.mt-3 {
  margin-top: 0.8rem;
}

.arrows {
  width: 30px;
  margin: 0 auto;
}

.arrows img {
  width: 100%;
}

.swap-boxes .gradient-box {
  background: transparent;
  border: 1px solid var(--text-end);
}

.swap-boxes .field {
  background: none;
}

.info {
  background-color: rgba(29, 36, 56, 0.733);
  padding: 1rem;
  border-radius: 10px;
  margin: 2rem 0;
}

.info h6 {
  margin-top: 0;
}

.top-section {
  /* max-width:100%; */
  margin-left: auto;
  margin-right: auto;
}

.top-section .top {
  display: none;
}

.top-section .stake-box {
  /* max-width: 100%; */

  margin-left: auto;
  margin-right: auto;
}

/* FAQ */
.faq-section {
  margin-top: 5rem;
  padding-bottom: 3rem;
}

.faq-section > div {
  flex: 1;
  border-radius: 20px;
  padding: 1rem;
  background-color: rgba(29, 36, 56, 0.733);
  color: #fff;
}

.faq-section > div > h1 {
  text-align: center;
  margin: 1rem auto;
  margin-bottom: 2rem;
}

.faq-section > div:last-of-type {
  margin-left: 30px;
}

/* Overview */
.overview p {
  line-height: 1.6;
  display: grid;
  grid-template-columns: 20px 1fr;
  margin: 0.7rem 0;
  /* align-items:flex-start; */
}

.overview p .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-top: 7px;
  /* display: grid; */
  /* place-content: center; */
  background-color: red;
  border-radius: 50%;
  background-color: var(--green-text);
}

/* .dot{
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
} */

@media (max-width: 982px) {
  .top-section {
    margin-top: 30px;
  }

  .stake-container {
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 10px;
  }

  .stake-options.top > a {
    display: inline-block;
  }

  .rewards .stake-options {
    text-align: center;
    margin-bottom: 1.6rem;
  }

  .faq-section > div:last-of-type {
    margin-left: 0px;
    margin-top: 2rem;
  }

  .row.reverse-order {
    flex-direction: column-reverse;
  }

  .margin-on-mb {
    margin-bottom: 1.5rem;
  }

  .cog-page__navigation {
    padding-bottom: 1.2rem !important;
  }
}

@media (max-width: 731px) {
  .balance {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .borderd-field-boxed {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .terms {
    flex-wrap: wrap;
  }

  .terms > * {
    margin: 0.2rem 0;
  }
}

/* COG */
.cog-header__text {
  display: none !important;
}

.cog-header {
  display: none !important;
}

.cog-body {
  margin-top: 0 !important;
}

.cog-page__navigation {
  padding-top: 0 !important;
}

.fz13px {
  font-size: 13px;
}

.social-icons i {
  cursor: pointer;
}

footer input {
  background-color: transparent !important;
  color: #fff !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
}

footer input::placeholder {
  color: #f3f3f3 !important;
}

.social-icons i:hover {
  color: #fff;
  filter: brightness(80%);
}
