/* REWARD  PAGE */
.three-box-reward {
  /* background: red; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  /* border: 1px solid #e7e7e7; */
  grid-gap: 15px;
  border-radius: 5px;
  margin-bottom: 1rem;
}

#image-rewards {
  width: 100px;
  margin-right: 0.5rem;
}

.box-reward {
  padding: 20px 15px;
  background: rgba(43, 63, 98, 0.6);
  border: 1px solid rgba(33, 47, 71, 0.6);
  border-radius: 10px;
}

.box-reward.first {
  display: flex;
  align-items: center;
}

.reward-box-head {
  margin-bottom: 0rem;
  font-size: 0.95rem;
}

.large-amount {
  margin-bottom: 0;
  font-size: 1.4rem;
  font-weight: 500;
}

.large-amount small {
  font-size: 0.8rem;
}

.smaller {
  font-size: 0.85rem;
  font-weight: 500;
}

.lighter {
  color: rgb(121, 121, 121);
  font-size: 0.85rem;
}

.claim-reward {
  padding: 3rem 15px;
  color: #000;
  font-weight: 400;
  background-color: #fcfcfc !important;
}

.claim-reward p {
  font-size: 0.95rem;
}
.claim-rewards-tabs .tabs-link-rewards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 15px;
}
.tabs-link-rewards a {
  display: inline-block;
  background-color: #23324e;
  background-color: #1d1d2a;
  border: 2px solid #b250be;
  border-radius: 100px;
  margin: 1rem 0;
  padding: 1rem 0;
  text-align: center;
  color: #fff !important;
  font-size: 1.3rem;
  font-weight: 400;
  /* border: 2px solid transparent; */
  filter: brightness(90%);
}
.tabs-link-rewards a.active {
  border: 2px solid #b250be;
  background: linear-gradient(
    40deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );
  filter: brightness(100%);
}

.total-rewards-claim {
  margin-top: 1rem;
  background-color: #23324e;
  border-radius: 10px;
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.total-rewards-claim .image-partt {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
.total-rewards-claim .image-partt img {
  margin-right: 1rem;
}
.claim-button button {
  border: none;
  outline: none;
  padding: 0.7rem 1.2rem;
  margin-left: 2rem;
  background-color: #7266ef;
  color: #fff;
  min-width: 150px;
  font-weight: 600;
  border-radius: 10px;
}
.claim-button button:hover {
  filter: brightness(90%);
}
.claim-button button:focus {
  transform: scaleY(1.03);
}
@media (max-width: 650px) {
  .total-rewards-claim {
    flex-direction: column;
  }
  .total-rewards-claim .image-partt {
    margin-right: 0;
  }
  .claim-button button {
    margin-left: 0;
    margin-top: 1.3rem;
  }
  .tabs-link-rewards a {
    padding: 0.5rem 0;
    font-size: 1rem;
  }
}

/* NEW REWARDS PAGE  */
.rewards-box-section {
  // background: #23324e91;
  padding: 20px;
  border-radius: 15px;
}
.rewards-boxes-claim {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 25px;
}
.rewards-boxes-claim .claim-button {
  display: grid;
  place-content: center;
}

.rewards-boxes {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
.rewards-boxes > div {
  border-radius: 15px;
  background: var(--sidebar-bg);

  padding: 1.5rem;
}

.reward-box h2 {
  font-size: 1.8rem;
}
.rewards-table th {
  font-weight: 600;
  vertical-align: top;
}
.rewards-table td {
  font-weight: 400;
}
.lh-1 {
  line-height: 1;
  margin-top: 2px;
}
.rewards-table {
  margin: 1rem 0;
  overflow: hidden;
  border-radius: 15px;
  background: var(--white);
  padding: 20px;
  border-radius: 15px;
  border: var(--light-border);
}
.table > :not(:first-child) {
  border-top: 1px solid transparent;
}

.dropdown-rewards select {
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.dropdown-rewards option {
  background-color: #1d2941;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 992px) {
  .rewards-boxes-claim {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .reward-box h2 {
    font-size: 1.4rem;
    margin-top: -10px;
  }
}
@media (max-width: 350px) {
  .reward-page-new.container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .rewards-box-section {
    /* background: #23324e91; */
    /* padding: 15px; */
    /* border-radius: 15px; */
  }
  .reward-box p {
    font-size: 0.9rem;
  }
  .reward-box h2 {
    font-size: 1.2rem;
    line-height: 1;
    margin-top: 10px;
  }
  .rewards-boxes {
    grid-template-columns: repeat(auto-fit, minmax(191px, 1fr));
  }

  .rewards-boxes > div {
    padding: 1.5rem 1rem;
  }
  .rewards-table {
    margin: 1rem 0;
    overflow: hidden;
    border-radius: 15px;
    background: #23324e91;
    padding: 5px;
    border-radius: 15px;
  }
  .reward-page-new h3 {
    font-size: 1rem;
  }
}

.staked-by-tble {
  max-width: 100%;
  display: inline-block;
  overflow-wrap: break-word;
  font-size: 0.9rem;
  margin: auto;
}

/* .rewards-drop-wallet{
  display:grid;
  grid-template-columns:1fr 250px;

}
.rewards-drop-wallet .connect-wallet-box{
  text-align: center;
  align-items: center;
}

.rewards-drop-wallet .dropdown-rewards{
  max-width: 250;
  align-self: center;

  box-shadow: 1px 1px 5px #1d2a52;
  
} */

@media (max-width:600px){
  /* .rewards-drop-wallet{
    grid-template-columns:1fr;
  
  }
  .rewards-drop-wallet .dropdown-rewards{
    max-width: 250px;
    width: 90%;
    margin: auto;
    align-self: center;
    margin-top: 1.2rem;
  } */
  
}