.map-container {
  height: 100%;
  width: 100%;
  background-color: #eee;
}

span.text-center {
  font-size: 13px;
}

.address .suggestion-item {
  background: #fff !important;
  color: #000 !important;
}

.address .suggestion-item--active {
  background: #000 !important;
  color: #fff !important;
}

.address input {
  width: 100%;
}

.submit-btn {
  max-width: 190px;
}

.first-submit-btn {
  width: max-content;
  /* padding-left: 14px; */
  /* padding-right: 14px; */
}

.two-nest-btn {
  display: flex;
  justify-content: space-between;
}
.miner header {
  position: absolute;
  top: 0;
  left: 0;
}
