.ncash-page{
//   background-image: url("../../../components/pages/images/ncash/bg.png");
//   background-size: cover;
//   background-repeat: no-repeat;
}

.ncash-page .home-page{
  .headH3 {
    text-align: center;
    font-size: 2.1rem;
    color: var(--color-dark-grey);
    font-weight: 600;
    text-transform: uppercase;
    margin: 1.2rem 0;
    letter-spacing: 1px;
    margin-bottom: 1.2rem;
    }
    
  section {
      margin: 2.4rem 0 0 0 ;
      padding: 2.5rem 1.2rem;
  }

  .container-home {
      max-width: 70rem;
      margin: auto;
  }

  .desc-text {
      font-weight: 300;
      line-height: 1.6;
      text-align: center;
      font-size: 1rem;
      color: var(--color-dark-grey);
      width: 90%;
      margin: 0 auto 1.5rem auto;
  }
  
  .quick-take {
      padding: 2rem 1rem;
  
      &__boxes {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          grid-gap: 1.2rem;
  
          &-box {
              padding: 1.2rem;
              background: var(--light-primary);
              /* Note: backdrop-filter has minimal browser support */
              border-radius: 0.5rem;
              text-align: center;
              &-image {
                  width: 100%;
                  height: 100%;
                  max-height: 150px;
                //   max-width: 150px;
                //   margin: auto;
                //   aspect-ratio: 1 / 1;
              }
  
              p {
                color: var(--color-dark-grey);
                  font-size: 0.85rem;
                  text-align: center;
                  font-weight: 500;
                  max-width: 200px;
                  margin: 1rem auto 0 auto;
              }
          }
      }
  }
  
  .ncashAllocation {
      .ncash-allocation {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
          grid-gap: 2.5rem;
          &__graph {
            @include whiteBoxStyle;
              padding: 1.5rem;
              width: 100%;
              & > img {
                  width: 100%;
                  height: 100%;
              }
          }
  
          &__text {
              color: var(--color-dark-grey);
              ul {
                  list-style: disc;
                  padding-left: 1rem;
              }
              li {
                  margin: 1rem 0;
                  line-height: 1.5;
                  font-weight: 300;
              }
          }
      }
  }
  
  .whereToBuy {
      &__options {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
          grid-gap: 2.5rem;
      }
      &__option {
        @include whiteBoxStyle;
          padding: 3rem 1.5rem;
          text-align: center;
          border: var(--primary-border);
          img {
              width: 100%;
              max-width: 200px;
              margin: auto;
          }
          &:hover{
            background-color: var(--sidebar-bg);
          }
      }
  }
  
  .ncash__distribution {
      display: grid;
      grid-template-columns: 1fr 2.5fr;
      grid-gap: 0 1.2rem;
      margin: 1rem 0;
      color: var(--color-dark-grey);
      font-weight: 300;
  
      & > div {
          padding: 1rem;
          
      }
      &--allocation {
        background-color: var(--white);
          text-align: center;
          /* Note: backdrop-filter has minimal browser support */
        
          border-radius: 8px;
          display: grid;
          place-content: center;
          h3 {
              font-weight: 300;
          }
          &.bg-purple-grad {
            //   background-image: url("../../../components/pages/images/ncash/header/burnedThisMonthBack.png");
            //   background-size: cover;
            //   background-repeat: no-repeat;

            background-color: var(--color-primary);
          color: var(--white);
              h3 {
                  font-weight: 600;
                  color: var(--white);

              }
          }
      }
      &--description {
            background-color: var(--white);
          border-radius: 8px;
          &.bg-white-grad {
              text-align: center;
            //   background-image: url("../../../components/pages/images/ncash/header/BurnedAllTime.png");
            //   background-size: cover;
            //   background-repeat: no-repeat;
            //   background-position: 0 0;
            //   color: #000;
            background-color: var(--color-primary);
            color: var(--white);
              h3{
                  font-weight: 600;
                  color: var(--white);
              }
          }
          ul {
              list-style: disc;
              padding-left: 1rem;
          }
          li {
              margin: 0.5rem 0;
          }
      }
      @media (max-width:650px) {
          grid-template-columns: 1fr;
  
          & > div {
              border-radius: 0;
              backdrop-filter: none;
              &:first-of-type{
                  border-bottom: 1px solid rgba(145, 145, 145, 0.41);
              }
          }
      }
  }
  
  .ourMedium {
      &__articles {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
          grid-gap: 2rem;
      }
  }
  
  .terms-text {
      max-width: 60rem;
      margin: 2rem auto;
      margin-top: 3rem;
      color: var(--color-dark-grey);
      font-weight: 500;
      ul {
          list-style: disc;
          padding-left: 1rem;
      }
  
      p,
      li {
          line-height: 1.6;
          margin: 0.5rem 0;
      }
  }


  .heading-secondary{
      font-family: inherit;
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      
      text-align: center;
      text-transform: uppercase;
  
      color: var(--color-dark-grey);
  
      @media (max-width:580px) {
          font-size: 1.5rem;
      }
  }
  .mb-small{
      margin-bottom: 1.5rem;
  }
  
  .bold-text {
      font-weight: 400;
  }
  .p-small {
      padding: 1.2rem;
  }
  img {
      width: 100%;
      height: 100%;
  }
  .text-uppercase {
      text-transform: uppercase;
  }
  .gradient-button {
      display: inline-block;
      text-align: center;
      padding: 0.6rem 1rem;
      min-width: 150px;
      background: var(--gradient-purple);
      color: #fff;
      border-radius: 5px;
  }
  .transparent-box {
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(7px);
      /* Note: backdrop-filter has minimal browser support */
      border-radius: 8px;
  }
  .text-black{
      color: #000;
  }
}


// Header
.ncash-page .home-page .header{
  width: 100%;
  min-height: 90vh;
  margin-top: 80px;
  background-image: url('../../../components/pages/images/ncash/headerBackLight.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
//   @media ( max-width:580px){
//       background-image: url('../../../components/pages/images/ncash/headerBackMobile.png');
//   }
  &__content{
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      align-items: center;
      grid-gap: 2rem;
      max-width: 70rem;
      margin: 1rem auto;
      @media (max-width:820px) {
       grid-template-columns: 1fr ;
          &--image{
              max-width: 90%;
              margin: auto;
          }
      }
     &--image{
      width: 100%;
      height: 100%;
      img{
          width: 100%;
          height: 100%;
      }
     }

     &--text{
        background-color: var(--white);
      color: var(--color-dark-grey);
      border:1px solid var(--color-primary);
      border-radius: 5px;
      h1{
          text-transform: uppercase;
          font-size:3rem;

          font-weight: 700;
          margin-bottom: 1rem;
      }
      p{
          font-size: 1.1rem;
          line-height: 1.6;
          font-weight: 300;
          max-width: 550px;
      }
     }
  }
}