.footerlogo img {
  margin-top: 0;
  margin-bottom: 1rem;
}
.newsletter #c-1-2 {
  background: transparent !important;
}

.Fontsize19 {
  font-size: 19px !important;
}
.newsletter {
  width: 100%;
  text-align: center;
  margin: 0px auto 0px auto;
}

.newsletter form {
  position: relative;
}

/* .newsletter form input {

    width: 100%;
    height: 48px;
    border-radius: 30px;
    border: solid 1px rgba(255, 255, 255, 0.2) !important;

    padding: 0px 30px;
    background: none !important;
    outline: none;

} */
.btn-main-delete:hover {
  box-shadow: 2px 2px 20px 0px #bb2d3b;
  transition: all 0.3s ease;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-main-delete {
  /* display: block; */
  text-align: center;
  color: #fff !important;
  background-color: #dc3545;
  border-color: #dc3545;
  border-radius: 5px;
  letter-spacing: normal;
  outline: 0;
  font-weight: 600;
  text-decoration: none;
  padding: 8px !important;
  font-size: 14px;
  width: fit-content;
  border: none;
  cursor: pointer;
  box-shadow: 2px 2px 20px 0px rgb(131 100 226 / 0%);
  transition: all 0.3s ease;
}

.btn-main-update:hover {
  box-shadow: 2px 2px 20px 0px #ffc107;
  transition: all 0.3s ease;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-main-update {
  /* display: block; */
  text-align: center;
  color: #fff !important;
  background-color: #ffc107;
  border-color: #ffc107;
  border-radius: 5px;
  letter-spacing: normal;
  outline: 0;
  font-weight: 600;
  text-decoration: none;
  padding: 8px !important;
  font-size: 14px;
  width: fit-content;
  border: none;
  cursor: pointer;
  box-shadow: 2px 2px 20px 0px rgb(131 100 226 / 0%);
  transition: all 0.3s ease;
}

.ant-btn-primary {
  background: #7366f0 !important;
}

.btn-main1:hover {
  box-shadow: 2px 2px 20px 0px #7366f0;
  transition: all 0.3s ease;
}
.btn-main1 {
  /* display: block; */
  text-align: center;
  color: #fff !important;
  background: #7366f0;
  border-radius: 5px;
  margin: 0px 14px 0px 0px;
  letter-spacing: normal;
  outline: 0;
  font-weight: 600;
  text-decoration: none;
  padding: 8px !important;
  font-size: 14px;
  width: fit-content;
  border: none;
  cursor: pointer;
  box-shadow: 2px 2px 20px 0px rgb(131 100 226 / 0%);
  transition: all 0.3s ease;
}
.newsletter .btn-main1 {
  background: #7366f0 !important;
}
.newsletter textarea {
  border: solid 1px rgba(255, 255, 255, 0.2) !important;
  background: transparent;
}

.newsletter form button.nv-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.copyright-text {
  margin-top: 65px;
}

.relative {
  position: relative;
}

.footer-links {
  margin: 0px 0px 0px 15px;
  padding: 0px;
}

.footer-links li {
  list-style: circle;
}

.footer-links li a {
  color: rgba(255, 255, 255, 0.4);
  line-height: 30px;
  text-decoration: none;
  transition: all 0.5s;
}

.footer-links li a:hover {
}

.footer-divider {
  /*border-left: solid 1px rgba(255, 255, 255, 0.2);
	border-right: solid 1px rgba(255, 255, 255, 0.2);*/
  padding-left: 60px;
}

.community-icons {
  margin: 15px auto 0px auto;
  padding: 0px;
  list-style: none;
}

.community-icons li {
  text-align: center;
  display: inline-block;
  margin: 0px 10px 0px 10px;
  line-height: normal;
}

.community-icons li div {
  background: #3a404d;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 6px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.6s ease;
}

.community-icons li div:hover {
  background: #1c99fe;
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.community-icons li div i {
  font-size: 30px;
  vertical-align: middle;
  line-height: 50px;
  color: #fff;
}

.community-icons li h4 {
  color: #1c99fe;
  font-size: 21px;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

.community-icons li small a {
  color: #1c99fe;
  text-decoration: none;
  font-size: 10px;
  transition: all 0.5s;
}

.community-icons li small a:hover {
  color: var(--head);
}

.fixed-community-icons {
  right: 10px;
  position: fixed !important;
  width: 60px !important;
  bottom: 70px !important;
  z-index: 99998;
}
footer .widget h5 {
  margin-bottom: 5px;
}
.footer-newsletter .cog-heading {
  display: none !important;
}
html.el-input__inner {
  background: transparent !important;
}

.social-icons {
  background-size: cover;
  display: flex;
}
.social-icons.hide-on-desktop {
  display: none;
}

.social-icons span:hover {
  filter: brightness(80%);
}
.social-icons span {
  width: 34px;
  height: 34px;
  display: grid;
  place-content: center;
  margin: 1rem 0.2rem;
  cursor: pointer;
}
header.smaller {
  background: #292f45 !important;
}


.cog-header__description {
  color: #ffffff;
}

@media (max-width: 982px) {
  .social-icons.hide-on-desktop {
    display: flex;
  }
  footer .widget {
    padding-bottom: 10px !important;
  }
}
.ptm-0 {
  margin-top: 0 !important;
}

.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border-color: #ff4d4f !important;
}

/* //------------------------------------footer---------------------------------------------------------------------------------------- */
/* .footer-links {
    margin: 0px 0px 0px 15px;
    padding: 0px;
}

.footer-links li {
    list-style: circle;.footerlogo img{

                           text-align: left;
}

.footer-links li a {
    color: rgba(255, 255, 255, 0.4);
    color: var(--dull);
    line-height: 30px;
    text-decoration: none;
    transition: all 0.5s;
}



.alignInitial {
    text-align: initial !important;
}

.footer-links li a:hover {
    color: rgba(255, 255, 255, 0.75);
}
.community-icons {
    margin: 15px auto 0px auto;
    padding: 0px;
    list-style: none;
}

.community-icons li {
    text-align: center;
    display: inline-block;
    margin: 0px 10px 0px 10px;
    line-height: normal;
}

.community-icons li div {
    background: #3a404d;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 6px;
    margin-left: auto;
    margin-right: auto;
    transition: all .6s ease;
}

.community-icons li div:hover {
    background: #1c99fe;
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);

}

.community-icons li div svg {
    font-size: 30px;
    vertical-align: middle;
    line-height: 50px;
    margin: 10px;
    color: #FFF;
}

.community-icons li h4 {
    color: #1c99fe;
    font-size: 21px;
    font-weight: 300;
    margin: 0;
    text-align: center;
    padding: 0;
}

.community-icons li small a {
    color: #1c99fe !important;
text-decoration: none;
font-size: 10px;
transition: all 0.5s;
}

.community-icons li small a:hover {
    color: rgba(255, 255, 255, 0.75);
}

.fixed-community-icons {
    right: 10px;
    position: fixed !important;
    width: 60px !important;
    bottom: 70px !important;
    z-index: 99998;
}

.social-icons {
    text-align: center;
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.social-icons a {
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.5);
    border: solid 3px rgba(255, 255, 255, 0.2);
    font-size: 21px;
    line-height: 45px;
    color: #FFF;
    border-radius: 50%;
    display: inline-block;
    margin: 5px;
    transition: all .6s ease;
}

.social-icons a:hover,
.social-icons a:focus {
    background: #ff6c0d;
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
} */
/* .ant-input-affix-wrapper-lg {
  padding: 0px;
  font-size: 16px;
} */

/* .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: #161d30 !important;
}

.kyc-page
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: #0c1e4e !important;
} */

.colorviolet {
  color: #7366f0 !important;
}
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  margin: 0px;
}
.anticon svg {
  display: inline-block;
  color: var(--color-dark-grey);
  margin-top: 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  color: var(--color-dark-grey);
  left: -1px;
  padding: 0;
  background: no-repeat;
  border: 0;
}
.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  background: none;
  border-radius: 0 2px 2px 0;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  background: none;
  color: var(--color-dark-grey);
  margin-bottom: 0;
  text-align: inherit;
}
.ant-form-item-control-input-content .ant-input {
  border-radius: 5px;
}
.backgroundColordarkhome {
  background-color: #161d30;
}

.backgroundColordark {
  /* background-color: #161d30; */
  padding: 0px 4% 50px;
  min-height: 69vh;
}
.backgroundColordarkFooter {
  background-color: #292f45;
  padding: 24px 20px 50px;
}

/* Pending Payments  OPEN */
.pending-page {
  margin: 3rem auto;
  /* background: red; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 20px;
}

.summary h2 {
  text-align: center;
  font-size: 1.4rem;
  color: var(--head);
  margin: 1.5rem 0;
}

.pay-summary {
  display: grid;
  /* grid-template-rows: 80% 20%;
    padding: 1rem; */
}

.pay-summary h1 {
  background: #7366f0;
  display: grid;
  place-content: center;
  border-radius: 10px;
  color: var(--head);
  color: #fff;
  width: 100%;
  margin: inherit !important;
  max-width: 650px;
  text-align: center;
  padding-top: 0rem !important;
}

.pay-summary .btn-submit {
  background: #7366f0;
  border: none;
  display: grid;
  place-content: center;
  min-width: 150px;
  margin: auto;
  font-size: 25px;
}

.pay-summary a {
  background: #7366f0;

  margin-top: 1rem;
  text-align: center;
}

.details-mycollection-box {
  display: flex;
  justify-content: space-around;
}

.details-mycollection-box p {
  font-weight: 600;
  /* font-size: 1rem; */
}
.collect-boxes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: 1rem;
  padding: 1rem;
}
.details-mycollection-box {
  padding: 1.2rem 0.9rem;
  text-align: center;

  border-radius: 10px;

  border: 1px solid var(--border);
}
#pay-summary > h1 {
  background: #7366f0;
  display: grid;
  place-content: center;
  border-radius: 10px;
  color: var(--head);
  color: #fff;
  width: 100%;
}
.details-mycollection-box h4 {
  font-size: 1.2rem;
}

.details-mycollection-box p {
  color: #ffffff;
  font-size: 0.85rem;
  margin-bottom: 0;
}
/* Pending Payments  CLOSE */
@media (max-width: 780px) {
  .pending-page {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

/* STYLE FOR THIS PAGE NEEDS IMPORTANT */
/* .backgroundColordark {
    background-color: #161d30;
    padding: 0px 0px 50px !important;
} */

.pay-summary {
  display: grid;
  /* grid-template-rows: 80% 20%;
    padding: 1.5rem 0; */
}
.paybuttonOnPayments {
  margin: auto;
}

.dfjb {
  margin: 0px;
  display: flex;
  justify-content: space-between;
}


.dfjb a {
  /* font-family: TommyLight; */
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
}
.colorWhite {
  color: #ffffff;
}
/* .tabs-links {
    margin-top: 1.5rem;
    overflow-x: scroll;
    position: relative;

    width: 100%;
    overflow-x: auto;
} */

.tabs-links ul {
  max-width: 100%;
  margin: 1.5rem 0px;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  /* border-bottom: 1px solid #f3f3f3; */
}

.tabs-links ul li:first-of-type {
  margin-left: 0;
}

/* NEW ADDED OPEN */
.tabs-links ul li {
  margin: 0 0.8rem;
  text-align: center;
  display: flex;
  width: 180px;
  text-align: center;
  /* background: red; */
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #7366f0;
}
/* .tabs-links ul li :hover {
      box-shadow: 2px 2px 20px 0px #7366F0;
          transition: all 0.3s ease;
} */
.tabs-links > ul::-webkit-scrollbar {
  display: none;
}

.tabs-links ul li:hover {
  /* background: #7366F0; */
  filter: brightness(80%);
  color: #fff;

  /* border-bottom: 1px solid var(--head); */
}

.tabs-links ul li.active {
  background: #7366f0;
  /* border-bottom: 3px solid var(--head); */
  color: #fff;
}

.tabs-links ul li.active a {
  /* color: var(--head); */
  color: #fff !important;
}

.tabs-links ul li a {
  /* display: inline-block; */
  font-weight: 500;
  text-align: center;
  /* border: 1px solid red; */
  color: #777 !important ;
  padding: 0.6rem 0rem;
  padding-left: 5px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* min-width: 120px; */
}

.tabs-links ul li img {
  display: inline-block;
  margin-right: 5px;
  margin-left: 10px;
}
.alignCenter {
  text-align: center;
}
.ant-form-item-explain-error {
  color: #ff6c0d;
  text-align: left;
  font-weight: 500;
  padding: 0px 0px 0px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #000000 !important;
  text-align: left;
  font-weight: 500;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #d9d7d7 !important;
}

.width200 {
  width: 200px;
}
/*  MY Collection */
.my-collections {
  max-width: 60em;
  margin: 1.5rem auto;
}

.collection {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border: 1px solid var(--border);
  border-radius: 20px;
  /* background-color: #f5f5f5; */
  /* background-color: #f5f5f593; */
  margin: 1rem 0;
}

.image-collection {
  max-width: 250px;
  max-height: 350px;
  /* background-color: #fff; */
  border: 1px solid var(--border);
  text-align: center;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

#collection-item-img {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* width: 300px; */
}

.details-mycollection-box h4 {
  font-size: 1.2rem;
}

.details-mycollection-box p {
  font-size: 0.85rem;
  margin-bottom: 0;
}

@media (max-width: 650px) {
  .collection {
    flex-direction: column;
  }

  .image-collection {
    max-width: 100%;
    height: 350px;
    text-align: center;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }

  #collection-item-img {
    /* border-bottom-left-radius: 20px; */
    border-top-right-radius: 20px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* width: 300px; */
  }
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #7366f0 !important;
  border-color: #7366f0 !important;
  color: #ffffff;
}
.ant-input-suffix {
  border-bottom: none !important;
  margin-left: 0px !important;
}
.ant-pagination-item a {
  color: #ffffff !important;
}
/* -------------------------------------------------home css--------------------------------- */
/* ------------------------------swal------------------------ */
.swal-modal {
  width: 358px;
  opacity: 0;
  pointer-events: none;
  background-color: var(--sidebar-bg) !important;
  color: var(--color-dark-grey);
}
.swal-footer {
  text-align: center !important;
}
.swal-icon--success__hide-corners {
  background: none !important;
}
.swal-icon--success:after,
.swal-icon--success:before {
  background: none !important;
}
.swal-title {
  color: var(--color-dark-grey) !important;
}
.swal-text {
  color: var(--color-dark-grey) !important;
  text-align: center !important;
}
.swal-button {
  background-color: var(--color-primary) !important;
}
.colorPurple {
  color: var(--color-primary) !important;
}
.copy {
  /* text-align: center; */

  color: #ffffff;
}
.purple-color-link {
  color: #7366f0;
}
.purple-color-link:hover {
  color: #7466f0df !important;
}
@media (max-width: 620px) {
  .less-padding-in-mobile {
    padding: 50px 0 20px 0;
  }
  .swal-modal {
    width: 92%;
  }
  .navbar .navbar-item .lines {
    display: none;
  }
}
@media (max-width: 982px) {
  .less-padding-in-mobile {
    padding: 0;
  }
  .less-padding-in-mobile h1 {
    padding-top: 0;
    padding-bottom: 1.2rem;
  }
}
