

/* :root {
  --color-white: #fff;
  --color-green: #4dfac3;
  --color-sky: #4ce7fd;
  --color-dull: #ccc;

  --body-bg-new: #00153e;
  --family: "Inter", sans-serif;
  --green-color: #00ffc4;
  --pink-color: #e017f3;
  --teal-color: #0088b2;
} */


.mx-width {
  max-width: 90em;
  margin: auto;
}

/* header {
  
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  background: var(--body-bg);
}
header .container {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
} */
/* a {
  color: #fff;
} */
.dflex li {
  margin: 0 0.7rem;
}

li {
  list-style: none;
}

.dflex {
  display: flex;
  align-items: center;
}

.icons {
  display: none;
}

.icons i {
  font-size: 1.8rem;
  color: #0088b2;
}

.btns-header {
  display: flex;
  align-items: center;
}

.logo {
  width: 180px;
  /* height: 1  00px; */
}

.logo img {
  width: 100%;
}

.border {
  display: none;
}

/* ------------------------------------------
  RESPONSIVE NAV STYLES
--------------------------------------------- */

/* 
    MAIN PAGW
 */
.video-section-page {
  position: relative;
  margin-top: 90px;
  height: 90vh;
  /* background-image: url("../images/Eco/header.jpeg"); */
  /* background-image: url("../images/Eco/header.jpeg"); */
  background-image: url("../images/animatedBG.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background-color: rgba(0, 21, 62, 0.7);
  background-blend-mode: multiply; */
}

.video-section-page .video-back {
  width: 100%;
  height: 100%;
  /* position: relative; */
}

.video-section-page video {
  z-index: 1;
  width: 100%;
  object-fit: cover;
}

video[poster] {
  object-position: bottom;
}

.video-section-page .text {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  z-index: 99;
}

.video-section-page .arrow {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  z-index: 99;
}

.video-section-page .text h1 {
  font-size: 3rem;
  text-align: center;
  color: #fff;
  max-width: 670px;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    infinite alternate-reverse both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
    alternate-reverse both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

.main {
  height: 90vh;
  margin-top: 80px;
  background-image: url("../images/Eco/Earth2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--body-bg);
  background-blend-mode: multiply;
}

.main-top {
  background-image: url("../images/Eco/Pointer.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: inherit;
}

.video {
  position: absolute;
  top: 0;
  /* height: 100vh; */
  width: 100%;
}

video {
  width: 100%;
  height: 100%;
}

.main-top .flex-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  max-width: 85rem;
  margin: 0 auto;
  padding: 0 1.5rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 9;
}

.main-top .flex-container .text-side {
  max-width: 100%;
}

.main-top .flex-container .text-side h1 {
  padding-top: 5rem;
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  /* line-height: 3rem; */
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #fff;
  width: 100%;
  /* max-width: 850px; */
}

.main-top .flex-container .text-side h1 span {
  color: var(--green-color);
}

.main-top .flex-container .text-side p {
  font-family: var(--family);
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 1.8rem;
  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #ffffff;
  margin-top: 2.5rem;
}

.main-top .flex-container .imgage-side {
  flex: 0.8;
  display: none;
}

/* NFM MARKETPLACE */
.nfm-marketplace-section {
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(42px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 5px;
  z-index: 12;
  overflow: hidden;
}

.nfm-marketplace-section .flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 85rem;
  margin: auto;
  padding: 2rem 1rem;
}

.nfm-marketplace-section .flex-container .text-side-nfm {
  flex: 1;
}

.nfm-marketplace-section .flex-container .text-side-nfm h2 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.25rem;
  /* identical to box height */

  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #ffffff;
}

.nfm-marketplace-section .flex-container .text-side-nfm p {
  font-family: var(--family);
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.4;

  color: #ffffff;
  margin-top: 1rem;
  max-width: 650px;
}

.nfm-marketplace-section .flex-container .image-side-nfm {
  flex: 0.7;
}

.nfm-marketplace-section .flex-container .text-side-nfm .two-buttons {
  max-width: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2rem 0;
}

.btn-ncta.pink {
  border-color: var(--pink-color) !important;
  color: #fff;
}

.btn-cta.pink {
  background: var(--pink-color);
  border: 1px solid var(--pink-color);
  color: #fff;
  margin-left: 1rem;
}

.btn-cta.sky {
  min-width: 150px;
  font-size: 1.1rem;
  font-weight: 600;
}

.nfm-marketplace-section .flex-container .image-side-nfm .image-black-layer {
  /* background: url(NFT_Image.png); */
  box-shadow: 0px 4px 4px 6px rgba(0, 0, 0, 0.25);
  transform: rotate(5.34deg);
  /* margin-top: -2rem; */
  z-index: 9;
}

.nfm-marketplace-section
  .flex-container
  .image-side-nfm
  .image-black-layer
  img {
  width: 100%;
  height: 100%;
  transform: rotate(355deg);
}

/* ECOSYSTEM */

.ecosystem {
  background-image: url("../images/Eco/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /* background-color: var(--body-bg); */
  /* background-color: #196ed2; */
  background-blend-mode: multiply;
  padding: 2rem 0;
  position: relative;
}

.ecosystem .first {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.25;
  /* identical to box height */

  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #ffffff;
  margin-bottom: 8px;
}

.ecosystem .conatiner {
  max-width: 85em;
  margin: auto;
}

.ecosystem .first-p {
  font-family: var(--family);
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 27px;
  text-align: center;
  max-width: 85%;
  margin: auto;
  color: #ffffff;
}

.first span {
  color: var(--green-color);
}

.multicoin-section {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 2.5rem;
  margin-top: 4rem; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: row wrap;
}

.multicoin-section .multi-box {
  color: #fff;
  text-align: center;
  width: 350px;
}

.multi-box .multi-img {
  text-align: center;
  width: 100%;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
  border: 2px solid #0f254e;
}

.multi-box .multi-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 2s linear;
}

.multi-box .multi-img img:hover {
  transform: scale(1.2);
}

.multi-box h2 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #ffffff;
  margin: 0.5rem 0;
}

.multi-box p {
  /* width: 90%; */
  max-width: 400px;
  margin: auto;
  font-family: var(--family);
  font-style: normal;
  font-weight: normal;
  font-size: 0.89rem;
  line-height: 17px;
  text-align: center;

  color: #ffffff;
}

#marketplace-card {
  position: relative;
}

#marketplace-card > #coming-soon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.border-radial {
  width: 100%;
  height: 50px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #cddbff 0%,
    rgba(62, 167, 244, 0.901042) 36.46%,
    rgba(0, 102, 255, 0) 100%
  );
  mix-blend-mode: color-dodge;
  margin: 4rem auto;
}

.executive {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 36px;
  /* identical to box height */

  letter-spacing: 0.25em;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: #ffffff;
}

.profile {
  max-width: 70rem;
  margin: auto;
}

.profile-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1.5rem;
  text-align: center;
}

.profile-one {
  background: #00040e;
  border-radius: 5px;
  padding: 1.5rem 1rem;
}

.profile-one h3 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.008em;
  text-align: center;
  color: #5a6688;
  margin: 0.2rem 0;
}

.profile-one p {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 15px;

  text-align: center;
  letter-spacing: 0.008em;
  margin: 0.2rem 0;
  color: #869ddc;
}

.balls {
  position: absolute;
  right: 0;
  bottom: 10%;
  z-index: -1;
}

/* MEDIA MENTIONS */
.media-mentions {
  margin: 3rem 0;
  position: relative;
}

.media-box {
  width: 85%;
  max-width: 85rem;
  margin: auto;
  background: #060607;
  border: 1px solid #00a4cb;
  box-sizing: border-box;
  border-radius: 10.7776px;
  padding: 2rem 0;
}

.media-box h2 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.87rem;
  line-height: 2.25rem;
  /* identical to box height */

  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #00a4cb;
}

.media-box .media-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 45vh;
  justify-content: space-between;
  margin-bottom: 7vh;
}

.media-link {
  width: auto;
  height: 13vh;
}

#media-mention-ctg {
  height: 14.5vh;
}

#media-mention-io {
  height: auto;
  width: 30%;
}

.media-link > img {
  width: 100%;
  height: 100%;
}

.media-mentions .border-radial {
  max-width: 550px;
  z-index: 2;
  position: absolute;
  left: 50%;
  bottom: -20%;
  transform: translateX(-50%);
}

/* EXPLORE ECOSYSTEM */
.explore-ecosystem {
  /* min-height: 90vh; */
}

.explore-ecosystem .content-bottom {
  background-image: url("../images/Eco/BottomArrow.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  width: 100%;
  padding: 5rem 0;
  padding-bottom: 3rem;
  margin-top: -4rem;
  height: 100%;
}

.explore-ecosystem .content-bottom .content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 85rem;
  margin: auto;
}

.explore-ecosystem .content-bottom .content .text-content {
  flex: 1;
  max-width: 650px;
}

.explore-ecosystem .content-bottom .content .image-content {
  /* flex: 0.8; */
}

.explore-ecosystem .text-content h1 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.25rem;
  /* identical to box height */

  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #ffffff;
  margin: 1rem 0;
}

.explore-ecosystem .text-content p {
  font-family: var(--family);
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 27px;

  color: #ffffff;
}

/* CONTACT US */

/* FOOTER */


/* SPLASH MAIN SCREEN */
.splash-screen-main {
  /* padding: 2rem 0; */
  height: 100vh;
  background-image: url("../images/Eco/Earth2.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--body-bg);
  background-blend-mode: multiply;
}

.splash-screen-main .container {
  min-height: 100vh;
  margin-top: 30px;
  /* margin-top: 12vh; */
  padding: 4rem 0;
  text-align: center;
  display: grid;
  place-content: center;
}

.splash-screen-main h1 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #97ffe7;
  margin-bottom: 2rem;
}

.splash-screen-main h2 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.25em;
  color: #fff;
}

.splash-screen-main h2.green {
  margin-top: 2rem;
  color: #97ffe7;
}

.our-partners {
  text-align: center;
}

.our-partners .partners-container {
  max-width: 75em;
  padding: 1rem;
  margin: auto;
}

.our-partners .partners-container h2 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.25em;
  color: #fff;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.partners-container .partners-images {
  /* display: grid;
 grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
 grid-gap: 20px;
 align-items: center;
 justify-content: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* max-width: 400px; */
  margin: 1rem auto;
}

.partners-images a {
  max-width: 200px;
  margin: 1rem 1.5rem;
  transform: scale(1);
  background: transparent;
  padding: 1rem;
  transition: all 0.5s linear;
}

.partners-images a img {
  width: 100%;
}

.partners-images a:hover {
  transform: scale(1.02);
  background-color: #00040e62;
}

/* CONTACT US CHANGES */
#contact_form .ant-form-item-has-error:is(input, textarea) {
  /* border-color: rgba(255, 255, 255, 0.2); */
  border-color: rgb(255, 129, 130);

}

/* #contact_form
 .half-field
 .form-field
 > #contact_form
 .half-field
 .form-field
 .ant-form-item-has-error {
 background-color: red !important;
} */

#contact_form
  .half-field
  .form-field
  .ant-form-item-with-help
  .ant-form-item-explain {
  /* height: auto; */
  /* min-height: 24px;
    opacity: 1; */
  margin-bottom: 1rem;
}

/* #contact_form .ant-form-item-has-error ~ .margin-0-after-error {
 margin-top: 0 !important;
} */

/* TRENDING SECTION */

/* TRENDING SECTION */
.trending {
  /* background-image: url("../images/BG.png"); */
  background: rgb(22, 17, 59);
  background: linear-gradient(
    93deg,
    rgba(22, 17, 59, 1) 0%,
    rgba(20, 29, 50, 1) 100%
  );
}

.trending-container {
  max-width: 75rem;
  margin: auto;
  padding: 2rem 0;
  position: relative;
  width: 95%;
}

/* .trending-container .ball-shade {
  right: 25%;
  left: unset;
  transform: scale(2);
} */
.trending-container h3 {
  color: var(--color-white);
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 1.2rem;
}

.trending-container h4 {
  color: var(--color-white);
}

.trending-container p {
  color: var(--color-dull);
  line-height: 1.6;
  font-size: 0.85rem;
}

/*  */
.trending-box-container {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 97%), 1fr));
}

.trending-box-container > div {
  background-color: #173a75;
  background: rgb(38, 199, 183);
  background: linear-gradient(
    144deg,
    rgba(38, 199, 183, 1) -20%,
    rgba(42, 65, 169, 1) 55%
  );
  /* background: linear-gradient(145deg, rgba(38, 199, 183, 1) 0%, rgba(42, 65, 169, 1) 100%); */
  /* background-image: url("../images/trendingbg.png"); */
  /* background-image: url("../images/home/trendingbg.png"); */
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
  color: #fff;
  border-radius: 10px;
  padding: 1rem;
  display: flex !important;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

/* MEDIA QUERY */
@media (max-width: 1100px) {
  header {
    /* height: 12vh; */
  }

  .main {
    height: unset;
    min-height: 85vh;
    margin-top: 12vh;
  }

  .main-top .flex-container {
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 4rem;
  }

  .main-top .flex-container .text-side {
    text-align: center;
    padding-bottom: 5rem;
  }

  .dflex li {
    margin: 0 0.5rem;
  }

  .nfm-marketplace-section .flex-container .text-side-nfm p {
    max-width: 500px;
  }

  .nfm-marketplace-section .flex-container .text-side-nfm .two-buttons {
    max-width: 500px;
  }

  .ecosystem {
    padding: 2rem;
  }

  .ecosystem .first {
    line-height: 1.5;
    margin-bottom: 15px;
  }

  .profile {
    max-width: 40rem;
    margin: auto;
  }

  .explore-ecosystem .content-bottom .content {
    flex-direction: column-reverse;
    text-align: center;
  }

  .explore-ecosystem .text-content h1 {
    margin: 2rem 0;
  }

  .contact-section .contact {
    flex-direction: column-reverse;
  }

  .contact-section .contact .image-logo {
    margin-top: 4rem;
  }

  footer .container {
    flex-direction: column;
  }

  footer .container .logo-text {
    flex: 0.6;
    /* max-width: 350px; */
    border-right: none;
  }
}

@media (max-width: 1049px) {
  header .container {
    padding: 10px 0 !important;
    height: 85px;
  }

  .main-top .flex-container .text-side h1 {
    font-size: 2rem;
    line-height: 2.4rem;
    letter-spacing: 0.25em;
    margin-top: 1.5rem;
  }

  .icons {
    display: block;
  }

  .nav-collapse {
    display: none;
  }

  .nav-collapse.open {
    display: block;
    position: absolute;
    top: var(--top-gap);
    left: 0;
    flex-direction: column;
    background-color: var(--sidebar-bg);
    /* background: red; */
    width: 100%;
    min-height: 100vh;
    margin: 0;
    overflow: auto;
  }

  .nav-collapse.open .dflex {
    flex-direction: column;
    padding-bottom: 2rem;

  }

  .nav-collapse.open .dflex li {
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    padding: 0.15rem 0;
    letter-spacing: 0.1rem;
    font-weight: 500;
    /* border-top: 10px solid transparent;
    border-image: url("../images/Glow.png") 10; */
  }

  
  .menu-item .header__navlink-relative{
    
    opacity: 1;
    visibility: visible;
    width:100%;
    position:relative;
    padding-left:1.1rem;
    background: transparent;
    color: var(--color-dark-grey);
    box-shadow: none;

  }
  .menu-item .header__navlink-relative >a{
    line-height: 1.6;
    font-size: 0.9rem;
  
  }

  .border {
    display: block;
    width: 90%;
    margin: auto;
    margin-top: 0.5rem;
  }

  .border.mt-0 {
    margin-top: -0.6rem;
  }

  .border img {
    width: 100%;
    transform: scaleX(1.1);
  }

  .nav-collapse.open .btns-header {
    order: -1;
  }

  .nav-collapse.open .border {
    order: -1;
  }

  .nav-collapse.open .btns-header li {
    border: none;
    width: unset;
  }

  .nfm-marketplace-section .flex-container {
    flex-direction: column;
  }

  .nfm-marketplace-section .flex-container .image-side-nfm {
    margin: 1.5rem 0;
  }

  .multicoin-section {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }

  .multi-box p {
    margin-bottom: 1.5rem;
  }

  footer .container {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  footer .container .about-links {
    flex-direction: column;
  }

  .contact-footer {
    text-align: center;
  }

  .contact-footer h3 {
    justify-content: center;
    text-align: center;
  }

  .contact-footer p {
    justify-content: center;
    text-align: center;
  }

  footer .container .logo-text p {
    margin-right: 0;
  }

  .balls {
    bottom: 15%;
  }
}

/* MOBILE MEDIA QUERY */
@media (max-width: 535px) {
  /* .main-top {
    background-image: url("../images/Pointer.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    width: 100%;
    height: 100%;
  } */
  header {
    /* height: 12vh; */
  }
  .nav-collapse.open #nav-ul{
    padding: 1rem 1rem 5rem 1rem;
  }
  .logo {
    width: 160px;
  }

  .video-section-page .text {
    width: 100%;
  }

  .video-section-page .text h1 {
    font-size: 2rem;
    text-align: center;
    max-width: 670px;
  }

  .main-top .flex-container .imgage-side {
    max-width: 90%;
    margin: auto;
  }

  .main-top .flex-container .text-side h1 {
    font-size: 1.5rem;
    line-height: 2.2rem;
    letter-spacing: 0.25em;
  }

  .main-top .flex-container .text-side p {
    font-size: 1rem;
    line-height: 1.3rem;
    letter-spacing: 0.15em;
    margin-top: 2.5rem;
  }

  .main-top .flex-container .imgage-side img {
    width: 100%;
  }

  .nfm-marketplace-section .flex-container .text-side-nfm h2 {
    font-size: 1.5rem;
  }

  .nfm-marketplace-section .flex-container .text-side-nfm p {
    font-size: 1.1rem;
  }

  .ecosystem .first {
    font-size: 1.275rem;
    line-height: 1.5;

    margin-bottom: 25px;
  }

  .ecosystem .first-p {
    font-size: 1rem;
    line-height: 22px;
  }

  .profile-one {
    min-width: 282px;
    max-width: 300px;
    margin: auto;
    margin-top: 1.5rem;
  }

  .media-box h2 {
    font-size: 1.47rem;
    line-height: 2.25rem;
  }

  .media-box .images img {
    display: block;
    margin: 1.2rem auto;
  }

  .multi-box .multi-img {
    text-align: center;
    width: 100%;
    height: 280px;
  }

  .explore-ecosystem .content-bottom .content .image-content {
    /* flex: 0.8; */
    max-width: 90%;
  }

  .explore-ecosystem .content-bottom .content .image-content img {
    /* flex: 0.8; */
    width: 100%;
  }

  .explore-ecosystem .text-content p {
    width: 90%;
    font-size: 1rem;
    line-height: 1.6;
    margin: auto;
    color: #ffffff;
  }

  .form-side .half-field {
    grid-gap: 0;
  }

  .contact-footer {
    /* padding: 1rem; */
    margin-left: 0;
    /* text-align: center; */
  }

  .contact-footer .social-icons {
    flex-wrap: wrap;
    justify-content: center;
  }

  .contact-footer .social-icons p {
    margin: 0 0.3rem;
    margin-top: 1rem;
  }

  .splash-screen-main .container {
    padding: 2rem 0;
  }

  .splash-screen-main h1 {
    font-size: 21px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.25em;
  }

  .splash-screen-main h2 {
    font-size: 22px;
    line-height: 36px;
  }
}

@media (max-width: 300px) {
  .ecosystem {
    padding: 2rem 0.5rem;
  }

  .profile-one {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-width: 256px;
  }
}

.mt-5px {
  margin-top: -5px !important;
}

@media (min-width: 982px) {
  #contact_form.forgetfinal input {
    min-width: 360px;
  }
}

/* HOmePage Changes */
:root {
  --color-white: #fff;
  --color-green: #4dfac3;
  --color-sky: #4ce7fd;
  --color-dull: #ccc;

  --body-bg-new: #00153e;
  --family: "Inter", sans-serif;
  --green-color: #00ffc4;
  --pink-color: #e017f3;
  --teal-color: #0088b2;
}

.homepage {
  background: #07103b;
}


.hero .btn-cta {
  display: inline-block;
  background: #0088b2;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  padding-right: unset;
  color: #fff;
  padding: 0.5rem 1rem;
  border: 1px solid #0088b2 !important;
}

.hero .btn-cta:hover {
  color: #fff;
}

.hero .btn-ncta {
  border: 1px solid #0088b2 !important;
  box-sizing: border-box;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  padding-right: unset;
  padding: 0.5rem 1rem;
}

.uppercase {
  text-transform: uppercase;
}

.hero .learn-more-btn {
  font-family: var(--family);

  font-weight: 600;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.008em;
  display: inline-block;
  background: #0088b2;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  color: #fff !important;
  padding: 0.5rem 1rem;
  min-width: 150px;
  margin-top: 2.5rem;
}

.text-center {
  text-align: center;
}

.hero {
  min-height: 100vh;
  /* padding-top: 90px; */
  background-image: url("../images/background.png");
  /* background-color: rgba(35, 13, 68, 0.4); */
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 78rem;
  width: 97%;
  margin: auto;
}

.hero-container .hero-text {
  flex: 0.4;
}

.hero-text h1 {
  font-size: 3.2rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: var(--color-white);
}

.hero-text h2 {
  font-size: 2rem;
  margin-bottom: 1.2rem;
}

.hero-text h2 span.green {
  color: var(--color-green);
}

.hero-text h2 span.sky {
  margin: 0 0.5rem;
  color: var(--color-sky);
}

.hero-text p {
  color: var(--color-dull);
  line-height: 1.6;
  font-size: 1rem;
  margin-bottom: 1.8rem;
}

.btn-ncta.sky {
  color: var(--color-sky);
  font-size: 1.1rem;
}

.btn-ncta.green {
  margin: 0 1rem;
  border: 1px solid var(--color-green);
  color: var(--color-green);
  font-size: 1.1rem;
}

.hero .arrow {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  z-index: 99;
}

@media (max-width: 1082px) {
  .hero {
    min-height: 100vh;
    padding-top: 90px;
    background-image: url("../images/background-mobile.png");
    background-color: rgba(35, 13, 68, 0.7);
    background-color: linear-gradient(
      90deg,
      rgba(35, 13, 68, 0.7) 0%,
      rgba(14, 106, 181, 0.7) 100%
    );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: multiply;
    /* background-position: 30% 30%;
    background-size: 100% 100%; */
  }

  .hero-container .hero-text {
    flex: 1;
    text-align: center;
  }

  .hero-text h1 {
    font-size: 2.1rem;
    margin-bottom: 1.4rem;
  }

  .hero-text h2 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .hero-text p {
    line-height: 1.5;
    margin-bottom: 1.2rem;
  }
}

@media (max-width: 704px) {
  .hero {
    min-height: 100vh;
    padding-top: 90px;
    background-image: url("../images/background-mobile.png");
    background-color: rgba(35, 13, 68, 0.7);
    background-color: linear-gradient(
      90deg,
      rgba(35, 13, 68, 0.7) 0%,
      rgba(14, 106, 181, 0.7) 100%
    );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: multiply;
    /* background-position: 30% 30%;
    background-size: 100% 100%; */
  }

  .hero-container .hero-text {
    flex: 1;
    text-align: center;
  }

  .hero-text h1 {
    font-size: 2.1rem;
    margin-bottom: 1.4rem;
  }

  .hero-text h2 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .hero-text p {
    line-height: 1.5;
    margin-bottom: 1.2rem;
  }
}
