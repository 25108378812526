.pending-pay-page {
  /* margin-top: 120px; */
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pending-pay-page h2 {
  font-size: 1.6rem;
}

.mt-120pxx {
  margin-top: 120px;
}

.pay-details-boxes {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  grid-template-columns: 1fr;
  grid-gap: 30px;
}

.pay-details-boxes > div {
  border-radius: 15px;
 @include whiteBoxStyle;
  padding: 1.5rem;
}

/* .pay-details-boxes > div:last-of-type {
 height: max-content;
} */
.link-color {
  color: #7366f0;
}

.one-section-payment {
  border-bottom: 2px solid #243254;
}

.one-section-payment:last-of-type {
  border-bottom: none;
}

.flex-1 {
  flex: 1;
}

.max-width-paragraph {
  max-width: fit-content;
  width: 100%;
}

.promo-bill-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  margin: 1.2rem 0;
}

.promo-bill-section > div {
  padding: 1rem;
  background: var(--sidebar-bg); 
  color: var(--color-dark-grey);
  border-radius: 15px;
}

.currency-dropdown {
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
}

.one-section-payment:not(:last-of-type) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 854px) {
  .summary-payment .payment-bar {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.all-three-payment {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(550px , 100%), 1fr));
  grid-gap: 20px;
  margin-top: 1rem;
}

.all-three-payment > div {
  padding: 1rem;
  background: var(--sidebar-bg);
  color: var(--color-dark-grey);
  border-radius: 15px;
}

.currency-dropdown {
  color: var(--color-dark-grey);
  max-width: 120px;
  border: var(--light-border);
  border-radius: 2px;
}

span.text-white {
  color: #fff;
}

#pay-now-btn.width-100 {
  width: 100%;
}

/* THANK YOU PAGE */
.thank-you-page {
  text-align: center;
  padding: 1rem 1.5rem;
  min-height: 80vh;
  display: grid;
  place-content: center;
}

.thank-you-page h1 {
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
}

.thank-you-page h2 {
  font-size: 1.9rem;
}

.thank-you-page p {
  margin: 1.2rem 0;
  font-size: 18px;
}

.thank-you-page p a {
  font-size: 20px;
}

.stay-tuned-box {
  max-width: 650px;
  margin: 1.5rem auto;
  background-color: #23324e;
  padding: 1rem;
  border-radius: 15px;
}

.stay-tuned-box .social-icons {
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem auto;
}

.stay-tuned-box .social-icons p {
  margin: 0.5rem;
}

.margin-terms {
  margin: 2.5rem auto;
}

@media (max-width: 992px) {
  .pay-details-boxes > div:last-of-type {
    height: max-content;
    order: -1;
  }

  .pending-pay-page h2 {
    font-size: 21px;
  }

  .thank-you-page h1 {
    font-size: 2rem;
  }

  .thank-you-page h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 820px) {
  .all-three-payment {
    display: grid;
    grid-template-rows: 1fr;
    /* grid-template-columns: repeat(auto-fit, minmax(550px, 1fr)); */
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-top: 1rem;
  }
}

@media (max-width: 375px) {
  .one-section-payment:not(:last-of-type) {
    flex-direction: column;
    align-items: flex-start;
  }

  .one-section-payment:not(:last-of-type) > div {
    width: 100%;
  }

  .one-section-payment:not(:last-of-type) > div:last-of-type {
    text-align: right;
    width: 100%;
  }

  .apply-promo {
    grid-gap: 10px;
  }

  .apply-promo .ant-form-item {
    margin-right: 0 !important;
  }

  .apply-promo #pay-now-btn {
    padding-left: 10px;
    padding-right: 10px;
  }

  .max-width-paragraph {
    max-width: unset;
    /* max-width: fit-content; */
    width: 100%;
  }

  /* Login Bottom Link Fix */
  .dfjb.mobile-issue-fix p {
    padding: 0;
    margin: 0;
  }
}

.flex-end-style {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

/* WALLET REDESIGN  */

.one-section-pay {
  /* display: flex;
  justify-content: space-between;
  align-items: flex-start; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pay-metamask {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.pay-metamask .currency-dropdown {
  max-width: 120px;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  // border: 1px solid rgba(255, 255, 255, 0.2);
}

/* NCASH PAY */
.ncash-pay-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.ncash-pay-box .ncash-pay {
  display: flex;
  justify-content: space-between;
}

.right-side-ncash-details .dfj {
  width: 160px;
  /* max-width: 200px; */
  /* width: 100%; */
}

.right-side-ncash-details .dfj p {
  font-size: 0.85rem;
  display: flex;
  justify-content: space-between;
}

.approve-btn {
  margin: 0.5rem 0;
}

@media (max-width: 992px) {
  .all-three-payment {
    grid-template-rows: unset;
  }

  .pay-metamask {
    flex-direction: column;
  }

  .pay-metamask .right-side {
    width: 100%;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
  }

  .right-side .left-text {
    text-align: left;
  }

  .use-network {
    margin: 10px 0;
  }

  .use-network p {
    text-align: left;
  }

  .one-section-pay {
    /* display: flex;
  justify-content: space-between;
  align-items: flex-start; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ncash-pay-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: space-between; */
  }

  .ncash-pay-box .ncash-pay {
    flex-direction: column;
  }

  .right-side-ncash-details .dfj {
    width: 100%;
    /* max-width: 200px; */
    /* width: 100%; */
    margin-top: 1rem;
  }

  .right-side-ncash-details .dfj p {
    font-size: 0.8rem;
    /* display: flex; */
    /* justify-content: space-between; */
  }

  .approve-btn {
    margin: 1rem 0;
  }
}

/* GET NCASH */
.exchnage-section {
  margin: 1.5rem 0;
  /* background-color: ; */
  background: #1d2941;
  background: rgba(74, 74, 74, 0.2);
  box-shadow: 0px 44.4403px 109.619px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 15px;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 2rem;
}

.exchnage-section h3 {
  padding: 1rem 0;
}

.max-width-55 {
  width: 95%;
  max-width: 55rem;
  margin: auto;
}

.exhanges-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;
  margin: 1rem 0;
}

.huobi-box {
  background: #23324e91;
  background: #080823;
  padding: 15px;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  display: inline-block;
  /* border: 1px solid #41567f91; */
}

.huobi-box img {
  max-width: 200px;
  height: 70px;
}

.huobi-box p {
  margin: 1rem auto;
}

@media (max-width: 310px) {
  .pay-details-boxes > div {
    padding: 1.5rem 0.5rem;
  }

  .pay-details-boxes {
    grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  }

  .promo-bill-section {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }

  .pending-pay-page {
    padding: 1rem 0.5rem;
  }

  .exhanges-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
    grid-gap: 30px;
    margin: 1rem 0;
  }
}
