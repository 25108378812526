.box-det {
  margin: 0 0 1rem;
  display: block;
}
@media (max-width: 805px) {
  .dashboard-container {
    display: grid;
    grid-template-columns: 1fr;
  }

  .bar-btn {
    display: block;
  }

  .sidebar {
    display: none;
  }

  .large-view-area {
    padding: 20px 5px;
  }

  nav {
    /* position: fixed; */
    z-index: 999;
    /* width: 100%; */
  }

  .side-link {
    display: none;
    right: -250px;
  }

  .side-link.expand {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 200px;
    background: #fff;
    padding-top: 70px;
    z-index: -1;
    -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .side-link.expand a {
    margin: 1rem;
    display: block;
  }

  .side-link.expand div {
    text-align: left;
    justify-content: flex-start;
  }

  .side-link.expand div p {
    display: none;
    margin-left: 1rem;
  }

  .hide-on-big {
    display: block;
  }

  .first-link {
    margin-top: 0;
  }

  @-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(-100px);
      transform: translateX(-100px);
    }
  }

  @keyframes slide-left {
    0% {
      right: -250px;
    }

    100% {
      right: 0;
      /* -webkit-transform: translateX(-100px);
        transform: translateX(-100px); */
    }
  }

  @keyframes slide-right {
    0% {
      right: 0;
    }

    100% {
      right: -250px;
    }
  }
}

@media (max-width: 650px) {
  .submit-btn {
    padding: 12px 15px;
  }

  .confirm-details-page {
    min-height: unset;
    height: 92vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    position: relative;
  }

  .confirm-details-sidebar {
    order: 2;
  }

  .earth {
    height: 100%;
  }

  .confirm-details-page {
    position: relative;
    margin-top: 90px;
    /* display: flex;
        flex-direction: column-reverse; */
  }

  .confirm-details-sidebar {
    width: 100%;
    padding: 10px;
    box-shadow: none;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .confirm-details-page h4 {
    max-width: 100%;
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  .confirm-details-sidebar .side-icon {
    /* position: absolute;
        right: 5%; */
    display: block;
    font-size: 2rem;
    text-align: left;
    cursor: pointer;
    margin-bottom: 0.5rem;
  }

  .address {
    margin-top: 1.22rem;
    max-width: 100%;
    font-size: 0.85rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
  }

  .next-prev {
    display: flex;
    align-items: center;
  }

  .previous {
    font-size: 2rem;
    display: flex;
    align-items: center;
  }

  .previous i {
    background: hsla(244, 28%, 46%, 1);

    background: linear-gradient(
      90deg,
      hsla(244, 28%, 46%, 1) 0%,
      hsla(294, 46%, 53%, 1) 100%
    );

    background: -moz-linear-gradient(
      90deg,
      hsla(244, 28%, 46%, 1) 0%,
      hsla(294, 46%, 53%, 1) 100%
    );

    background: -webkit-linear-gradient(
      90deg,
      hsla(244, 28%, 46%, 1) 0%,
      hsla(294, 46%, 53%, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#585495", endColorstr="#B24EBE", GradientType=1); */
  }

  .pac-container {
    width: 290px !important;
    top: 160px !important;
    left: 40px !important;
  }
}

/* // */
.earth {
  position: relative;
}
