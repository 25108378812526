//that does not ouput statys here
// Light theme
:root{
  --white: #fff;
  --body-bg: #f6FBFF ;
    --color-primary:#3FB8E1;
    --color-dark-grey: #3C3C3C;
    --border-color: #F6FBFF;
    --font: 'Montserrat' , sans-serif;
    --color-white:#fff;
    --light-border: 1px solid rgba(60, 60, 60 ,.1);
    --max-container: 80rem;
    --top-gap: 130px;
    --gradient-bg: radial-gradient(
        100.48% 341.36% at -0.48% 2.54%,
        #de2ef0 0%,
        #440bc9 100%
      ),
  #e4e4e4; 

  --sidebar-bg : #DFF4FF;
  --form-active: #3FB8E1;
  --form-completed: #3FB8E1;
  --light-grey: #c7c7c7;
  --primary-border: 1px solid var(--color-primary);
  --light-primary:rgba(63, 184, 225, 0.2);
  --header-shadow: 0px 6px 48px rgb(63 184 225 / 18%);
}
