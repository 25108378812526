
:root {
  --family: "Inter", sans-serif;
  --green-color: #00ffc4;
  --pink-color: #e017f3;
}
/* THIS IS THE MENU BORDER BOTTOM MOBILE VIEW
background: radial-gradient(50% 50% at 50% 50%, #CDDBFF 0%, rgba(62, 167, 244, 0.901042) 36.46%, rgba(0, 102, 255, 0) 100%);
mix-blend-mode: color-dodge;
*/

.btn-cta {
  display: inline-block;
  background: #0088b2;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  padding-right: unset;
  padding: 0.35rem 1rem;
  border: 1px solid #0088b2 !important;
}

.btn-ncta {
  border: 1px solid #0088b2 !important;
  box-sizing: border-box;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  padding-right: unset;
  padding: 0.35rem 1rem;
}
.uppercase {
  text-transform: uppercase;
}
.learn-more-btn {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.008em;
  display: inline-block;
  background: #0088b2;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  padding: 0.5rem 1rem;
  min-width: 150px;
  margin-top: 2.5rem;
}
.text-center {
  text-align: center;
}

.mx-width {
  max-width: 95%;
  margin: auto;
}

#backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.menu-item {
  /* color: inherit !important;
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
  font-weight: normal; */
}

.menu-item > .border,
.border.mt-0 {
  border: none !important;
}

.dflex li {
  margin: 0 1rem;
}

.dflex {
  display: flex;
  align-items: center;
}
.icons {
  display: none;
  cursor: pointer;
}
.icons i {
  font-size: 1.8rem;
  color: #0088b2;
}
.btns-header {
  display: flex;
  align-items: center;
}
.logo {
  width: 180px;
  /* height: 1  00px; */
}
.logo img {
  width: 100%;
}
.border {
  display: none;
}
/* ------------------------------------------
  RESPONSIVE NAV STYLES
--------------------------------------------- */
a:hover {
  filter: brightness(90%);
}
/* 
    MAIN PAGW
 */

.main {
  height: 90vh;
  margin-top: 80px;
  background-image: url("../images/Earth2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--body-bg);
  background-blend-mode: multiply;
}

.main-top {
  /* background-image: url("../images/Pointer.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  width: 100%;
  height: 100%;
}
.main-top .flex-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 85rem;
  margin: auto;
  padding: 0 1.5rem;
}
.main-top .flex-container .text-side {
  flex: 1;
  /* margin-right: 1.5rem; */
  max-width: 700px;
}
.main-top .flex-container .text-side h1 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 3rem;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #fff;
}
.main-top .flex-container .text-side h1 span {
  color: var(--green-color);
}
.main-top .flex-container .text-side p {
  font-family: var(--family);
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 1.8rem;
  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #ffffff;
  margin-top: 2.5rem;
}
.main-top .flex-container .imgage-side {
  flex: 0.8;
}

/* NFM MARKETPLACE */
.nfm-marketplace-section {
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: radial-gradient(
    100% 100% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(42px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 5px;
  z-index: 12;
  overflow: hidden;
}

.nfm-marketplace-section .flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 85rem;
  margin: auto;
  padding: 2rem 1rem;
}

.nfm-marketplace-section .flex-container .text-side-nfm {
  flex: 1;
}
.nfm-marketplace-section .flex-container .text-side-nfm h2 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.25rem;
  /* identical to box height */

  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #ffffff;
}
.nfm-marketplace-section .flex-container .text-side-nfm p {
  font-family: var(--family);
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.4;

  color: #ffffff;
  margin-top: 1rem;
  max-width: 650px;
}
.nfm-marketplace-section .flex-container .image-side-nfm {
  flex: 0.7;
}
.nfm-marketplace-section .flex-container .text-side-nfm .two-buttons {
  max-width: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2rem 0;
}

.btn-ncta.pink {
  border-color: var(--pink-color) !important;
  color: #fff;
}
.btn-cta.pink {
  background: var(--pink-color);
  border: 1px solid var(--pink-color);
  color: #fff;
  margin-left: 1rem;
}
.nfm-marketplace-section .flex-container .image-side-nfm .image-black-layer {
  /* background: url(NFT_Image.png); */
  box-shadow: 0px 4px 4px 6px rgba(0, 0, 0, 0.25);
  transform: rotate(5.34deg);
  /* margin-top: -2rem; */
  z-index: 9;
}
.nfm-marketplace-section
  .flex-container
  .image-side-nfm
  .image-black-layer
  img {
  width: 100%;
  height: 100%;
  transform: rotate(355deg);
}

/* ECOSYSTEM */

.ecosystem {
  /* background-image: url("../images/BG.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /* background-color: var(--body-bg); */
  /* background-color: #196ed2; */
  background-blend-mode: multiply;
  padding: 2rem 0;
  position: relative;
}
.ecosystem .first {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 1.875;
  line-height: 2.25;
  /* identical to box height */

  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #ffffff;
  margin-bottom: 8px;
}
.ecosystem .conatiner {
  max-width: 85em;
  margin: auto;
}
.ecosystem .first-p {
  font-family: var(--family);
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 27px;
  text-align: center;
  max-width: 85%;
  margin: auto;
  color: #ffffff;
}
.first span {
  color: var(--green-color);
}

.multicoin-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1.5rem;
  margin-top: 4rem;
}
.multicoin-section .multi-box {
  color: #fff;
  text-align: center;
}

.multi-box h2 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #ffffff;
  margin: 0.5rem 0;
}
.multi-box p {
  /* width: 90%; */
  max-width: 400px;
  margin: auto;
  font-family: var(--family);
  font-style: normal;
  font-weight: normal;
  font-size: 0.89rem;
  line-height: 17px;
  text-align: center;

  color: #ffffff;
}
.border-radial {
  width: 100%;
  height: 50px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #cddbff 0%,
    rgba(62, 167, 244, 0.901042) 36.46%,
    rgba(0, 102, 255, 0) 100%
  );
  mix-blend-mode: color-dodge;
  margin: 4rem auto;
}
.executive {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 36px;
  /* identical to box height */

  letter-spacing: 0.25em;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: #ffffff;
}
.profile {
  max-width: 70rem;
  margin: auto;
}
.profile-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1.5rem;
  text-align: center;
}
.profile-one {
  background: #00040e;
  border-radius: 5px;
  padding: 1.5rem 1rem;
}

.profile-one h3 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.008em;
  text-align: center;
  color: #5a6688;
  margin: 0.2rem 0;
}

.profile-one p {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 15px;

  text-align: center;
  letter-spacing: 0.008em;
  margin: 0.2rem 0;
  color: #394565;
}
.balls {
  position: absolute;
  right: 0;
  bottom: -15%;
  z-index: -1;
}

/* MEDIA MENTIONS */
.media-mentions {
  margin: 3rem 0;
  position: relative;
}
.media-box {
  width: 85%;
  max-width: 85rem;
  margin: auto;
  background: #060607;
  border: 1px solid #00a4cb;
  box-sizing: border-box;
  border-radius: 10.7776px;
  padding: 2rem 0;
}
.media-box h2 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.87rem;
  line-height: 2.25rem;
  /* identical to box height */

  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #00a4cb;
}
.media-box .images {
  margin: 2.5rem 0;
  /* display: flex; */
  /* flex-direction: column; */
}
.media-box .images img {
  display: block;
  margin: 1rem auto;
}
.media-mentions .border-radial {
  max-width: 550px;
  z-index: 2;
  position: absolute;
  left: 50%;
  bottom: -20%;
  transform: translateX(-50%);
}

/* EXPLORE ECOSYSTEM */
.explore-ecosystem {
  /* min-height: 90vh; */
}
.explore-ecosystem .content-bottom {
  /* background-image: url("../images/BottomArrow.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  width: 100%;
  padding: 5rem 0;
  padding-bottom: 3rem;
  margin-top: -4rem;
  height: 100%;
}
.explore-ecosystem .content-bottom .content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 85rem;
  margin: auto;
}
.explore-ecosystem .content-bottom .content .text-content {
  flex: 1;
  max-width: 650px;
}
.explore-ecosystem .content-bottom .content .image-content {
  /* flex: 0.8; */
}
.explore-ecosystem .text-content h1 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.25rem;
  /* identical to box height */

  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #ffffff;
  margin: 1rem 0;
}
.explore-ecosystem .text-content p {
  font-family: var(--family);
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 27px;

  color: #ffffff;
}
/* SPLASH MAIN SCREEN */
.splash-screen-main {
  /* padding: 2rem 0; */
  height: 100vh;
  background-image: url("../images/Earth2.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--body-bg);
  background-blend-mode: multiply;
}
.splash-screen-main .container {
  min-height: 100vh;
  margin-top: 30px;
  /* margin-top: 12vh; */
  padding: 4rem 0;
  text-align: center;
  display: grid;
  place-content: center;
}
.splash-screen-main h1 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #97ffe7;
  margin-bottom: 2rem;
}
.splash-screen-main h2 {
  font-family: var(--family);
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.25em;
  color: #fff;
}
.splash-screen-main h2.green {
  margin-top: 2rem;
  color: #97ffe7;
}

#username .menu-item {
  max-width: 120px;
  margin-right: 0 !important;
}

#username > span:first-of-type {
  display: block;
  /* width: 77px; */
  /* height: max-content; */
  /* text-align: left !important; */
  font-size: 0.8rem;
  word-break: break-all !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* *************************** */
/* *************************** */
/* *************************** */
/* ***** Mobile Styles ******* */
/* *************************** */
/* *************************** */
/* *************************** */
@media (max-width: 535px) {
  /* .main-top {
    background-image: url("../images/Pointer.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    width: 100%;
    height: 100%;
  } */
  header {
    /* height: 12vh; */
  }
  .logo {
    width: 160px;
  }
  .dflex {
    padding: 0;
  }
  .main-top .flex-container .imgage-side {
    max-width: 90%;
    margin: auto;
  }
  .main-top .flex-container .text-side h1 {
    font-size: 1.5rem;
    line-height: 2.2rem;
    letter-spacing: 0.25em;
  }
  .main-top .flex-container .text-side p {
    font-size: 1rem;
    line-height: 1.3rem;
    letter-spacing: 0.15em;
    margin-top: 2.5rem;
  }
  .main-top .flex-container .imgage-side img {
    width: 100%;
  }
  .nfm-marketplace-section .flex-container .text-side-nfm h2 {
    font-size: 1.5rem;
  }
  .nfm-marketplace-section .flex-container .text-side-nfm p {
    font-size: 1.1rem;
  }
  .ecosystem .first {
    font-size: 1.275rem;
    line-height: 1.5;

    margin-bottom: 25px;
  }
  .ecosystem .first-p {
    font-size: 1rem;
    line-height: 22px;
  }
  .profile-one {
    min-width: 282px;
    max-width: 300px;
    margin: auto;
    margin-top: 1.5rem;
  }
  .media-box h2 {
    font-size: 1.47rem;
    line-height: 2.25rem;
  }
  .media-box .images img {
    display: block;
    margin: 1.2rem auto;
  }
  .explore-ecosystem .content-bottom .content .image-content {
    /* flex: 0.8; */
    max-width: 90%;
  }
  .explore-ecosystem .content-bottom .content .image-content img {
    /* flex: 0.8; */
    width: 100%;
  }
  .explore-ecosystem .text-content p {
    width: 90%;
    font-size: 1rem;
    line-height: 1.6;
    margin: auto;
    color: #ffffff;
  }
  .form-side .half-field {
    grid-gap: 0;
  }
  .contact-footer {
    /* padding: 1rem; */
    margin-left: 2rem;
    /* text-align: center; */
  }
  .contact-footer .social-icons p {
    margin: 0 0.3rem;
    margin-top: 1rem;
  }

  .splash-screen-main .container {
    padding: 2rem 0;
  }
  .splash-screen-main h1 {
    font-size: 21px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.25em;
  }
  .splash-screen-main h2 {
    font-size: 22px;
    line-height: 36px;
  }
}

.nav-collapse {
  align-items: center;
  margin-left: auto;
}

/* *************************** */
/* *************************** */
/* *************************** */
/* ***** Tablet Styles ******* */
/* *************************** */
/* *************************** */
/* *************************** */

@media (max-width: 998px) {
  header {
    /* height: 12vh; */
  }
  .main {
    height: unset;
    min-height: 85vh;
    margin-top: 10vh;
  }

  .main-top .flex-container {
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
  .main-top .flex-container .text-side {
    text-align: center;
    padding-bottom: 5rem;
  }
  .dflex li {
    margin: 0 0.5rem;
  }
  .nfm-marketplace-section .flex-container .text-side-nfm p {
    max-width: 500px;
  }
  .nfm-marketplace-section .flex-container .text-side-nfm .two-buttons {
    max-width: 500px;
  }
  .ecosystem {
    padding: 2rem;
  }
  .ecosystem .first {
    line-height: 1.5;
    margin-bottom: 15px;
  }
  .profile {
    max-width: 40rem;
    margin: auto;
  }
  .explore-ecosystem .content-bottom .content {
    flex-direction: column-reverse;
    text-align: center;
  }
  .explore-ecosystem .text-content h1 {
    margin: 2rem 0;
  }
  .contact-section .contact {
    flex-direction: column-reverse;
  }
  .contact-section .contact .image-logo {
    margin-top: 4rem;
  }
  footer .container {
    flex-direction: column;
  }
  footer .container .logo-text {
    flex: 0.6;
    /* max-width: 350px; */
    border-right: none;
  }
}

@media (max-width: 773px) {
  header .container {
    padding: 10px;
  }
  .jumbotron {
    padding-top: 90px;
  }
  /* #username,
  .item-dropdown {
    display: none;
  } */

  /* ul {
    transform: rotate(180deg);
  }
  ul > li,
  .btns-header,
  #username {
    transform: rotate(-180deg);
  } */

  .main-top .flex-container .text-side h1 {
    font-size: 2rem;
    line-height: 2.4rem;
    letter-spacing: 0.25em;
    margin-top: 1.5rem;
  }
  .icons {
    display: block;
    cursor: pointer;
  }
  .nav-collapse {
    display: none;
  }
  .nav-collapse.open {
    display: block;
    position: absolute;
    top: 9vh;
    left: 0;
    flex-direction: column;
    background-color: #0f0f0f;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    overflow:auto;
  }
  .nav-collapse.open .dflex {
    flex-direction: column-reverse;
  }
  .nav-collapse.open .dflex li {
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    padding: 0.95rem 0;
    letter-spacing: 0.2rem;
  }

  .border {
    display: block;
    width: 90%;
    margin: auto;
    margin-top: 0.5rem;
  }
  .border.mt-0 {
    margin-top: -0.4rem;
  }
  .border img {
    width: 100%;
    transform: scaleX(1.1);
  }
  .nav-collapse.open .btns-header li {
    border: none;
    width: unset;
  }
  .nfm-marketplace-section .flex-container {
    flex-direction: column;
  }
  .nfm-marketplace-section .flex-container .image-side-nfm {
    margin: 1.5rem 0;
  }
  .multicoin-section {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
  .multi-box p {
    margin-bottom: 1.5rem;
  }
  footer .container {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  footer .container .about-links {
    flex-direction: column;
  }
  .contact-footer {
    text-align: center;
  }
  .contact-footer h3 {
    justify-content: center;
    text-align: center;
  }

  .contact-footer p {
    justify-content: center;
    text-align: center;
  }

  .item-dropdown {
    position: absolute;
    top: 46px;
    right: 0;
    min-width: 200px;
    padding: 0.5rem;
  }
  .menu-item .text-right {
    margin-right: 10px;
    cursor: pointer;
  }
}
@media (max-width: 478px) {
  #username .menu-item {
    max-width: 120px;
    margin-right: 0 !important;
  }

  #username > span:first-of-type {
    display: block;
    /* width: 77px; */
    /* height: max-content; */
    /* text-align: left !important; */
    font-size: 0.8rem;
    word-break: break-all !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.menu-item .text-right {
  margin-right: 5px;
  cursor: pointer;
}
.menu-item span:first-of-type {
  display: block;
  /* width: 77px; */
  /* height: max-content; */
  /* text-align: left !important; */
  /* font-size: 0.8rem; */
  word-break: break-all !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.menu-item span {
  cursor: pointer;
}

.mobile-view-nav {
  display: flex;
  align-items: center;
}
.btns-header .menu-item {
  margin: 0 0.7rem;
}
#username {
  margin: 0 1rem;
}
.hide-it {
  display: none !important;
}
@media (max-width: 500px) {
  .btns-header .menu-item {
    margin: 0 0.2rem;
  }
  .btns-header .menu-item a{
    display: inline-block;
    text-align: center;
    font-size: .82rem;
  }
  .btns-header .menu-item .text-right {
    font-size: 13px;
  }
  header .container {
    padding-left: 0;
    padding-right: 0;
  }
  .logo {
    width: 140px;
  }
  #username {
    margin: 0 0.3rem;
  }
  .menu-item span:first-of-type {
    font-size: 0.8rem;
  }
  .menu-item span:last-of-type {
    margin-right: 10px;
  }
}
@media (min-width: 782px) {
  .dflex {
    margin-bottom: 0;
  }
  /* footer .container .logo-text img {
    margin-left: 3.5rem;
  } */
}

/* New Header */
.menu-item{
  position: relative;
}

.menu-item p{
  margin-bottom: 0;
  cursor: pointer;
}
.menu-item p i{
  margin-left: 0.3rem;
}
.menu-item .header__navlink-relative{
  position: absolute;
  top: 100%;
  z-index:99;
  background: #fff;
  color: #000;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  /* width: 150px; */
  opacity: 0;
  visibility: hidden;
  min-width: 225px;
  transition: all .2s linear;
  /* display:none; */
}
.menu-item  .header__navlink-relative.active{
  opacity: 1;
  visibility: visible;
  /* display:block; */
  /* min-width: 150px; */
}

.menu-item .header__navlink-relative >a{
  display: block;
  margin: 0.35rem 0;
  cursor: pointer;
  font-size: 0.9rem;

}
.menu-item .header__navlink-relative >a:hover{
 text-decoration: underline;
 color: inherit !important;

}
