:root {
  /* --box-bg: #1b2235;
  --white: #fff;
  --btn-orange: #7366f0;
  --border: #eee;
  --section-bg: #161d30;
  --box-border: #2d3445; */
  /* --body-font: "DM Sans", Helvetica, Arial, sans-serif; */
  /* --body-font: "Inter", sans-serif;
  --shadow-box: 0px 0px 4px 5px #161c2b; */
}

.collection-boxes {
  padding: 2rem 0;
  margin-top: 5vh;
}

#subheader {
  text-align: center;
  overflow: hidden;
  padding: 50px 0 33px 0 !important;
  /* background:url('./images/subheader.jpg') ; */
}

/* .sub-image {
  background:url('./images/subheader.jpg') top !important;
} */

#subheader h1 {
  font-size: 40px;
  margin-top: 10px;
}

.row-justify-custom {
  display: flex;
  justify-content: space-between;
}

.row-justify-custom p {
  margin-bottom: 0.1rem;
  font-size: 0.85rem;
  color: #ccc;
}

.nft__item_wrap {
  margin-top: 0;
  margin-bottom: 10px;
}

.nft__item_wrap a {
  width: 100%;
}

.text-right {
  text-align: right;
}

#marketplace > .nft__item {
  padding: 15px;
  /* background-color: #161b2a !important; */
  /* background-color: #161b2a66 !important; */
  color: white;
  /* border: 1px solid #2d3445; */
  /* border: 1px solid #2d3445; */
  /* -webkit-box-shadow: 0px 0px 20px 19px #1b2235; */
  /* -moz-box-shadow: 0px 0px 20px 19px #1b2235; */
  /* box-shadow: 0px 0px 4px 5px #161c2b4b; */

  background: #2758A2 !important;
  border: 1.3646px solid #2D3445;
  box-shadow: 0px 0px 5.45842px 6.82302px rgba(22, 28, 43, 0.294);
}

#marketplace-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 20vh;
  margin: 20px auto 0;
}

#marketplace-summary > h2 {
  margin: 0;
}

#marketplace-summary .gradient-box {
  border: none;
  color: white;
  padding: 11px 18px;
  min-width: none;
  font-size: 16px;
}

/* section {
  background-color: var(--section-bg);
} */

#section-nfts {
  padding: 25px 0 90px 0;
}

#product-detail-page-custom {
  padding: 70px 0 90px 0;
}

/* @media (max-width: 992px) {
  .sm-pt10 {
    padding-top: 0px;
  }
  header.header-mobile {
    position: absolute;
    background: #403f83;
    top: 0;
    height: 90px;
  }
  #subheader {
    text-align: center;
    overflow: hidden;
    padding: 130px 0 45px 0 !important;
  }
  .sorting .right-side div {
    display: flex;
  }
  .sorting .right-side div select {
    margin: 0;
    padding: 0.5rem 0.9rem;
    padding-left: 0.3rem;
  }
  .sorting .right-side div select:last-of-type {
    margin-left: 0.5rem;
  }
} */

/* Changes Fixed 26-Jan */
.breadcumb {
  padding: 0;
}

.breadcumb .mainbreadcumb {
  padding: 45px 0 10px 0;
  margin: 0;
}

.breadcumb .mainbreadcumb h1 {
  font-size: 2.5rem;
}

.nft__item_info p {
  color: #fff;
  font-weight: 600;
}

.nft__item_info {
  cursor: pointer;
  line-height: 28px;
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .sm-pt10 {
    padding-top: 0px;
  }

  header.header-mobile {
    position: absolute;
    background: #403f83;
    top: 0;
    height: 90px;
  }

  .top_banner {
    margin: 187px 0 0px 207px;
  }

  /* Chnages Fixed */
  .breadcumb .mainbreadcumb {
    /*padding: 0 0 30px 0;*/
    margin: 0;
  }

  #subheader {
    text-align: center;
    overflow: hidden;
    padding: 130px 0 45px 0 !important;
  }

  .sorting .right-side div {
    display: flex;
  }

  .sorting .right-side div select {
    margin: 0;
  }
}

.breadcumb .mainbreadcumb h1 {
  font-size: 2.5rem;
}

.nft__item_info p {
  color: #fff;
  font-weight: 600;
}

.nft__item_info {
  cursor: pointer;
  line-height: 28px;
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .sm-pt10 {
    padding-top: 0px;
  }

  header.header-mobile {
    position: absolute;
    background: #403f83;
    top: 0;
    height: 90px;
  }

  /* Chnages Fixed */
  .breadcumb .mainbreadcumb {
    /*padding: 0 0 30px 0;*/
    margin: 0;
  }

  #subheader {
    text-align: center;
    overflow: hidden;
    padding: 130px 0 45px 0 !important;
  }

  .sorting .right-side div {
    display: flex;
  }

  .sorting .right-side div select {
    margin: 0;
    padding: 0.5rem 0.9rem;
    padding-left: 0.3rem;
  }

  .sorting .right-side div select:last-of-type {
    margin-left: 0.5rem;
  }

  section h1 {
    color: #fff;
    margin: auto;
    max-width: 650px;
    text-align: center;
    padding-top: 0;
  }
}

@media (max-width: 600px) {
  section h1 {
    line-height: 1.4;
    font-size: 2rem;
    padding-top: 1rem;
  }

  section h1 {
    color: #fff;
    margin: auto;
    max-width: 650px;
    text-align: center;
    padding-top: 0;
  }
}

@media (max-width: 600px) {
  section h1 {
    line-height: 1.4;
    font-size: 2rem;
    padding-top: 1rem;
  }
}

/* Cognito */
.cognito-form-contact-us input,
.cognito-form-contact-us textarea {
  background: transparent !important;
  border: var(--light-border);
}

.cog-button--submit {
  /* background: #FF6C0D !important; */
  border: none !important;
  outline: none !important;
  padding: 0.4rem 0.5rem !important;
  border-radius: 5px !important;
}

.cog-page__navigation {
  display: flex;
  justify-content: center !important;
  padding-top: var(--gutter);
  padding-top: 0.7rem !important;
}

/* newsletter */
.cog-heading {
  display: none !important;
}

.newsletter-form .cog-body {
  margin-top: 0.5rem !important;
}

.newsletter-form .cog-header__description {
  color: rgba(255, 255, 255, 0.55) !important;
}

.newsletter-form .el-input__inner {
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.newsletter-form .cog-page__navigation {
  padding-top: 0.5rem;
  justify-content: flex-start;
}

textarea {
  color: var(--color-dark-grey);
  font-weight: normal;
  border:var(--light-border);
  padding: 0.3rem 0.7rem !important;
  border-radius: 5px !important;
}

input::placeholder,
textarea::placeholder {
  color:  var(--color-dark-grey);
}

/* PRODUCT DETAIL PAGE */

.product-detail {
  display: flex;
  justify-content: space-between;
  max-width: 70vw;
  margin: 2rem auto;
}

.large-image {
  flex: 0.7;
  width: 100%;
  height: auto;
  margin-right: 1.5rem;
}

.image-part {
  border: 1px solid var(--box-border);
  background-color: var(--box-bg);
  border-radius: 15px;
  /* box-shadow: 0px 0px 6px 5px #f5f6f8e1; */
  box-shadow: var(--shadow-box);
}

.love-react {
  text-align: right;
  padding-right: 0.5rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.pro-image {
  width: 100%;
  max-height: 450px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.pro-image img {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.additional-details {
  padding: 15px;
  border-radius: 12px;
  margin-top: 1rem;
  /* box-shadow: 0px 0px 6px 5px #f5f6f8e1; */
  box-shadow: var(--shadow-box);

  border: 1px solid var(--box-border);
  background-color: var(--box-bg);
}


.pay-now-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.price-product {
  flex: 1;
  /* background: red; */
  font-size: 0.7rem;
  margin: 0;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.pay-now-section h6 {
  color: #ccc;
}

.price-product span {
  /* display: block; */
  color: #fff;
  font-size: 1.3rem;
  margin-top: 0.3rem;
}

.details-box {
  /* background: yellow; */
  display: block;
  width: 100%;
  padding: 15px;
  flex: 1;
  background-color: var(--box-bg);
  border-radius: 15px;
  border: 1px solid var(--box-border);
  /* box-shadow: 0px 0px 6px 5px #f5f6f8e1; */
  box-shadow: var(--shadow-box);

  /* background: yellow; */
}

.details-box h1 {
  font-size: 2rem;
}

.details-box p {
  /* color: rgb(170, 170, 170); */
  margin-top: 1.3rem;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

p.description {
  font-size: 1rem;
  max-width: fit-content;
  line-height: 1.5;
}

.details-box h2 {
  margin-top: 0;
  color: var(--head);
}

.paynow-btn {
  flex: 1;
  text-align: center;
}

#pay-now-btn {
  color: #fff;
  background-color: var(--btn-orange);
  padding: 8px 25px;

  border-radius: 5px;
  margin-left: 0;
  margin-right: 0;
  /* margin-top: 1.2rem; */
}

#pay-now-btn:hover {
  filter: brightness(90%);
}

#customers {
  /* font-family: Arial, Helvetica, sans-serif; */
  border-collapse: collapse;
  width: 100%;
  /* margin-top: 1.5rem; */
}

#customers td,
#customers th {
  border: 1px solid var(--box-border);
  padding: 4px 8px;
}

#customers tr:nth-child(even) {
  background-color: var(--box-bg);
}

#customers tr:hover {
  background-color: var(var(--box-border));
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--box-border);
  color: #fff;
}

/* @media (max-width: 982px) {
  .pro-image {
    height: 500px;
  }
  .pro-image img {
    height: 100%;
    border-radius: 50%;
}

.profile-box p {
    color: #ccc;
    font-size: 1rem;
}
.btns-profiles .btn-submit{
    display: block;
    margin: 0.9rem 0;
    border-radius: 50px;
    padding: 0.7rem 0;
    width: 100%;
}

/* .large-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    } */
/* } */

/* Contact US */

#contact-form-main-page {
  padding: 90px 0;
}

#contact-form-main-page h2 {
  /* font-family: ; */
  font-family: var(--body-font);
  font-weight: bold;
  margin-bottom: 1.2rem;
  color: #fff;
}

#contact-form-main-page .contact-link {
  text-decoration: none;
  color: var(--btn-orange);
  /* font-family: 'Metropolis'; */

  font-weight: bold;
}

.react-out-us-box {
  background-color: #292f45;
}

.padding40 {
  padding: 60px 40px;
}

.error-msg {
  color: rgb(231, 34, 34);
  margin: 0;
  font-size: 14px;
  line-height: 0;
  display: none;
}


.cognito-form-contact-us textarea {
  margin: 20px 0;
}



/* ***********

    MY PROFILE

*****************/

@media (max-width: 982px) {
  .pro-image {
    height: 500px;
  }

  .pro-image img {
    height: 100%;
    width: 100%;
  }

  #product-detail-page-custom {
    padding-left: 0.9rem;
    padding-right: 0.9rem;
  }

  .product-detail {
    max-width: 100%;
    flex-direction: column;
  }

  /* Contact us mobile */
  section#bottom {
    padding-top: 90px;
    padding-bottom: 0;
  }

  .header-col-mid #mainmenu {
    padding: 0 12px !important;
    left: 0;
  }

  header.cog-header.cog-wrapper.cog-header--left.clone.smaller {
    height: 42px !important;
  }

  .row.reverse-order {
    flex-direction: column-reverse;
  }

  .margin-on-mb {
    margin-bottom: 1.5rem;
  }

  .column-reverse-in-mobile {
    flex-direction: column-reverse;
  }

  .contact-margin-on-mobile {
    margin-top: 2rem;
  }

  .padding40 {
    padding: 40px 12px;
  }
}

@media (max-width: 550px) {
  .pro-image {
    height: 400px;
  }
}

.stake-sell-option {
  display: flex;
}

.stake-sell-option .stake-sell-btns {
  margin-left: 1.5rem;
}

.artibute-section {
  max-width: 90em;
  margin: auto;
}

.attributes-key {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 25px;
  padding-top: 0;
}

.attributes-key > div {
  background-color: #242c42 !important;
  border-radius: 10px;
  padding: 1rem;
}

.attributes-key > div > h4 {
  margin-bottom: 1rem;
}

.attributes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 25px;
}

.attributes-box {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}

.collection-table-stake {
  background-color: #242c42;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.collection-table-stake th,
.collection-table-stake td {
  color: #fff;
}

.collection-table-stake td a {
  color: #7366f0;
}

.collection-table-stake td {
  font-weight: 500;
}

.attributes-box h6 {
  color: #6462b3;
}

.attributes-key p:last-of-type {
  margin-bottom: 0 !important;
}

.attributes-key p:first-of-type {
  margin-bottom: 5px !important;
}

@media (max-width: 1138px) {
  .stake-sell-option {
    flex-direction: column;
  }

  .stake-sell-option .stake-sell-btns {
    margin-left: 0rem;
  }

  .artibute-section {
    padding: 1rem;
  }
}


@media (min-width: 752px) {
  .collection-table-stake .left-field {
    text-align: left;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

.right-aligned-connect {
  display: flex;
  max-width: 200px;
  justify-content: flex-end;
  margin: auto;
  /* background: red; */
  margin-left: auto !important;
}

.right-aligned-connect .gradient-box {
  min-width: 200px;
}

.disconnect-btn span {
  display: inline-block;
  /* background: linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  ) !important; */
  background-color: var(--white);
  color: var(--color-dark-grey);
  font-weight: 600;
  font-size: 1.1rem;
  border-radius: 50%;
  padding: 8px 18px;
  height: 30px;
  width: 30px;
  display: grid;
  place-content: center;
  margin-left: 1rem;
}

.pos-relative {
  position: relative;
  /* margin-right: 60px; */
}

/* .pos-relative:only-child {
 margin-right: 0 !important;
} */

.pos-relative > a + a {
  margin-right: 60px;
}

.pos-relative > a + a ~ .pos-relative > a:first-of-type {
  margin-right: 60px;
}

/* .pos-relative:has(a:nth-child(2)) {
 margin-right: 60px;
} */
.pos-relative .disconnect-btn {
  position: absolute;
  right: -60px;
}

/* StAKED UNSTAKED NFM */
.stake-unstake-buttons > div {
  max-width: 28em;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.stake-unstake-buttons .box-det {
  margin: 0.5rem 0;
  margin-bottom: 1.8rem;
  cursor: pointer;
}



.background-xmas{
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2.5rem 0;
  background-image:url('../../components/pages/images/marketplace/market.png') ;
}

@media (max-width: 750px) {
  .stake-sell-option .d-flex {
    flex-direction: column;
  }

  /* .padding-0-in-mobile {
    padding-bottom: 0;
  } */
  .background-xmas{
    background-size: cover;
  }
}

.text-line-through{
  position:relative;
  /* text-decoration: line-through;
  text-decoration-color: #FF3838;
  text-decoration-thickness: 0.2rem; */
}
.text-line-through .line{
  position: absolute;
  display: inline-block;
  left: 0;
  top: 50%;
  height: 0.2rem;
  width: 100%;
  background-color: #FF3838;
  transform: rotate(-14deg) translateY(-50%);
}
