.referral{
  padding: 1.5rem;
  border-radius: 15px;

  @media (max-width:600px) {
      padding: 0.5rem;
      margin: 0 -10px;
      
  }
  &__box{
      overflow: hidden;
      border-radius: 15px;

      &--header{
          padding: 1rem;
          background: var(--color-primary);
          border-radius: 16px 16px 0px 0px;
          
          display: flex;
          justify-content: space-between;
          align-items: center;

         
          &-text{
              h2{
                  margin-bottom: 0.5rem;
                  color: var(--white);
                  font-weight: 600;
              }
              p{
                  max-width: 420px;
                  font-size: 1.1rem;
                  color: var(--color-dark-grey);
                  margin-bottom: 0.2rem;
                  line-height: 1.4    ;
              }
              @media (max-width:600px) {
                  // float: left;
                  h2{
                      font-size: 1.5rem;
                      line-height: 1;
                  }
                  p{
                      font-size: .95rem   ;
                  }
              }
          }
          &-image{
              max-width: 95px;
              max-height:95px;

              @media (max-width:500px) {
                  
                  max-width: 70px;
                  max-height: 70px;
              }
              @media (max-width:445px) {
                  
                 display:none;
              }
          }
      }

      &--form{
          padding: 1.5rem 1rem;

          form{
              position: relative;
          }
      }

      &--input{
          background-color: #fff;
          color: #000 !important;
          border-radius: 50px;
          padding: 0.5rem 1.5rem;
          overflow: hidden;
          border: var(--light-border);
          outline: none;
          font-weight: 400;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          &:focus-within{
              border: none;
              outline: none;
              transform: scale(1);
          }
          &::placeholder{
              color: #000 ;
          }
      }
      &--submit{

         @media (min-width:560px) {
          
             position: absolute;
             right: -1px;
             top: 0;
             border-bottom-right-radius: 50px;
              border-top-right-radius: 50px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              width: max-content;
              margin-top: 0;
         }
          color: #fff;
          background: var(--color-primary);
          padding: 0.5rem 1.5rem;
          border: 1px solid var(--color-primary);
          outline: none;
          cursor: pointer;
          border-radius: 50px;
          width: 100%;
          margin-top: 1.2rem;
          margin-bottom: 0;
          transition: .2s all;

          &:hover{
           filter: brightness(95%);   
          }
      }

      @media (max-width:600px) {
          &--input{
          padding: 0.5rem 0.5rem;
              
          }
          &--submit{
          padding: 0.5rem 0.5rem;

          }
      }
  }   





  &__stats{
      margin-top: 1rem;

      display: grid;
      grid-template-columns: repeat(auto-fit , minmax(min(250px , 100%) , 1fr));
      grid-gap: 1.5rem;

      &--box{
          background: var(--sidebar-bg);
          border-radius: 16px;
          padding: 1rem;

          &-icon{
              display: flex;
              align-items: center;

              img{
                  max-width: 50px;
                  max-height: 50px;
                  filter: brightness(0);
              }

              span{
                  display: inline-block;
                  font-weight: 600;
                  margin-left: 1rem;
                  color: var(--color-dark-grey);
              }
          }

          p{
              width: 100%;
              padding: 1.8rem 0;
              text-align: center;
              font-weight: 600;
              font-size: 2rem;
              margin-bottom: 0;
          }
      }


  }



  &__social-links{
      margin: 2rem 0 1rem 0;
      display: flex;
      justify-content: space-between; 
      align-items: center;
      flex-wrap: wrap;
  }

  &__social-link {
      background-color: #fff;
      border: var(--light-border);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      color: #000;
      padding: 0.5rem 0.7rem;
      border-radius: 50px;
      align-self: stretch;
      font-weight: 500;
      margin: 0.5rem 0;

      img{
          max-width: 30px;
          // aspect-ratio:  1 / 1;
          max-height: 30px;
          // border-radius: 50%;
          margin-right: 0.5rem;
      }
      &:hover{
          filter: brightness(95%);
          
          color: #000;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
          span{
              color: #000;
          }
          transform: scale(1.05);
      }

      @media only screen and (max-width: 1100px) and (min-width: 1029px)  {
          &-title{
              display: none;
          }
      }
      @media only screen and (max-width: 998px) and (min-width: 768px)  {
          &-title{
              display: none;
          }
      }
      
      @media (max-width:600px) {
          padding: 0.3rem;
          margin: 0.5rem 0;

          img{
              margin-right: 0;
              object-fit: scale-down;
              outline-offset: 3px;
              max-width: 25px;
              max-height: 25px;
          }
          &-title{
              display: none;
          }
      }

  }

  .terms{
      color:var(--color-dark-grey);
      text-align: left;
      // max-width: 690px;
    margin: 1.2rem auto;
  }
}