.Login-page{
  

  &__box{
    padding: 2.5rem 1rem;
    min-height: 90vh;
    display: grid;
    place-content: center;
    // background: #161d30;
    border-radius: 5px;
    // border: 1px solid red;
    color: var(--color-dark-grey);
    & > h3{
      color: var(--color-dark-grey);
    }
    
    input{
      font-weight: 600; 

      &:not(input[type="submit"], input[type="checkbox"], #contactForm_password), .input-style{
        background-color: var(--white) !important;
        border: 1px solid var(--color-primary) !important;
        color: var(--color-dark-grey) !important;
        border-radius: 5px !important;
      }
      &#contactForm_password{
        color: var(--color-dark-grey) !important;

      }
      &::placeholder{
        color: var(--color-dark-grey) !important;
      }
    }

    .pass-field  {

      .ant-input-affix-wrapper {
        border-radius: 5px !important;

        border: 1px solid var(--color-primary) !important;

        .ant-input-password{
          border: none !important;
        }
        span{
          border: none !important;
          input{
            border: none !important;
          }
        }
      }

      .ant-input-suffix{
        svg{
          color: var(--color-dark-grey) !important;
        }
      }
    }
  }

  p , label{
    color: var(--color-dark-grey);
  }

}


/* TEXT EFFECT */
.field-set input[type="password"],
.field-set input[type="email"],
.field-set input[type="text"] {
  border-color: var(--color-primary) !important;
}
.field-set input[type="password"]:hover,
.field-set input[type="email"]:hover,
.field-set input[type="text"]:hover,
.field-set input[type="password"]:focus,
.field-set input[type="email"]:focus,
textarea:hover,
textarea:focus,
.field-set input[type="text"]:focus {
  border-color: var(--color-primary) !important;
}
/* span.ant-input-affix-wrapper.ant-input-password.form-control {
    border-color: #ff4d4f !important;
} */
.ant-input-affix-wrapper{
  border: var(--light-border);
}
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border-color: var(--color-primary) !important;
}
textarea:focus-visible {
  outline: none;
}

