.landing-main {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("../../../components/pages/images/backgroundlanding.png");
  background-blend-mode: multiply;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.landing-main .text-btn {
  display: flex;
  height: 100%;
  flex-direction: column;
  /* background: red; */
  justify-content: space-around;
  align-items: center;
}

.landing-main h1 {
  padding: 130px 0;
  padding-bottom: 1rem;
  text-align: center;
}

.landing-main p {
  text-align: center;
  color: #fff;
  font-size: 1.3rem;
  width: 95%;
  max-width: 900px;
  margin: auto;
  font-weight: 500;
}

.nft-section-landing {
  padding: 50px 0;
  min-height: unset;
  min-height: 62vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.nft-image-landing {
  height: 110%;
  max-height: 505px;
  /* border-radius: 20px; */
  border-radius: 10px;
  overflow: hidden;
  /* border: 1px solid #049adc; */
}

.nft-image-landing img {
  transition: 1s all linear;
}

.nft-card-landing h5 {
  font-size: 1rem;
  padding: 1rem;
  text-align: center;
  line-height: 1.6;
  font-weight: 400;
}

.buttons-landing-main {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  /* margin-top: 10rem; */
}

.buttons-landing-main a:last-child {
  margin-bottom: 2.5rem;
}

.nft-card-landing:hover .nft-image-landing img {
  cursor: pointer;
  transform: scale(1.2);
}

.nft-image-landing img {
  width: 100%;
  /* height: 101%; */
  height: auto;
  border-radius: 20px;
  object-fit: contain;
}

/* Third Section */
.third-section-landing {
  text-align: center;
  background-color: #141a2b;
  padding: 2rem 0;
}

.third-section-landing h1 {
  max-width: 756px;
  margin: 0 auto;
  margin-bottom: 2.5rem;
}

.horizontal_line {
  height: 3px;
}

.dropboxes {
  color: #fff;
  max-width: 350px;
  margin: 3rem auto;
  border: 1px solid #334162;
  padding: 1.2rem;
  border-radius: 10px;
  background: #23324e91;
}

.dropboxes img {
  margin-bottom: 1.2rem;
}

.dropboxes h3 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
}

.dropboxes p {
  font-size: 0.9rem;
}

.nft-card-landing .title {
  text-align: center;
  margin: 1rem 0;
}

.nft-card-landing .title h2 {
  font-size: 1.8rem;
}

@media (max-width: 982px) {
  .landing-main {
    min-height: 89vh;
  }

  .landing-main.marketplace-landing {
    min-height: 100vh;
    /* height: unset; */
    padding-top: 90px;
    padding-bottom: 1rem;
  }

  .buttons-landing-main {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    margin-top: 0;
  }

  .buttons-landing-main .pre-order-btn {
    padding: 0.5rem;
    min-width: 150px;
  }

  .buttons-landing-main .explore-btn {
    padding: 0.5rem;
    /* min-width: 150px; */
  }

  .landing-main h1 {
    padding: 40px 0;
    font-size: 2.3rem;
    text-align: center;
  }

  .nft-section-landing {
    padding: 50px 0;
  }

  .third-section-landing {
    padding: 70px 0;
  }

  .third-section-landing h1 {
    font-size: 2.3rem;
  }

  .nft-card-landing {
    height: 100%;
  }

  .nft-image-landing {
    /* height: 410px; */
    height: auto;
    border-radius: 20px;
    max-height: unset;
    /* aspect-ratio: 16/9; */
  }
}

.changebg {
  background-color: #00153e;
  border-bottom: none !important;
}

.background-2 {
  background-color: #121727;
}

.sold_section_header {
  padding-top: 2.5rem;
}

@media (max-width: 550px) {
  .landing-main p {
    font-size: 1.01rem;
  }

  .buttons-landing-main {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .buttons-landing-main .pre-order-btn {
    min-width: 110px;
  }

  .buttons-landing-main a:last-child {
    margin-left: 0;
  }
}

/* NITRO ACTIVITY PADDING ISSUE */
.no-padding-in-mobile {
  padding: 0px 0px 50px;
}

/* NEW MARKETPLACE DESIGN  */
.marketplace-banner {
  width: 100%;
}

.marketplace-banner img {
  width: 100%;
}
.marketplace-text-box{
  background: linear-gradient(107.48deg, #B24EBE -85.77%, #211B7C 100%);
  color: var(--white);
  h1{
    padding: 1.5rem 0 0.5rem 0;
    font-weight:900;
    text-align: center;
    color: var(--white);
  } 
  p{
    max-width: 900px;
    margin: auto;
    text-align: center;
    font-size: 1.1rem;
  }
}
.available-nfm {
//   background-image: url('../../../components/pages/images/marketplace/backxmas.png'), linear-gradient(
//       359.53deg,
//       #0a0929 0.44%,
//       rgba(0, 4, 19, 0) 45.61%
//     )
//  ;
  // background-blend-mode: multiply;
  // background-position: center center;

  &-bot-section{
    // background-image: url("../../../components/pages/images/marketplace/marketplaceBG.jpg");
    background-color:  rgb(7, 16, 59);
    background-blend-mode: multiply;
    background-position: center center;

    padding: 2.5rem 1rem;
    margin: auto;
    color: var(--white);
    .max-width {
      max-width: 80rem;
      margin: auto;
    }

    h2{
      color: var(--white) !important;
    }
  }

  &-bots {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 2rem;
  }


}

.available-bot {
  text-align: center;
  /* background: red; */
  padding: 1.5rem;
  h2 {
    margin-top: 1rem;
    color: var(--white);
  }
  &__image {
    max-width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 10px solid;
    overflow: hidden;
    margin: auto;
    img {
      width: 100%;
      object-fit: cover;
    }

  }

  &:hover{
    &__image {
      box-shadow: -8px 2px 50px;
    }
  }
}

.border-sky {
  border: 15px solid #7dd2fa;
  filter: drop-shadow(0px 0px 40.635px #7dd2fa);
}

.border-pink {
  border: 15px solid #da3d85;
  filter: drop-shadow(0px 0px 40.8426px #da3d85);
}

.border-purple {
  border: 15px solid #c075f8;
  filter: drop-shadow(0px 0px 39.8775px #deb1fc);
}

.left-nfm-stats {
  max-width: 250px;
  margin: auto;
  margin-top: 1rem;
}
.white-btn-bg {
  background-image: url("../../../components/pages/images/marketplace/btnBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
  padding: 0.65rem 1.5rem;
  width: 150px;
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: 1px;
  margin: 1rem auto;
  border-radius: 10px;
  color: #250088;
}

.payment-section {
  // background-image:
  //  url('../../../components/pages/images/marketplace/backxmas.png'), 
  //  url("../../../components/pages/images/marketplace/marketplaceBG.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: 0 0;
  // background-blend-mode: multiply;
  position: relative;
  padding: 5rem 0 0rem 0;
  padding-top: var(--top-gap);
}
// .payment-section::after{
//   display: none;
//   content: '';
//   pointer-events: none;
//   position: absolute;
//   z-index: 0;
//   inset: 0;
//   width: 100%;
//   height: 100%;
//   opacity: .4;
//   background-image: url('../../../components/pages/images/marketplace/backxmas.png');
// }

@media (max-width: 982px) {
  .marketplace-text {
    margin-bottom: 1.5rem !important;
  }
}

@media (min-height: 968px) {
  .min-height-23vh {
    min-height: 23vh;
  }
}

@media only screen and (min-device-height: 492px) and (max-device-height: 750px) and (orientation: landscape) {
  /* For landscape layouts only */
  .nft-image-landing {
    max-height: 390px;
  }
}
