.hexagon-grid {
  height: 100%;
  display: grid;
  grid-template-columns: 350px 1fr;
}

.sidebar-hexagon {
  display: grid;
  grid-template-rows: 300px 1fr;
}

.sidebar-hexagon .colored-part {
  background-image: url("../../../components/pages/images/backSideBar.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  padding: 1rem;
}

.colored-part .row-style {
  display: flex;
  align-items: center;
}

.row-style h2 {
  margin-bottom: 0;
  margin-left: 0.5rem;
}

.row-style i {
  cursor: pointer;
  font-size: 1.5rem;
}

.sidebar-hexagon .colored-part h2 {
  color: #fff;
  font-size: 1.5rem;
  margin-top: auto;
}

.sidebar-hexagon .colored-part p {
  color: #fff;
  margin-top: auto;
  font-size: 1rem;
  font-weight: 500;
}

.hex-menus {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 90px);

  grid-column-gap: 15px;
  grid-row-gap: 20px;
  background: #fff;
  /* grid-template-columns: 100px auto; */
}

.hex-menu.full {
  grid-column-start: 0;
  grid-column-start: 3;
}

.hex-menu {
  padding: 0.5rem;
  max-height: 100px;
  background: #f5f5f5;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 10px;
  box-shadow: 0 0 2px #f4f4f4;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 1px 0px;
}

.hex-menu:hover i {
  color: #000 !important;
}

.hex-menu h6 {
  color: #000 !important;
  font-weight: 600;
  font-size: 0.93rem;
}

.hex-menu p {
  /* font-size: 1.05rem; */
  margin-bottom: 0.2rem;
  color: #000;
  /* line-height: 1.4; */
}

.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 100px);
  /* grid-column-gap: 20px; */
  /* grid-row-gap: 20px; */
}

.hex-menu.colored {
  background-color: #36b7e3;
  border: 1px solid #36b7e3;
}

.hex-menu.full {
  grid-column: 1 / 3;

  /* grid-area: 1 / 1 / 2 / 3; */
}

/* .hex-menu.row-double {
  grid-row: 1 / 3;
} */
/* .div2 { grid-area: 2 / 1 / 3 / 2; }
.div3 { grid-area: 2 / 2 / 3 / 3; }
.div4 { grid-area: 3 / 1 / 4 / 2; }
.div5 { grid-area: 3 / 2 / 4 / 3; }
.div6 { grid-area: 4 / 1 / 5 / 2; } */
