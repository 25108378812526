.analytics-page{
  .gateWayBox {
    background-color: #ffffff;
    margin: 10px;
    display: inline-flex;
    border-radius: 5px;
    padding: 5px 14px;
    align-items: center;
  }
  .gateWayonsign {
    border-radius: 47px;
    border: 4px solid #8bc34a;
    margin: 0px 20px 0px 0px;
    width: 60px;
    height: 60px;
  }
  
  .gatewayHead {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: #3c3c3c;
  }
  
  .gatewaytext {
    font-size: 10px;
    font-weight: 400;
    text-align: initial;
  }
  
  .gatwayOn {
    text-align: center;
    margin: 0px 10px;
    box-shadow: 0px 2.59238px 9.72144px rgb(0 0 0 / 25%);
    padding: 7px 10px;
    border-radius: 5px;
  }
  
  p.textMain {
    font-size: 12px;
    line-height: initial;
    margin-top: 1px;
  }
  
  .marginAuto {
    margin: auto;
  }
  
  .gateWayFilter {
    border: 1px solid #0d33df;
    border-radius: 3px;
    height: fit-content;
    padding: 5px;
    color: #fff;
  }
  .gateWayFiltertext {
    margin: 10px;
  }
  .gateWayFilterIcon {
    margin: 10px;
  }
  .dash-box div .small-u3 {
    color: #ccc;
    text-align: center;
  }
  
  .small-u3 {
    color: #414141;
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 0;
  }
  
  .dash-boxes-u3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(159px, 1fr));
    grid-gap: 18px;
    margin-top: 1rem;
    /* height: 75vh; */
    padding: 0 15px 0 0;
    overflow-y: auto;
  }
  
  .back_icon {
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: -7px;
    margin-left: -7px;
    background-color: transparent;
    margin: 2rem 0 1.5rem;
  }
  
  .back_icon i {
    color: var(--color-dark-grey);
    font-size: 1.4rem;
    cursor: pointer;
    padding: 0 10px;
    transition: all 0.3s;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }
  
  .back_icon i:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  h1.analytics-head {
    font-size: 1.8rem;
    margin: 0;
    margin-left: 1rem;
  }
  
  .chart-trx {
    margin-top: 25px;
  }
  
  button.chakra-button.timeline-btn {
    padding: 5px 10px;
    font-size: 14px;
    margin: 2px 2px;
  }
  
  button.chakra-button.timeline-btn.active {
    background-color: #fff;
  }
  
  .row.sensors-box {
    max-height: 64vh;
    overflow: auto;
    padding-right: 0.5rem;
  }
  
  /* Let's get this party started */
  .row.sensors-box::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  .row.sensors-box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(219, 217, 217, 0.747);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  
  /* Handle */
  .row.sensors-box::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(200, 200, 202, 0.8);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  
  .snesorImg {
    margin: 10px auto;
    height: 60px !important;
    width: auto !important;
  }
  
  p.sensor-value {
    font-weight: 600;
    font-size: 22px;
    margin: 0;
    line-height: initial;
  }
  
  .textUltraSmall-u3 {
    font-size: 11px;
    height: 20px;
  }
  
  .textMain {
    margin: auto;
  }
  
  .dash-box-u3 {
    padding: 12px 6px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    color: #ffffff;
    height: 190px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 0px auto 20px;
    cursor: pointer;
    transition: all 2s;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .dash-box-u3:hover {
    box-shadow: 0px 0px 8px #454545;
  }
  
  .colorGreen {
    color: #8bc34a !important;
  }
  
  .viewAnalytics {
    font-size: 15px;
    background-image: linear-gradient(to right, #de2ef0, #440bc9);
    color: #ffff;
    border-radius: 8px;
    text-align: center;
    padding: 6px;
    margin-top: 10px;
  }
  
  .viewAnalytics:hover {
    opacity: 0.8;
  }
  
  .sensor-card {
    background-color: var(--white);
    border-radius: 8px;
    padding: 15px 12px;
    border: 1px solid var(--sidebar-bg);
  }
  
  .sensor-card p {
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    margin: 0;
    margin-top: 0.5rem;
  }
  
  .sensor-card img.sensor-Img {
    height: 16rem;
    border-radius: 14px;
  }
  
  .textSmall {
    font-size: 18px;
    font-weight: 500;
    color: var(--color-dark-grey);
    line-height: initial;
  }
  
  .colorGreen {
    color: #8bc34a;
  }
  .colorRed {
    color: #f44336;
  }
  .colorBlue {
    color: #496fca;
  }
  .coloryellow {
    color: #ffc107;
  }
  .colorGray {
    color: #714318;
  }
  
  .chart {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    width: 95%;
    margin: 0 auto;
  }
  
  .chartHead {
    color: #414141;
    text-align: left;
    font-size: 30px;
    font-weight: 500;
  }
  
  #chart-trx > div {
    overflow: auto;
  }
  #chart > div {
    overflow: auto;
  }
  .css-cljdej {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 0.5rem;
  }
  
  .css-rb6w15 {
    display: flex;
    flex-flow: row wrap;
    /* width: 100%; */
    background: #ebebeb;
    border-radius: 8px;
    padding: 2px;
  }
  .css-1crqwgw {
    display: inline-flex;
    order: 1;
  }
  .chakra-button {
    border: none !important;
  }
  .css-vkhaod[data-active] {
    background: var(--chakra-colors-white);
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    border-color: var(--chakra-colors-gray-200);
  }
  .css-vkhaod {
    display: inline-flex;
    appearance: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    outline: transparent solid 2px;
    outline-offset: 2px;
    width: auto;
    line-height: 1.2;
    border-radius: 8px;
    transition: all 250ms cubic-bezier(0.08, 0.52, 0.52, 1) 0s;
    padding: 0px 0.5rem;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
  }
  .css-vkhaod[data-active] {
    background: #fff;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    border-color: #dedede;
  }
  .css-bdy63n {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    order: 3;
    width: 100%;
    margin-top: 2px;
    margin-left: 0px;
  }
  
  .css-bdy63n {
    order: 2;
    width: auto;
    margin-top: 0px;
    margin-left: 1px;
  }
  .css-vubhbv {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    border-color: var(--chakra-colors-gray-200);
    border-radius: 0.5rem;
    width: 100%;
    background: var(--chakra-colors-white);
    padding-inline: 0.5rem;
  }
  .css-1088zyq {
    text-align: center;
    font-size: 0.875rem;
    flex: 1 1 0%;
  }
  .css-j89ybo {
    display: inline-flex;
    order: 2;
    margin-left: auto;
  }
  .dounloadChakra {
    border: none;
    border-radius: 5px;
    background: #709ae5;
    color: #fff;
    width: 23px;
  }
  
  .device__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .device__edit-icon{
    cursor: pointer;
  }
  
  @media (max-width: 1500px) {
    .sensor-card img.sensor-Img {
      height: 13rem;
    }
    h1.analytics-head {
      font-size: 1.6rem;
    }
    .textSmall {
      font-size: 16px;
    }
    .snesorImg {
      height: 55px !important;
    }
    p.sensor-value {
      font-size: 18px;
    }
    .small-u3 {
      font-size: 9px;
    }
    .dash-box-u3 {
      height: 160px;
    }
  }
  
  @media (max-width: 1100px) {
    .dash-box-u3 {
      height: 180px;
    }
  }
  
  @media (max-width: 800px) {
    .sensor-card img.sensor-Img {
      height: 17rem;
    }
    .gateWayBox {
      align-items: center;
      width: 37%;
      align-items: center;
      justify-content: center;
    }
    .dash-box-u3 {
      height: 165px;
    }
  }
  
  @media (max-width: 552px) {
    .gateWayBox {
      width: 50%;
    }
    h1.analytics-head {
      font-size: 1.4rem;
      margin-left: 0.5rem;
    }
    .back_icon {
      margin: 1rem 0 1rem;
    }
    .row.sensors-box {
      max-height: 25vh;
      overflow: auto;
      margin-bottom: 1rem;
    }
    .chart {
      width: 94%;
      margin: 0 auto;
    }
    .gateWayonsign {
      margin: 0px 29px 0px 0px;
      width: 40px;
      height: 30px;
    }
    .gatewayHead {
      font-size: 10px;
    }
    .gateWayBox {
      padding: 5px 10px;
    }
  }
  
}
