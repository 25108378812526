.BOX {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
  border-radius: 15px;
}
.gradient-bg {
  background: var(--gradient-bg);
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  border-radius: 8.72303px;
}
