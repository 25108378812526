.burn-page {
  // --side-padding: 0 2rem;
  // background: url(../../../components/pages/images/burn/header/bodyBack.png);
  // background:url("../images/burn/header/bodyBack.png");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-color: var(--white);

  .header {
    padding: 0;
    height: 100%;
  }

  .header-container {
    position: relative;
    min-height: 90vh;
    height: 100%;
    isolation: isolate;
    margin-top: 87px;
    background-image: url("../../../components/pages/images/burn/header/headerBackLight.png");
    object-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
  }

  .image-absolute {
    display: none;
    position: absolute;
    border-radius: 20px;
    overflow: hidden;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  .image-absolute img {
    width: 100%;
    height: 100%;
    object-position: left;
  }

  .text-side {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;

    display: grid;
    grid-template-columns: repeat(auto-fit , minmax(min(250px , 100%) , 1fr));
    align-content: center;
    grid-gap: 0rem 1rem;
  }

  .text-side > div {
    // flex: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    &:first-of-type{
      display: flex;
      flex-direction: column;
      justify-content: center;

    }
  }

  .text-side > div> img {
    // max-width: 550px;
    // width: 90%;
    // margin: auto;
    // height: 100%;
    width: 100%;
    height: 100%;
  }

  .text-header {
    /* max-width: 30rem; */
    /* flex: 0.5; */
    /* background: red; */
    /* max-width: 50rem; */
    text-align: center;
    /* margin-left: auto; */
  }

  .text-header h1 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: clamp(6.188rem, calc(12px + 4.125vw), 4.313rem);
    line-height: 1.1;
    margin-bottom: 0;
  }

  .text-header h1.first {
    display: block;
    perspective: 100px;
    // color: #c200ed;
    font-size: 9rem;
    font-size: clamp(6.188rem, calc(25px + 7.125vw), 10.313rem);

    /* font-family: "GothamNarrow"; */
    // color: rgb(245, 217, 58);
    /* text-shadow: 0px 5px 37.76px rgba(49, 22, 88, 0.77); */
    // background: rgb(244, 188, 105);
    // background: linear-gradient(145deg,
    //     rgba(244, 188, 105, 1) 0%,
    //     rgba(233, 49, 21, 1) 100%);

    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  .text-header h1.first span {
    transform-style: preserve-3d;
    transform: rotateX(45deg);
  }

  .text-header h1.second {
    font-size: clamp(7.188rem, calc(45px + 8.125vw), 10.713rem);

    /* font-size: 10.7rem; */
    line-height: 0;
    margin-bottom: 0;
    margin-left: -5px;
  }

  .text-header h1.third {
    font-size: 8rem;
    font-size: 9.7rem;
    font-size: clamp(5.188rem, calc(25px + 7.125vw), 9.713rem);
  }

  .text-header h1.second,
  .text-header h1.third {
    color: #fff;
    line-height: 1;
  }

  .buy-ncash {
    text-align: center;
    margin-top: 2rem;
  }

  .btn-buy-ncash {
    min-width: 150px;
    margin-top: 2rem;
    margin-left: -3rem;
  }

  @media only screen and (min-height: 1300px) and (max-height: 1400px) {
    .header-container {
      min-height: 56vh !important;
    }
  }

  @media (max-width: 892px) {
    .text-side {
      // flex: 1;
    }

    // .header-container {
    //   min-height: 82vh;
    //   height: max-content;
    // }

    .text-header {
      text-align: center;
      max-width: 100%;
    }

    .text-header h1.second {
      text-align: center;
    }

    .btn-buy-ncash {
      margin-left: 0;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .image-absolute img {
      object-position: 18%;
      object-fit: cover;
    }

    .text-header h1.first {
      font-size: clamp(4.088rem, calc(25px + 5.125vw), 8.313rem);
    }

    .text-header h1.second {
      font-size: clamp(5.188rem, calc(45px + 6.125vw), 7.713rem);
    }

    .text-header h1.third {
      font-size: clamp(4.188rem, calc(25px + 6.125vw), 7.713rem);
    }
  }

  @media (max-width: 756px) {
    .text-side {
      flex-direction: column-reverse;
    }

    .header-container {
      min-height: 100vh;
      height: 100%;
    }

    // .text-side > div:last-of-type img {
    //   max-width: 350px;
    //   width: 90%;
    //   height: 100%;
    //   margin: auto;
    // }

    // .text-side > .coin-images img {
    //   max-width: 350px;
    //   width: 90%;
    //   margin: auto;
    //   height: 100%;
    // }
  }
  // @media () {
    
  // }s
  @media (max-width: 530px) {
    .text-side{
      // padding-top: 90px;
      display: inline-flex;
    }
    .text-header h1 {
      font-size: 3.5rem;
    }

    // .text-side > div:last-of-type img {
    //   max-width: 300px;
    // }
    .text-side > div:last-of-type {
      max-width: 85%;
      max-height: 230px;
      margin: auto;
    }

    .text-header h1.second {
      font-size: 8.7rem;
    }

    .text-header h1.third {
      font-size: 5.5rem;
    }
  }

  @media (max-width: 395px) {
    .image-absolute img {
      object-position: 22%;
      object-fit: cover;
    }

    .text-header h1 {
      font-size: 2.8rem;
    }

    .text-header h1.second {
      font-size: 8.7rem;
    }

    .text-header h1.third {
      font-size: 5.5rem;
    }
  }

  @media (max-height: 584px) {
    .text-side > div:last-of-type img {
      max-width: 200px;
    }

    .header-container {
      min-height: 125vh;
      height: 100%;
    }

    .text-header h1 {
      font-size: 2.4rem;
    }

    .text-header h1.second {
      font-size: 7.7rem;
    }

    .text-header h1.third {
      font-size: 3.5rem;
    }
  }

  @media (max-height: 380px) {
    .text-side > div:last-of-type img {
      max-width: 200px;
    }

    .header-container {
      min-height: 155vh;
    }
  }

  /* For nexus and Apple ipad */
  @media only screen and (min-height: 1170px) and (max-width: 1020px) {
    .header-container {
      min-height: 45vh;
    }

    /* .text-side > div:last-of-type img {
        max-height: 450px;
      } */
  }

  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
    .header-container {
      min-height: 40vh;
    }
  }

  .real-time-burn {
    margin: 2rem 0 0 0;
    padding: 2rem 1rem;
  }

  .grid-boxes {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
  }

  .grid-boxes > div {
    padding: 1rem;
    border-radius: 15px;
    // color: #fff;
    text-align: center;
  }
  .box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .grid-boxes > div:nth-child(1) {
    // background-image: url("../../../components/pages/images/burn/header/burnedThisMonthBack.png");
    // background-size: cover;
    // background-repeat: no-repeat;
    background-color: var(--color-primary);
    color: var(--white);
  }
  .grid-boxes > div:nth-child(2) {
    // background-image: url("../../../components/pages/images/burn/header/BurnedAllTime.png");
    // background-size: cover;
    // background-repeat: no-repeat;
    background-color: var(--white);
    color: var(--color-dark-grey);
  }
  .text-black {
    color: var(--color-dark-grey);
  }
  .box > div {
    flex: 1;
  }
  .box h2 {
    font-size: clamp(1rem, 0.7rem + 1.6vw, 2.1rem);
    line-height: 1.6;
    font-weight: 700;
    margin-bottom: 0;
  }
  .box p {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: 0;
  }
  .burn-history {
    margin-top: 2.5rem;
  }
  /* Table */
  .table-burn table td {
    color: var(--color-dark-grey);
    text-align: center;
    font-weight: 500;
  }

  .table-burn table tr {
    border-color: #555;
  }
  .table-burn table th {
    font-weight: 500;
    color: var(--white);
    text-align: center;
  }
  .table > :not(:first-child) {
    /* border-top: 2px solid currentColor; */
    border-top: unset;
    border-bottom: 1px solid rgb(72, 72, 72);
  }
  /* .blue {
      background-color: rgba(0, 0, 255, 0.25);
    } */
  .table-burn nav ul li button {
    color: #fff;
  }
  .table-burn nav {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50rem;
    margin: auto;
  }
  .table-burn nav ul li > div {
    color: #fff;
  }
  .text-right {
    text-align: right;
  }
  .toal {
    margin: 1rem 0;
    color: #fff;
    font-weight: 400;
    font-size: 1.2rem;
  }
  .toal h4 {
    font-weight: 400;
  }
  .chart-burn {
    margin-top: 2.5rem;
  }
  .chart-size {
    max-width: 65rem;
    margin: auto;
  }
  .right {
    text-align: right;
  }
  /* .activity-filter select {
width: 150px;
margin: -1rem auto;
color: #fff;
border: 3px solid #353a4b;
padding: 0.5rem;
border-radius: 3px;
}
.activity-filter select option {
padding: 1.3rem 0.5rem !important;
background: #161c30;
color: #fff;
} */
  /* @media (min-width: 1000px) {
.activity-filter {
  margin-top: -2rem;
}
} */
  table {
    /* border: 1px solid #ccc; */
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }

  table tr {
    /* background-color: #f8f8f8; */
    /* border: 1px solid #ddd; */
    padding: 0.35em;
    vertical-align: middle;
  }

  table th {
    font-size: 19px;
    // font-weight: 600 !important;
  }

  table th,
  table td {
    font-weight: 400;
    padding: 0.925em;
    text-align: center;
  }

  table th {
    font-size: 0.92em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  @media screen and (max-width: 970px) {
    table {
      border: 0;
    }
    table th {
      font-size: 0.78em;
      letter-spacing: 0em;
    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      /* border-bottom: 3px solid #ddd; */
      display: block;
      margin-bottom: 0.625em;
    }

    table td {
      border-bottom: 1px solid #2c2c2c;
      display: block;
      font-size: 0.8em;
      text-align: right;
    }

    table td::before {
      /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: capitalize;
    }

    table td:last-child {
      border-bottom: 0;
    }
    .center-in-mobile {
      text-align: center;
    }
    .counter.in-mobile-right {
      justify-content: flex-end;
    }

    .trx-id {
      display: inline-block;
      max-width: 168px;
      color: #34c77b;
    }
    .green-text-lat-long {
      color: #34c77b;
    }
    .de-table.table-rank .d-plus {
      color: #fff;
    }
  }

  #freez {
    text-decoration: underline !important;
  }

  #block {
    text-decoration: underline !important;
    color: red;
  }
  @media (min-width: 982px) {
    [data-label="Address"] {
      text-align: left;
    }
  }
  .max-width-250 {
    max-width: 350px;
    display: inline-block;
    color: var(--color-dark-grey);
    overflow-wrap: break-word;

    &:hover {
      text-decoration: underline;
    }
  }

  input[type="submit"].btn-pay {
    display: block;
    margin: 0 auto;
    padding: 8px 20px;
    background: #008000;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 3px #111;
    cursor: pointer;
  }
  .overflow-text {
    overflow-wrap: break-word;
  }
  /* /Temp Hiden */
  /* tbody tr td {
  visibility: hidden !important;
} */

  #order-table {
    background: none;
    padding: 1rem;
  }
  @media (max-width: 1237px) {
    .max-width-250 {
      max-width: 250px;
      margin-left: auto;
      margin-right: 0;
      display: block;
      text-align: right;
    }
  }
  @media (max-width: 982px) {
    .ant-popover-placement-top .ant-popover-arrow {
      left: unset !important;
      right: 10% !important;
    }
    .address-text-table {
      display: inline-block;
      max-width: 240px;
    }
    .btn-main1,
    .btn-main-delete {
      margin: 0;
    }
    .btn-main1.ml-2p {
      margin-left: 1rem !important;
    }
    .de-table.table-rank td {
      padding: 12px 0;
    }
    tbody {
      display: table-caption;
      width: 100%;
    }
    tbody tr {
      margin: 1.5rem 0.5rem;
      background-color: #2d3445;
      background-color: rgba(78, 79, 137, 0.5);
      border-radius: 10px;
    }
    tbody tr td span {
      display: block;
      width: 100%;
      text-align: right;
    }

    /* Temp Hidden */
    /* tbody tr td {
    visibility: visible !important;
  }
  tbody tr td span {
    visibility: hidden !important;
  } */
    .max-width-250 {
      max-width: 200px;
    }
    a.max-width-250::first-letter {
      padding-left: 2rem;
    }
  }
  @media (max-width: 480px) {
    .max-width-250 {
      max-width: 120px;
      padding-left: 2rem;
    }
    a.max-width-250::first-letter {
      padding-left: 0;
    }
  }

  @media (max-width: 320px) {
    :root {
      --side-padding: 0 0.5rem;
    }
    table td::before {
      font-size: 0.7rem;
    }
  }

  @media (max-width: 540px) {
    .burn-history > div {
      padding: 0.5rem;
    }
    .box {
      display: flex;
      flex-direction: column-reverse;
    }
    .box p {
      font-size: 1rem;
      line-height: 1.4;
      padding-top: 1.5rem;
    }
  }
  @media (min-width: 982px) {
    /* #order-table {
        max-width: 90rem;
      } */
    table {
      border-collapse: separate !important;
      border-spacing: 20px 10px !important;
    }
    table tr th {
      width: max-content;
      padding: 2rem;
      border-radius: 10px;
      margin: 0 1rem;
      margin: auto;
      font-weight: 600;
      background-color: var(--color-primary);
      color: var(--white);
    }
    table tr td {
      width: max-content;
      padding: 1rem;
      border-radius: 10px;
      margin: 0 1rem;
      margin: auto;
      background-color: var(--white);
    }
    table a {
      font-size: 0.95rem;
      color: var(--color-dark-grey);
    }
  }

  .headH3 {
    text-align: center;
    font-size: 2.1rem;
    color: var(--color-dark-grey);
    font-weight: 600;
    text-transform: uppercase;
    margin: 1.2rem 0;
    letter-spacing: 1px;
    margin-bottom: 1.2rem;
  }

  @media (max-width: 540px) {
    :root {
      --side-padding: 0 1rem;
    }
  }
}
