.nfm-detail-page {
  display: grid;
  grid-template-columns: 330px 1fr;
  grid-gap: 30px;
}
.nfm-detail-page > div {
  border-radius: 20px;
  background: #23324e91;
  padding: 15px;
}
.image-nfm img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.nfm-detail-text {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 25px;
}
.nfm-detail-text > div {
  padding: 15px;
}
.nfm-detail-text > div:first-of-type {
  border-right: 2px solid #2a3852;
}
.nfm-detail-text > div:first-of-type p {
  margin-top: 2rem;
}

.attributes-nfm-details {
  margin: 2rem auto;
  background-color: #23324e91;
  padding: 15px;
  border-radius: 20px;
}
.attributes-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: 15px;
}
.attributes-boxes > div {
  padding: 20px;
  border-radius: 15px;
  background: #23324e;
}
.att-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.att-box p:first-of-type {
  margin-bottom: 0rem;
  font-weight: 500;
  color: rgb(254, 139, 224);
}
.att-box p:last-of-type {
  margin-bottom: 0rem;
  font-weight: 600;
  font-size: 1rem;
}

.key-values .dfjb p {
  font-weight: 500;
}

.myct .mt-120pxx {
  margin-top: 1.2rem;
}
@media (max-width: 992px) {
  .nfm-detail-page {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
@media (max-width: 768px) {
  .nfm-detail-text > div:first-of-type {
    border-right: none;
  }
  .nfm-detail-text > div {
    padding: 5px;
  }

  .key-values .dfjb p {
    margin-bottom: 0.2rem;
    padding: 2px;
  }
}
@media (max-width: 315px) {
  .nfm-detail-text {
    /* display: grid; */
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    /* grid-gap: 25px; */
  }
  .nfm-detail-text h2 {
    font-size: 1.6rem;
    line-height: 1.2;
  }
}
