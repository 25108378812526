/*  NEW STYLES */
.confirm-details-page {
  display: grid;
  grid-template-columns: 30% 70%;
  width: 100%;
  min-height: 100vh;
}

#address-text,
#address-text:focus,
#address-text:hover {
  background-color: #1a1a26;
  border: none;
  border-color: transparent !important;
  outline: none;
  width: 100%;
  resize: none;
  /* overflow: auto; */
  /* min-height: 5vh;
  height: auto;
  max-height: 20vh; */
  text-align: center;
}

#address-text::-webkit-scrollbar {
  display: none;
}

.justify-content-between {
  justify-content: space-between !important;
}
.confirm-details-sidebar {
  width: 100%;
  background-color: var(--sidebar);
  color: var(--white);
  padding: 25px;
  box-shadow: 20px 0 20px -10px var(--text-end);
  /* margin-top: 2rem; */
}

/* .confirm-details-sidebar::After {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    right: 0;
    box-shadow: 0px 12px 25px 5px var(--text-end);

} */

.confirm-details-sidebar h4 {
  /* max-width: 250px; */
}

.confirm-details-sidebar .side-icon {
  display: block;
  font-size: 2rem;
  text-align: right;
  cursor: pointer;
  margin-bottom: 1.5rem;
}

.side-icon i {
  background: hsla(244, 28%, 46%, 1);

  background: linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#585495", endColorstr="#B24EBE", GradientType=1); */
}

.confirm-details-sidebar h3 {
  font-size: 1.5rem;
}

.gradient-box {
  background: hsla(244, 28%, 46%, 1);

  background: linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  border-radius: 5px;
  padding: 2px;
}

.bar-btn {
  display: none;
}

.border-field {
  margin-bottom: 0;
  max-width: 200px;
  font-size: 0.9rem;
}

.box-det {
  margin: 1.9rem 0;
  display: block;
}

.field {
  background: var(--sidebar);
  /* max-width: 250px; */
  border-radius: 5px;
  padding: 10px;
}

.submit-btn {
  /* display: block; */
  background-color: var(--btn-bg);
  padding: 12px 15px;
  border-radius: 14px;
  /* width: 90%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem auto;
  margin-top: 3rem;
  /* margin-top: auto; */
}

.submit-btn p {
  background: hsla(244, 28%, 46%, 1);

  background: linear-gradient(
    45deg,
    rgb(113, 109, 161) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );
  /* background: var(--text-end); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.3rem;
  font-weight: 900;
  margin-bottom: 0;
}
.submit-btn:last-of-type p {
  margin-right: 0.5rem;
}
.submit-btn i {
  background: var(--btn-bg);
  /* padding: 8px; */
  height: 32px;
  width: 32px;
  display: grid;
  place-content: center;
  font-size: 1rem;
  color: #fff;
  border-radius: 50%;
  /* background: linear-gradient(90deg, hsla(244, 28%, 46%, 1) 0%, hsla(294, 46%, 53%, 1) 100%);

    background: -moz-linear-gradient(90deg, hsla(244, 28%, 46%, 1) 0%, hsla(294, 46%, 53%, 1) 100%);

    background: -webkit-linear-gradient(90deg, hsla(244, 28%, 46%, 1) 0%, hsla(294, 46%, 53%, 1) 100%); */
  /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  box-shadow: 0px 0px 2px 3px #505257;
}

/* SELECT MINER */
.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-field {
  margin-bottom: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: linear-gradient(
    90deg,
    rgb(113, 109, 161) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );
  display: none;
}

.selected-field.active {
  display: inline-block;
}

.box-det:hover {
  color: #fff;
  filter: brightness(110%);
}

/* .miner-boxes .box-det {
    border-radius: 5px;
    box-shadow: 0px 0px 0px 0px var(--text-end);
} */

.address {
  margin-top: 2rem;
  max-width: 100%;
  font-size: 0.95rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

@media (max-width: 780px) {
  .submit-btn {
    margin-top: 1.2rem;
    margin-bottom: 1rem;
  }
}

.max-width-full {
  max-width: 100% !important;
  font-size: 0.8rem;
}
