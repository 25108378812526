.sorting {
  max-width: 12em;
  margin: auto;
  margin-bottom: 1.5rem;

  padding-top: 1.2rem;

  select , .currency-dropdown{
    padding: 0.5rem 1.5rem;
    margin: 0 0.5rem;
    border:var(--light-border);
    background-color: transparent;
    border-radius: 5px;
    color: var(--color-dark-grey);

    option{
      background-color: var(--white);
      color: var(--color-dark-grey);
      border: 0;
    }
  }

  .right-side{
    margin-right: 4vw !important;
    max-width: 24em;
    div select{
      padding: 0.5rem 1.5rem;
      margin: 0 0.5rem;
      border:var(--light-border);
      background-color: transparent;
      border-radius: 5px;
      color: var(--color-dark-grey);
      display: inline-block;
      max-width: 190px;

      option{
        background-color: var(--white) ;
        color: var(--color-dark-grey);
        border: 0;
      }
    }
  }
}


.right-side {
  text-align: right;
}

