
/* FOOTER */
.footer-nitro{
  padding: 2rem 0;
  background-color: var(--white);
  border-top: var(--light-border);
  &__container{
    width: 90%;
    max-width: 85rem;
    margin: auto;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;

    @include gridLayout(320px , 1rem);

    align-items: baseline;
    // justify-items: center;
  }

    
  .footer-links-head{
    // font-size: 2rem;
    text-decoration: underline;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  &__logo-text{
    flex: 0.6;
    /* max-width: 350px; */
    // border-right: 4px solid var(--color-primary);
    margin-bottom: 1rem;
    img{
      max-width: 180px;
    }
    p{
      font-weight: 400;
      font-size: 0.95rem;
      line-height: 1.3;
      margin: 1rem 2rem 2rem 0;
      color: var(--color-dark-grey);

      @media (max-width:540px) {
        margin: 0 1rem;
      }
    }
  }

  &__about-links{
    width: 100%;
    // border-right: 2px solid var(--color-primary);
    @include gridLayout(150px , 1rem);

    .links{
      // max-width: 200px;
      // margin: 0 auto;
      .footer-links-head{
        color: var(--color-dark-grey) !important;
        font-size: 1.3rem;
        &:last-of-type{
          margin-top: 1rem;
        }
      }
      p{
        margin: 0.5rem 0;
        a{
          color: var(--color-dark-grey);
          font-weight: 500;
          font-size: 0.9rem;
          line-height: 1.4;
          &:hover{
            color: var(--color-dark-grey);
            text-decoration: underline;
          }
        }
      }
    }
  }


  &__contact-footer {
    padding: 1rem;
    margin-left: 0rem;
    max-width: 200px;
    @media (max-width:1140px) {
      text-align: center;
      max-width: unset;
      display: grid;
      place-content: center;
      grid-column-start: 1;
      grid-column-end: -1;
    
  }
    h3{
      
      font-size: 1rem;
      line-height: 1.3;
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;
      margin: 0.5rem 0;
      color: var(--color-dark-grey);
    }
  
    p{
     
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.3;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    margin: 0.5rem 0;
    color: var(--color-dark-grey);
  
    a{
      color: var(--color-primary);
      margin-left: 5px;
     }
    }
  
    .social-icons{
      @include gridLayout(30px , 1.2rem);

      @media (max-width:1140px) {
      
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        p{
          margin:0 0.7rem ;
        }
      
    }

      p{
        // margin: 0 0.7rem;
        margin-top: 1rem;
        &:first-of-type{
          margin-left: 0;
  
        }
      }
    }
  }
}


.social-icons {
  display: flex;
  i{
    color: var(--color-dark-grey);
  }
  p:hover a i{
    transform: scale(1.1);
    color: var(--color-primary);
  }
}
