:root {
  --sidebar: #1a1a26;
  --btn-bg: #2c2d31;
  --text-start: #585495;
  --text-end: #b24ebe;

  --box-shadow: 0px 0px 10px 8px #049adc;
  --shadow-inset: 0px 0px 8px 5px #8de2ff inset;

  /* blue */
  --blue-inner-shadow: 0px 0px 5px 4px #00b4ff inset;
  /* outer box-shadow:0px 0px 16px 16px #00b4ff ; */

  /* Pink */
  --pink-outer-box-shadow: 0px 0px 10px 8px #be0072;
  /* --pink-outer-box-shadow:0px 0px 10px 8px #be0072 ; */
  --purple-text: rgb(156, 150, 248);
  --pink-head-text: rgb(254, 139, 224);
}

#nitro-nft-miner .top-links {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /* max-width: 80em;
  margin: auto; */
  z-index: 1;
}

.gradient-box {
  background: hsla(244, 28%, 46%, 1);

  background: linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );
  min-width: 150px;
  border-radius: 25px;
  padding: 2px;
}

.gradient-text {
  background: hsla(244, 28%, 46%, 1);

  background: linear-gradient(
    45deg,
    rgb(113, 109, 161) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );
  /* background: var(--text-end); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.box-det {
  margin: 1.9rem 0;
  /* display: block; */
}

.field {
  background: var(--sidebar);
  /* max-width: 250px; */
  border-radius: 25px;
  padding: 10px;
  text-align: center;
}

.field.active {
  background-color: transparent;
}

.border-field {
  margin-bottom: 0;
  max-width: 200px;
  font-size: 0.9rem;
}

/* NITRO HERO SECTIOn */
.nitro-hero-section {
  display: flex;
  margin-top: 2rem;
}

.nitro-img {
  flex: 0.6;
  border: 1px solid #fff;
  border-radius: 20px;
  box-shadow: var(--box-shadow), var(--shadow-inset);
  margin: 0 1rem;
}

.nitro-img img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.nitro-text {
  flex: 1.3;
  margin-left: 2.3rem;
}

.nitro-text h1 {
  margin-bottom: 0;
  font-size: 2.4rem;
}

/* .nitro-text h5 {
  margin-left: 0.5rem;
} */

.nitro-text p {
  color: #ccc;
  max-width: 700px;
}

.nitro-text-inside-section {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.four-link {
  flex: 0.5;
  display: flex;
  flex-direction: column;
}

.four-link a {
  display: inline-block;
  margin: 0.8rem 0;
  padding: 0.6rem;
  border: 1px solid var(--text-start);
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
}

.four-link a img {
  width: 40px;
  height: 40px;
  margin-right: 1rem;
}

.four-link a svg {
  width: 70px;
  height: 45px;
  margin-right: 1rem;
}

.four-link a:hover {
  background: linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );
  color: #fff;
}

.four-link a:hover + .four-link a svg {
  fill: #fff;
}

.four-link a:hover svg path {
  fill: #fff !important;
  stroke: #fff !important;
}

.full-content-box {
  /* background: red; */
  flex: 1;
  display: flex;
  justify-content: center;

  /* max-width: 300px; */
  /* ff 3.6+ */
  /* background: -moz-radial-gradient(circle at 52% 49%,
      rgba(52, 27, 84, 1) 30%,
      rgba(22, 29, 48, 1) 58%);

  safari 5.1+,chrome 10+
  background: -webkit-radial-gradient(circle at 52% 49%,
      rgba(52, 27, 84, 1) 30%,
      rgba(22, 29, 48, 1) 58%);

  opera 11.10+
  background: -o-radial-gradient(circle at 42% 49%,
      rgba(52, 27, 84, 1) 30%,
      rgba(22, 29, 48, 1) 58%);

  ie 10+
  background: -ms-radial-gradient(circle at 42% 49%,
      rgba(52, 27, 84, 1) 30%,
      rgba(22, 29, 48, 1) 58%);

  global 92%+ browsers support
  background: radial-gradient(circle at 50% 49%,
      rgba(52, 27, 84, 1) 30%,
      rgba(22, 29, 48, 1) 58%); */

  background: transparent !important;
}

.full-content-box div {
  position: relative;
  border: 1px solid var(--text-start);
  border-radius: 20px;
  padding: 1rem;
  text-align: center;
  width: 300px;
  box-shadow: 0 2px 1px 1px #000;
}

.full-content-box div img {
  width: 75px;
  height: 95px;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}

.light-top,
.light-bot {
  position: absolute;
  background: radial-gradient(
    circle at 50% 49%,
    rgba(52, 27, 84, 1) 10%,
    rgba(22, 29, 48, 1) 38%
  );
}



@media (max-width: 1108px) {
  .nitro-img {
    margin: 0;
  }

  .nitro-text {
    flex: 1.3;
    margin-left: 2rem;
  }

  .nitro-text h1 {
    margin-bottom: 0;
    font-size: 2.5rem;
  }

  .nitro-text-inside-section {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .four-link {
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
  }

  .four-link a {
    margin: 1rem;
    min-width: 200px;
  }

  .full-content-box div {
    width: 100%;
  }

  .row-style {
    flex-direction: column;
  }
}

@media (max-width: 982px) {
  .nitro-hero-section {
    flex-direction: column;
  }

  .nitro-text {
    margin-top: 2rem;
    margin-left: 0;
    margin-right: 0;
  }

  .four-link {
    flex-direction: column;
  }

  .four-link a {
    margin: 1rem 0;
  }

  .full-content-box {
    margin-top: 1.5rem;
  }
}



/*New PENDING PAGE  */
.pending-one .nitro-img {
  flex: 0.7;
  border: 1px solid #fff;
  border-radius: 20px;
  box-shadow: var(--pink-outer-box-shadow), var(--shadow-inset);
  margin: 0 1rem;
  max-width: 310px;
  max-height: 310px;
}

.pending-one .nitro-img img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

/* .nitro-text.small-width { */
/* flex: 1; */
/* } */

.nitro-text.small-width h1 {
  font-size: 3.5rem;
  text-align: left;
  margin-left: 0;
  padding-top: 0;
}

.line {
  height: 3px;
  width: 120px;
  background-color: var(--pink-head-text);
  margin-bottom: 1.5rem;
}

.nitro-text.small-width p {
  max-width: 550px;
  color: #fff;
}

.nitro-text.small-width h5 {
  margin-left: 0;
}

.max-width-40em {
  max-width: 30em;
}

.pink-text {
  color: var(--pink-head-text) !important;
}

.purple-text {
  color: var(--purple-text);
  color: rgb(132, 127, 201) !important;
  font-size: 1.1rem;
  font-weight: 600;
}

.nitro-text.small-width .purple-text {
  margin: 0.5rem 0;
}

.text-white {
  color: #fff;
}

@media (max-width: 982px) {
  .breadcumb .mainbreadcumb h1 {
    font-size: 2.3rem;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mainbreadcumb {
    margin: 0;
    padding: 0 !important;
  }

  #nitro-nft-miner .top-links {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    max-width: 700px;
    overflow-x: scroll;
    z-index: 1;
    /* display: none; */
  }

  .nitro-text.small-width h1 {
    font-size: 2.3rem;
    text-align: left;
    margin-left: 0;
  }

  #nitro-nft-miner {
    padding-top: 0;
  }

  .nitro-text.small-width .purple-text {
    margin: 0.5rem 0;
  }
}

@media (max-width: 769px) {
  /* #nitro-nft-miner {
    padding-top: 25px;
  } */

  .top-links .gradient-box {
    min-width: 75px !important;
    border-radius: 5px !important;
    height: 54.6px;
    display: grid;
  }

  .top-links .field {
    padding: 10px 0 !important;
    border-radius: 5px !important;
  }

  .top-links .border-field {
    font-size: 0.8rem;
    max-width: 65px;
  }

  .top-links .box-det:last-of-type .gradient-box .field .border-field {
    max-width: 43px;
    overflow-wrap: break-word;
  }
}

@media (max-width: 420px) {
  #nitro-nft-miner .top-links:not(.top-links.hit-tab) {
    justify-content: flex-start;
  }

  /* .hit-tab {
    justify-content: space-between;
  } */
}

/* My Collection Styles */
.collection-boxes .nft__item {
  padding: 0;
  border: 0;
  box-shadow: none;
  background-color: transparent !important;
}

.collection-boxes .nft__item_wrap {
  border: 1px solid var(--pink-head-text);
  box-shadow: var(--blue-inner-shadow);
  border-radius: 9px;
  transition: all 0.2s ease-in-out;
}

.collection-boxes .nft__item_preview {
  opacity: .95;
}

.collection-boxes .nft__item_wrap {
  position: relative;
  /* overflow: hidden; */
}

/* 
      Sold Out
*/
.collection-boxes .nft__item.sold-out-nft {
  box-shadow: none;
  border-color: rgba(111, 107, 107, 0.5);
}

.collection-boxes .nft__item.sold-out-nft:hover {
  box-shadow: none !important;
}

.collection-boxes .nft__item.sold-out-nft .nft__item_wrap {
  border-color: rgba(111, 107, 107, 0.5);
}
.collection-boxes .nft__item.sold-out-nft .nft__item_preview {
  opacity: 0.9;
}

.collection-boxes .nft__item.sold-out-nft:hover .nft__item_wrap {
  opacity: 0.9;
  box-shadow: none;
}

.collection-boxes .nft__item.sold-out-nft .nft__item_wrap #iconclick {
  display: none;
}

.sold-mark {
  display: none;
}

.sold-mark img {
  transform: scale(0.8);
}

.collection-boxes .nft__item.sold-out-nft .nft__item_wrap .sold-mark {
  position: absolute;
  inset: 0;
  color: #474754;
  font-size: 3rem;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
  margin: 0;
  display: grid;
  place-content: center;
  overflow: hidden;
  font-weight: 900;
  letter-spacing: 6px;
  border-color: none;
}

.collection-boxes .nft__item.sold-out-nft .nft__item_wrap .sold-mark span {
  display: block;
  transform: rotate(321deg);
  border: 10px solid rgba(111, 107, 107, 0.5);
  padding: 1rem 10rem;
  border-left: 0;
  border-right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: multiply;
}

.collection-boxes .nft__item_wrap #iconclick {
 
  display: inline-block;
  width: 60px;
  height: 60px;
  font-weight: 600;
  color: #fff;
  font-size: 1.2rem;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  padding: 0.5rem;
  cursor: pointer;
}

.collection-boxes .nft__item_info p.purple-text {
  font-weight: 400;
}

.collection-boxes .nft__item:hover .nft__item_wrap {
  box-shadow: var(--box-shadow), var(--shadow-inset);
}

.collection-boxes .nft__item:hover .nft__item_preview {
  opacity: 1;
}

/* .collection-boxes .nft__item:hover .nft__item_info p {
  color: var(--color-dark-grey);
} */

.collection-boxes .nft__item:hover p.purple-text {
  color: #ccc;
}
.nft__item.mobileview .nft__item_wrap {
  box-shadow: var(--box-shadow), var(--shadow-inset);
}

.nft__item.mobileview .nft__item_preview {
  opacity: 1;
}

.nft__item.mobileview .nft__item_info p {
  color: #fff !important;
}

.nft__item.mobileview p.purple-text {
  color: #ccc;
}

/* SOLD NFT UI FIX */
.sold-nfts-marketplace .nft__item_wrap a {
  /* height: 392px; */
  /* height: 392px; */
  object-fit: cover;
}

.sold-nfts-marketplace .nft__item_wrap a img {
  height: 100% !important;
  width: 100%;
  object-fit: cover;
}

/* @media (min-width: 982px) {
 #iconclick {
  display: none !important;
 }
} */
#iconclick {
  display: none !important;
}
