.backdrop {
  width: 100vw;
  height: 89vh;
  position: fixed;
  inset: 0;
  top: 90px;
  /* left: 50%; */
  /* transform: translate(-50% , -50%); */
  z-index: 999 !important;
  /* display: grid;
  place-content: center; */
  /* display: none; */
  background-color: rgba(22, 29, 48, 0.5);
}

.modal-container {
  width: 90%;

  margin: auto;
  max-width: 25em;
  /* max-height: calc(100vh - 85px); */
  height: calc(100vh - 85px);
  position: fixed;
  right: 0;
  top: 85px;
  /* top:50%; 1
  /* left: 50%; */
  /* transform: translate(-50% , -50%); */
  z-index: 9999 !important;
  /* background-color: rgba(0, 0, 0, 1); */
  background-color: #00153e;
  background-color: #161d30;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 5px 20px;
  overflow: auto;
}
/* 
.close-modal > img {
  width: 100%;
  height: auto;
} */
.close-modal {
  text-align: right;
}
.close-modal i {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.modal-content {
  background: none;
}
.modal-container button {
  outline: none;
  border: none;
  background: none;
}
.modal-content {
  border: none;
}
.select-items select {
  padding: 8px 12px;
  /* border: 1px solid #ccc; */
  background: #222a42;
  color: #fff;
  border-radius: 3px;
  margin: 2rem 0;
  margin-top: 1.2rem;
}
.select-items select option {
  color: #fff;
}

.btn-modal {
  /* margin-top: 2rem; */
}
.btn-modal > * {
  display: block;
  text-align: center;
}
.btn-modal a {
  color: var(--color-dark-grey);
  margin-bottom: 1rem;
  font-weight: 600;
}
.btn-modal p {
  color: var(--color-dark-grey);
}
.approve-modal,
.Pay-now-modal {
  padding: 0.8rem 1rem;
  border-radius: 5px;
  width: 90%;
  margin: 0.5rem auto;
  font-weight: 600;
}
.approve-modal {
  background: hsla(244, 28%, 46%, 1);

  background: linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  ) !important;
  /* background: #666 !important; */
  color: #fff;
}
.Pay-now-modal {
  color: #fff;
  background: hsla(244, 28%, 46%, 1);

  background: linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  ) !important;
  /* background: #0057D8 !important; */
}
.wallets {
  margin-bottom: 1.2rem;
}
.wallets img {
  width: 25px;
  height: 25px;
}
.wallets p {
  color: var(--color-dark-grey);
  margin-bottom: 0;
  margin-left: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.wallets > div {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(26, 26, 26);
  padding: 0.5rem;
}
.modal-content h4 {
  margin: 1rem 0;
  font-size: 1.1rem;
}

.slide-left {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-left {
  0% {
    right: -200px;
  }
  100% {
    right: 0;
  }
}
.one-section-payment {
  border-bottom: 2px solid #ccc;
  padding: 1rem 0;
}
.two-btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 1.5rem 0;
}
.bordered-button {
  border: 2px solid !important;
  border-radius: 5px !important;
  min-width: unset;
  padding: 10px 5px;
}
.bordered-button:first-of-type {
  border-color: #605399 !important;
}
.bordered-button:last-of-type {
  border-color: #a650b8 !important;
}
.bordered-button.purple {
  border-color: #a650b8 !important;
}
/* #pay-now-btn.pay-now-small{
  min-width: ;
} */
/* button {
  transform: scale(1);
}
button:focus {
  transform: scale(1.03);
} */

.btn-modal h5 {
  margin-top: -1.5rem;
}

.apply-promo {
  display: grid;
  width: 100%;
  grid-template-columns: 5fr 2.3fr;
  grid-gap: 20px;
}
.apply-promo input {
  margin: 0;
  border: var(--primary-border);
  color: var(--color-dark-grey);
  font-weight: 500;
  border-radius: 5px;
}
.apply-promo input::placeholder {
  color: var(--color-dark-grey);
}
.apply-promo button {
  color: #fff;
}
.apply-promo input,
.apply-promo button {
  padding: 5px 5px;
}
.borded-tb {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.light-weight {
  font-weight: 300 !important;
}
@media (max-width: 450px) {
  .modal-content .dfjb p {
    padding: 0;
  }
}

@media (max-width: 1350px) {
  .modal-container {
    /* padding  -bottom: 5rem !important; */
    overflow: auto;
  }
}
.less-padding-button {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.line-height1 {
  line-height: 1;
}
