


.Edit-profile{
  // background-color: red;
  background-color: var(--white);
  .profile{
    p{
      color: var(--color-dark-grey);
      a{
        color: inherit;
      }
    }
    &-box{
      h6{
        &:last-of-type{
          opacity: 0.8;
          margin-bottom: 1.2rem;
        }
      }
    }
    &-section{
      
      max-width: 35rem;
      margin: 1rem auto;
      min-height: 70vh;
    }
    &-image{
      background-color: var(--color-primary);
      color: var(--white);
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: grid;
      place-content: center;
      margin-bottom: 1.5rem;
      h2{
        color: var(--white);
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
  }

  input{
    border: var(--light-border) !important;
  }
}
.profile-box {
  /* margin-top: 3rem; */
  /* background: red; */
  padding: 20px;
  text-align: center;

}

.profile-box h1 {
  margin-top: 1.3rem;
  margin-bottom: 0;
  font-size: 2.1rem;
  color: var(--color-dark-grey);
}

.profile-image {
  width: 230px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50%;
}

.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profile-box p {
  color: #ccc;
  font-size: 1rem;
}

.btns-profiles .btn-submit {
  display: block;
  margin: 0.9rem 0;
  border-radius: 50px;
  padding: 0.7rem 0;
  width: 100%;
}
