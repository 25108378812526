*,*::before, *::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
  // font-size: 62.5%;
}

body{
  background-color: var(--body-bg) !important;
  font-family: var(--font) !important;
  accent-color: var(--color-primary) !important;
}
li {
  list-style: none;
}

a {
  text-decoration: none !important;
  color: var(--color-dark-grey);
}
p{
  line-height: 1.6;
}

.color-primary{
  color: var(--color-primary) !important;
}
.color-dark-grey{
  color: var(--color-dark-grey) !important;
}


input,select, textarea{
  border: var(--light-border);
  color: var(--color-dark-grey);
}


// P
.PT-90px{
  padding-top: 130px;
}