
:root {
  --sidebar: #1a1a26;
  --btn-bg: #2c2d31;
  --text-start: #585495;
  --text-end: #b24ebe;

  --box-shadow: 0px 0px 10px 8px #049adc;
  --shadow-inset: 0px 0px 8px 5px #8de2ff inset;
}
#nitro-nft-miner .top-links {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  cursor: pointer;
}

#gradient-text {
  margin-top: 0;
  font-weight: 700;
  text-align: left;
  margin-left: 0;
  padding-top: 0;
}

#payment-btns {
  width: 50%;
  flex-direction: column;
}
#pp-desc {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
}

#nitro-nft-miner {
  padding-top: 0;
}

#order-table {
  /* background-color: var(--sidebar-bg); */
  border-radius: 15px;
  font-size: 1rem;
  margin-top: 1rem;
}
#order-table th,
#order-table td {
  padding: 15px 0;
}


.gradient-box {
  background: hsla(244, 28%, 46%, 1);

  background: linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );
  min-width: 150px;
  border-radius: 50px !important;
  padding: 2px;
}
.gradient-text {
  background: hsla(244, 28%, 46%, 1);

  background: linear-gradient(
    45deg,
    rgb(113, 109, 161) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );
  /* background: var(--text-end); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.box-det {
  margin: 1.9rem 0;
  /* display: block; */
}

.field {
  background: var(--sidebar);
  /* max-width: 250px; */
  border-radius: 50px !important;
  padding: 10px;
  justify-content: center !important;

  text-align: center;
}
.field.active {
  background-color: transparent;
}
.border-field {
  margin-bottom: 0;
  max-width: 200px;
  font-size: 0.9rem;
}

/* NITRO HERO SECTIOn */
.nitro-hero-section {
  display: flex;
  margin-top: 2rem;
}
.nitro-img {
  flex: 0.6;
  border: 1px solid #fff;
  border-radius: 20px;
  box-shadow: var(--box-shadow), var(--shadow-inset);
  margin: 0 1rem;
}
.nitro-img img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}
.nitro-text {
  flex: 1.3;
  margin-left: 2.3rem;
}
.nitro-text h1 {
  margin-bottom: 0;
  font-size: 2.5rem;
}
.nitro-text h5 {
  margin-left: 0rem;
}
.nitro-text p {
  color: var(--color-dark-grey);
  max-width: 700px;
}
.nitro-text-inside-section {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}
.four-link {
  flex: 0.5;
  display: flex;
  flex-direction: column;
}
.four-link a {
  display: inline-block;
  margin: 0.8rem 0;
  padding: 0.6rem;
  border: 1px solid var(--text-start);
  border-radius: 50px;
  color: var(--color-dark-grey);
  font-weight: 600;
}
.four-link a img {
  width: 40px;
  height: 40px;
  margin-right: 1rem;
}
.four-link a svg {
  width: 70px;
  height: 45px;
  margin-right: 1rem;
}
.four-link a:hover {
  background: linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );
  color: #fff;
}
.four-link a:hover + .four-link a svg {
  fill: #fff;
}
.four-link a:hover svg path {
  fill: #fff !important;
  stroke: #fff !important;
}
.full-content-box {
  /* background: red; */
  flex: 1;
  display: flex;
  justify-content: center;

  /* max-width: 300px; */
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 52% 49%,
    rgba(52, 27, 84, 1) 30%,
    rgba(22, 29, 48, 1) 58%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 52% 49%,
    rgba(52, 27, 84, 1) 30%,
    rgba(22, 29, 48, 1) 58%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 42% 49%,
    rgba(52, 27, 84, 1) 30%,
    rgba(22, 29, 48, 1) 58%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 42% 49%,
    rgba(52, 27, 84, 1) 30%,
    rgba(22, 29, 48, 1) 58%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 50% 49%,
    rgba(52, 27, 84, 1) 30%,
    rgba(22, 29, 48, 1) 58%
  );
}
.full-content-box div {
  position: relative;
  border: 1px solid var(--text-start);
  border-radius: 20px;
  padding: 1rem;
  text-align: center;
  width: 300px;
  box-shadow: 0 2px 1px 1px #000;
}
.full-content-box div img {
  width: 75px;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}
.light-top,
.light-bot {
  position: absolute;
  background: radial-gradient(
    circle at 50% 49%,
    rgba(52, 27, 84, 1) 10%,
    rgba(22, 29, 48, 1) 38%
  );
}

@media (max-width: 1199px) {
  .nitro-img {
    flex: 1 !important;
  }
  .nitro-text-inside-section {
    flex-direction: column;
  }
  .four-link {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2px;
  }
  .full-content-box div {
    width: 100% !important;
  }
}
@media (max-width: 1108px) {
  /* .nitro-img {
  margin: 0;
 }

 .nitro-text {
  flex: 1.3;
  margin-left: 2rem;
 }
 .nitro-text h1 {
  margin-bottom: 0;
  font-size: 2.5rem;
 }

 .nitro-text-inside-section {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 } */

  /* .four-link {
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
 } */
  /* .four-link a {
  margin: 1rem;
  min-width: 200px;
 }
 .full-content-box div {
  width: 100%;
 }
 .row-style {
  flex-direction: column;
 } */
}

@media (max-width: 982px) {
  #payment-btns {
    width: 100%;
    margin-top: 1.2rem;
    text-align: center;
    flex-direction: column;
  }
  #payment-btns > a {
    margin: 0.5rem 0.4rem;
  }
  .nitro-hero-section {
    flex-direction: column;
  }
  .nitro-text {
    margin-top: 2rem;
    margin-left: 0;
    margin-right: 0;
  }

  .four-link {
    flex-direction: column;
  }
  .four-link a {
    margin: 1rem 0;
  }
  .full-content-box {
    margin-top: 1.5rem;
  }

  .field {
    padding: 7px;
  }
  .top-links {
    margin-bottom: 1.5rem;
  }
  .top-links .box-det {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .top-links .gradient-box {
    min-width: 115px;
  }
  .border-field {
    font-size: 0.75rem;
  }
  #product-detail-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.btn-connect-wallet {
  /* width: max-content; */
  text-align: right;
}
.gradient-button {
  background: hsla(244, 28%, 46%, 1);

  background: linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(244, 28%, 46%, 1) 0%,
    hsla(294, 46%, 53%, 1) 100%
  );
  min-width: 160px;
  border-radius: 50px !important;
  padding: 8px 10px;
  outline: none;
  border: none;
  color: #fff;
}
.margin-10px {
  margin: 0 0 10px !important;
}
.mx-auto {
  margin: 0 auto !important;
}
.bold-text {
  font-weight: 600;
}

.content-head-pending-payment {
  margin-top: 2rem;
  margin-bottom: -5px;
  font-size: 1.4rem;
}

@media only screen and (min-width: 765px) and (max-width: 982px) {
  .jumbotron {
    padding-top: 90px;
  }
}
@media (max-width: 550px) {
  .four-link {
    display: grid !important;
    grid-template-columns: 1fr;
    /* grid-gap: 2px; */
  }
}
/* //982 to 1199 px */
/* @media only screen and (max-width: 1199px) and (min-width: 982px) {
 .nitro-img {
  flex: 1 1 !important;
 }
} */
