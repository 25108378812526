.emi-screen {
  margin: 1.5rem 0;
}

.emi-estimate {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 20px;
  padding: 0.5rem 1.5rem;
}

.emi-estimate-box {
  background: #07103b;
  /* border: 1px solid #FFFFFF; */
  border-radius: 11px;
  margin: 1rem 0;
  border-radius: 10px;
  padding: 1.5rem;
}

.gradient-button-2 {
  margin-top: 1rem;
  padding: 0.6rem 1rem;
  /* min-width: 150px; */
  outline: none;
  border: none;
  background: var(--gradient-bg);
  /* border: 1px solid #ccc; */
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.grid-center {
  display: grid;
  place-content: center;
  text-align: center;
}

.fw-600 {
  font-weight: 600;
}

/* .bold {
    font-weight: bold;
} */
.emi-estimate-box p.light {
  font-size: 1.2rem;
}

.fw-900 {
  font-weight: 900;
}

.fz-big {
  /* font-size: clamp(2.5rem, 5vw, 1.5rem); */
  font-size: clamp(1.33625rem, 1.2247rem + 0.6892vw, 3.125rem);
}

.emi-all-tier {
  padding: 1.5rem;
}

.emi-all-tier .de-table.table-rank td {
  padding: 10px 0;
  font-weight: 400;
}

.emi-all-tier .de-table.table-rank th {
  padding: 15px 0;
  font-weight: 600;
}

.emi-all-tier table th {
  font-size: 0.9rem;
}

.emi-accordian-button {
  padding: 0.8rem 1rem;
  margin-top: 1.2rem;
}

.emi-accordian-content {
  display: block;
}

.emi-accordian-content.collapsed {
  display: none;
}

.cust-padding {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

/* OUTSTANDING PAYMENT */
.outstanding-payment {
  padding: 1.5rem;
}

.outstanding-payment .field-outstanding {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: transparent;
  margin: 1rem 0;
}

.outstanding-payment select {
  background-color: #07103b;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: #fff;
}

.outstanding-payment input {
  background-color: #07103b;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: #fff;
}

.outstanding-payment select option {
  color: #fff;
}

@media (max-width: 768px) {
  .emi-estimate {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
    padding: 0.5rem 1.5rem;
  }

  .emi-estimate-box:last-of-type {
    order: -1;
    margin-bottom: 0;
  }

  .emi-estimate-box {
    padding: 0.8rem;
  }
}

@media (max-width: 540px) {
  .emi-estimate-box h3 {
    font-size: 1rem;
  }

  .fz-big {
    font-weight: 600;
  }

  .emi-estimate-box p.light {
    font-size: 0.95rem;
  }
}
