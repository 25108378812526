.iot-order-summary{ 
  & > div{
    // @include whiteBoxStyle;
    // border: var(--light-border);
    color: var(---color-dark-grey);
  }
}

.iot-country-dropdown {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  font-weight: 600;
}

/* .iot-country .react-select__control{
    background-color: rgba(255, 255, 255, 0.1) !important;
    border-color: #404972 !important;
    color: #fff !important;
} */

.iot-country .react-select__placeholder {
  color: #fff !important;
}

/* buttons */
.iot-btn-blue {
  display: inline-block;
  padding: 0.9rem 1.5rem;
  background: #4361ee;
  color: #fff;
  text-align: center;

  font-size: 0.9rem;
}

/* Form Step */

.form-steps-iot {
  margin: 2rem 0;
  position: relative;
}
.form-steps_links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-step__link {
  display: inline-block;
  text-align: center;
  padding: 1rem 1.2rem;
  background: var(--light-grey);
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.2s linear;
  border-radius: 5px;
}
.form-step__link:first-of-type,
.form-step__link.active {
  background: var(--color-primary);
  color: var(--white);
  /* background: #4361EE;
  color: #fff; */
}
.form-step__link.active {
  transform: scale(1.1);
}

/* , .form-step__link:first-of-type  */
.form-step__link.completed {
  background: var(--color-primary);
  color: var(--white);
  transform: scale(1);
}
.form-step__link:not(.active):hover {
  color: var(---color-dark-grey) !important;
  filter: brightness(90%);
}
.form-steps-iot .absolute-line {
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 3px;
  background: var(--light-grey);
  transition: 0.3s ease-in-out;
}
.form-steps-iot .absolute-line::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease-in-out;

  width: var(--fill-blue);
  height: 3px;
  background: var(--color-primary);
}

.card-ship {
  position: relative;
  width: 100%;
  height: auto;
  padding: 30px;
  font-size: 16px;
  transition: 0.5s ease;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  background-color: var(--white);
  color: var(---color-dark-grey) !important;
}
/* Checkout page css */

.iot-cart-checkout {
  display: flex;
  flex-direction: column;
  --form-completed: linear-gradient(94.62deg, #b137f2 3.99%, #4f4dd1 94.57%);
  --form-active: linear-gradient(90deg, #e0e8f0 0%, #ffffff 100%);
  --form-unfinished: linear-gradient(90deg, #adadad 0%, #e4e4e4 100%),
    linear-gradient(90deg, #56ab2f 0%, #a8e063 100%);
}

.iot-cart-checkout > p {
  margin-top: auto;
}

.iot-cart-checkout .btn-white {
  display: inline-block;
  padding: 0.3rem 1rem;
  border-radius: 0;
  background: #fff;
  color: #000;
  outline: none;
  border: none;
  transition: 0.2s linear;
}

.iot-cart-checkout .btn-white.secondary {
  display: inline-block;
  padding: 0.1rem 1rem;
  border-radius: 0;
  background: transparent;
  color: #fff;
  outline: none;
  transition: 0.2s linear;
}

.iot-cart-checkout .btn-white.secondary:hover {
  text-decoration: underline;
}

.iot-cart-checkout .mx-240 {
  max-width: 240px;
}

.iot-cart-checkout .mt-3rem {
  margin-top: 3rem;
}

.iot-cart-checkout .btn-white:hover {
  filter: brightness(90%);
}

.iot-cart-checkout .bb-head {
  border-bottom: var(--light-border);
  padding-bottom: 0rem;
  margin-bottom: 1rem;
}

.iot-country-section {
  max-width: 250px;
  margin-left: auto;
}
.react-select-dropmenu {
  height: unset !important;
}
/* .react-select__option{
    margin-top: 1rem !important;
} */
.react-select__option,
.react-select__option--is-focused,
.react-select__option--is-selected {
  background: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
}

.react-select__option--is-focused {
  background-color: #deebff !important;
  color: black !important;
}

.react-select__option--is-selected {
  background-color: rgb(107, 107, 255) !important;
  color: white !important;
}
.iot-label-radio .ant-form-item-label > label {
  color: var(---color-dark-grey);
}

.iot-next-back-button {
  display: inline-block;
  background: #3485ff;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 0.7rem 0.9rem;
  text-align: center;
  color: #fff;
  transition: 0.3s linear;
}
.iot-next-back-button:focus {
  transform: scaleX(1.03) scaleY(1.02);
}
.iot-next-back-button:active {
  transform: scale(1);
}
.iot-next-back-button.br-none {
  border-radius: 0;
}
.iot-cancel-button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0 0.5rem;
}

.iot-counter-button {
  display: inline-block;
  background: #fff;
  border-radius: 0;
  border: none;
  outline: none;
  padding: 0.2rem 0.8rem;
  text-align: center;
  color: #000;
}
.iot-counter-button:hover {
  filter: brightness(90%);
}
.iot-btn-outlined {
  border-color: #fff;
}
.iot-cancel-button:hover {
  text-decoration: underline;
}
.list-unstyled > li {
  font-size: 1rem;
}
.font-root {
  font-size: 0.9rem;
}
.iot-cart-checkout hr {
  margin: 0 auto 1rem auto;
}
.iot-promo-form .ant-form-item-control-input-content {
  position: relative !important;
  /* display: flex !important;
    align-items: stretch !important;
    justify-content: stretch !important; */
}

.iot-promo-form input[type="text"]{
  border: 1px solid var(--color-primary);
  color: var(---color-dark-grey) !important;
  font-weight: 500;
  &::placeholder{
    color: var(---color-dark-grey) !important;
  }
}
.iot-promo-form .ant-row.ant-form-item {
  margin-bottom: 0 !important;
}
.iot-promo-form > .ant-form-item {
  width: 100%;
}
.iot-apply-promo-button {
  position: absolute;
  background-color: var(--color-primary);
  // border-color: var();
  right: 0;
  /* margin-left: 0.5rem; */
  /* height: 38px; */
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 101px;
  border-top-right-radius: 5px;
  padding: 0 1rem;
  font-weight: 500;
  border-bottom-right-radius: 5px;
  color:var(---color-dark-grey);
  /* border-radius: 5px; */
}
.fix-height-input {
  height: 32px !important;
}
.ship-address{
  color: var(---color-dark-grey) !important;

  input, textarea{
    border: var(--light-border) !important;

  }
  label{
    display: flex;
    align-items: flex-start;
    color: var(---color-dark-grey) !important;
    cursor: pointer;

    input[type="radio"] {
      width: 20px;
      height: 20px;
      margin-top: 0;
      margin-right: 10px;
    }
  }
}
.right-floated-btns{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > *{
    max-width: max-content;
  }
}
.ship-address .address-persnol {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
}
.ship-address .address-persnol > span {
  margin: 0 0.3rem;
}
.ship-address .badge-primary-light {
  background-color: var(--color-primary);
}
.ship-address .address-field {
  color: var(---color-dark-grey);
  line-height: 1.6;
}
.ship-add-new-address .ant-form-item {
  margin-bottom: 0 !important;
}
.ship-add-new-address label {
  color: var(---color-dark-grey);
  margin-bottom: 0.2rem;
}
.ship-add-new-address .form-field {
  margin: 0.8rem 0;
}
.ship-add-new-address :where(textarea, input, select, option)::placeholder {
  color: var(---color-dark-grey) !important;
}
.max-width-120px {
  max-width: 120px;
}
.ship-add-new-address select {
  padding: 4px 11px !important;
  background: transparent;
}
.ship-add-new-address select option {
  background-color: var(--white);
  color: var(---color-dark-grey);
  font-size: 0.95rem;
  line-height: 20px;
  padding: 3px 2px;
}
/* .ship-add-new-address select:first-of-type{
    color: #ccc !important;
} */
.card-ship table td {
  font-weight: 400;
  padding: 15px 0 !important;
}
.iot-my-orders {
  margin-top: 1rem;
}
@media (max-width: 736px) {
  .form-step__link {
    padding: 0.5rem 0.5rem;
    font-size: 0.65rem;
    letter-spacing: 1px;
  }
  .card-ship {
    padding: 0.9rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .card-ship.no-padding-in-mobile {
    padding: 0;
  }

  .card-ship table tbody tr {
    background: rgba(255, 255, 255, 0.1);
  }
  .ship-add-new-address .half-fields {
    grid-row-gap: 0;
  }

  .promo-code-iot {
    height: 32px;
    display: grid;
    place-content: center;
  }
}

/* View Details Popup */
.view-details-backdrop {
  // background: rgba(7, 16, 59, 0.5);
  background-color: rgba(63, 184, 225, 0.2);

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  min-height: 100vh;
  /* display: grid;
    place-content: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  /* overflow: auto; */
}

.view-details-popup {
  min-width: 28rem;
  width: 100%;
  max-width: 40rem;
  margin: 0 1rem;

  background-color: var(--white);
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  padding: 1.5rem;
  box-shadow: 0 5px 20px rgba(63,184,225, 0.7);
  color: var(---color-dark-grey);
  overflow: auto;
  /* position: absolute;
  top: 50%;
  left: 50%; */
  /* transform: translate(-50% , -50%); */
  /* margin-top: 10vh; */
  /* overflow: auto;
  max-height: 70vh; */
}

.view-details-backdrop.hide {
  display: none;
}
.view-details-popup .close-btn {
  font-weight: 600;
  margin-left: 1rem;
  cursor: pointer;
  font-size: 1.3rem;
}
.order-detail_popup {
}
.order-detail__head {
  padding: 0.5rem 0;
  border-bottom:  var(--light-border);

  margin-bottom: 1.5rem;
}

.orders-lists {
  max-height: 45vh;
  overflow: auto;
}
.order {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  grid-gap: 1rem;
  margin: 1.5rem 0;
}
.order-list .order-detail__image {
  width: 100%;
  max-height: 185px;
}
.order-detail__image img {
  width: 100%;
  height: 100%;
}

.order-list:not(:last-of-type) {
  margin-bottom: 1.2rem;
}
.order-list__item .text-big {
  font-weight: 500;
  font-size: 1.3rem;
  margin-bottom: 0rem;
}
.order-list {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-gap: 1rem 1.5rem;
}
.order-list__item li {
  font-size: 0.9rem;
  line-height: 1.3;
}
.track-order-btn {
  padding: 0.5rem 1.2rem;
  border-radius: 5px;
  margin-right: 0.5rem;
  background: linear-gradient(45deg, #b137f2, #4f4dd1);
}
