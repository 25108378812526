.my-account{
  min-height: calc(100vh - var(--top-gap));
  height: 100%;
  // // background-color: red;
  // background-image: url('../../../components/pages/images/images/ecosytemBackground.png');
  // background-image: url("../pages/images/images/ecosytemBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  margin-top: var(--top-gap);
  
  &-expanded-view{
    // background-color: var(--white);
    color: var(--color-dark-grey);
  }

  h1{
    color: var(--color-dark-grey) !important;
  }
  &__heading{
    padding-top: 1rem;
    @media (max-width:768px) {
      padding-bottom: 1.5rem;
      padding-top: 0;
    }
    @media (max-width:599px) {
      font-size: 1.5rem !important;
    }
  }
  .expanded-component{
    // background-color: var(--white);
    color: var(--color-dark-grey);
  }
}


.sidebar-floating {
  /* background-image: url('../pages/images/images/ecosytemBackground.png');
    background-size: cover;
    background-repeat: no-repeat; */
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: sticky;
  top: var(--top-gap);
  left: 10px;
  max-height: calc(100vh - var(--top-gap));
  background-color: var(--sidebar-bg);


  
 .logo {
  text-align: center;
  max-width: 180px;
}

.menu p {
  margin-bottom: 0;
}

:is(i) {
  color: var(--color-dark-grey);
}

.submenu > a,
.submenu > p {
  color: var(--color-dark-grey);
}
}

/* Grid area */
.expanded-view {
  display: flex;
  flex-direction: column;

  &__profileSection{
    display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  }
  &__profile {
    display: flex;
    align-items: center;
  }

  &profileDetails {
    display: flex;
    align-items: center;

    h6,a{
      color: #fff;
  text-align: right;
  margin-right: 0.3rem;
  margin-left: 0.5rem;
  font-size: 0.7rem;
    }
  }

}



a.menu{
  &:hover{
    color: var(--white);
    background-color: var(--color-primary);
  }
}

.menu {
  display: flex;
  align-items: center;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  margin: 0.5rem auto;
  cursor: pointer;
  color: var(--color-dark-grey);

  
  p{
    font-size: 1.1rem;
    font-weight: 600;
    img{
     margin-right: 0.5rem;
     filter: brightness(0);

    }
  }
  i{
    color: var(--color-dark-grey);
  }

  &:hover{
    background-color: var(--color-primary);
    color: var(--white);
    img{
      filter: brightness(1);
    }
    i{
      color: #fff;
    }
  }
  &.active{
    background: var(--color-primary);
    color: var(--white);
      p{
        img{
          filter: brightness(1);
        }
      }
      i{
        color: var(--white);
      }
  }
 
  .submenus {
    display: none;
    
    
  }
  &.active .submenus {
    display: block;
    a {
      display: block;
      width: 100%;
      color: var(--color-dark-grey);
    }
  }
}

.menus {
  margin-top: 2rem;
}


.logout {
  margin-top: auto;
  padding: 1rem;
  color: var(--color-dark-grey);
  font-weight: 600;
  a{
    color: inherit;
    font-weight: inherit;
  }
  &:hover{
    a{
      color: inherit;
    }
  }
  img{
    filter: brightness(0);
  }

}

.menu img,
.logout img {
  max-width: 20px;
}





/* Grid area */
.expanded-view {
  display: flex;
  flex-direction: column;
}

.expanded-view__profileSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.expanded-view__profile {
  display: flex;
  align-items: center;
}

#user-avatar {
  --profile-size: 50px;
  width: var(--profile-size);
  height: var(--profile-size);
  border-radius: 50%;
  border: 3px solid grey;
}

.expanded-view__profileDetails {
  display: flex;
  align-items: center;
}

.expanded-view__profileDetails :is(h6, a) {
  color: #fff;
  text-align: right;
  margin-right: 0.3rem;
  margin-left: 0.5rem;
  font-size: 0.7rem;
}

.btn-connect-wallet {
  cursor: pointer;
  display: inline-block;
  outline: none;
  border: none;
  background-color: #fff;
  color: #440bc9;
  padding: 0.7rem 1rem;
  height: auto;
  font-weight: 600;
  min-width: 150px;
  margin-right: 1.5rem;
  border-radius: 10px;
}

.order-details {
  height: 100%;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 1.5rem;
}

.order-details :is(h3, h4, h6, p, a) {
  color: #fff;
}

.bb {
  border-bottom: 1px solid #fff;
}

.pxy-1 {
  padding: 1rem;
}

.three-column > div {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  align-items: center;
}

.three-column h6 {
  font-weight: 500;
  font-size: 1rem;
}

.dfjb {
  display: flex;
  justify-content: space-between;
}

.dfac {
  display: flex;
  align-items: center;
}

.device__count {
  padding: 1rem 0;
}

.device__count h5 {
  margin-bottom: 0.23rem;
}

.device__count > div > p:first-of-type {
  font-size: 0.9rem;
}

.device__count > div > p:last-of-type {
  font-size: 1rem;
}

.apply-coupon {
  background-color: #08143e;
  padding: 1rem;
  border-radius: 10px;
}

.apply-coupon h4 {
  color: #fff;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.apply-coupon {
  margin: 1rem 0;
}

.apply-coupon > div {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 2.8fr 1.2fr;
  margin: 1rem 0;
}

.apply-coupon :is(input, button) {
  padding: 0.3rem 1rem;
  background-color: transparent;
  border: 1px solid rgb(170, 169, 169);
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
}

button {
  cursor: pointer;
}

.apply-coupon p {
  text-align: center;
  font-size: 0.8rem;
  padding: 0.5rem 0 1rem 0;
}

.pay-now-proceed {
  margin-bottom: 0;
  margin-top: auto;
  padding: 2rem 0 2rem 0;
  box-shadow: 0 0 0 1rem transparent;
  clip-path: inset(0 -1rem);
  /* background: red; */
  border-top: 1px solid #ccc;
}

.df {
  display: flex;
  flex-direction: column;
}

.pay-now-btn {
  padding: 0.8rem 1rem;
  min-width: 150px;
  outline: none;
  border: none;
  background-color: transparent;
  border: 1px solid #ccc;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.counter {
  display: flex;
  /* background: red; */
  justify-content: center;
  align-items: center;

  .minus,
 .plus {
  margin-bottom: 0;
  // /* background: #103453; */
  background-color: transparent;
  color: var(--color-dark-grey);
  font-size: 1.4rem;
  padding: 0 6px;
  border: 2px solid var(--color-primary);
  cursor: pointer;
  border-radius: 3px;
  line-height: 1;

  &:hover{
    transform: scale(1.03);
  }
  &:active{
    transform: scale(0.97);
  }
}
}

/* CHANGE STYLES */

#number-update {
  width: 40px;
  padding: 3px 5px;
  outline: none;
  /* border: 1px solid rgb(189, 189, 189); */
  border-radius: 2px;

  background: transparent;
  color: #fff;
  /* border: 1px solid #103453; */
  margin: 0 7px;
  text-align: center;
  font-size: 17px;
}

.price_div {
  /* ma */
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.qty {
  font-size: 20px;
  font-weight: 500;
}

.my-account img {
  width: 100%;
  height: 100%;
}

/* Hex updaTE */
.nfm-updation {
  padding: 2rem 1rem;
}

.nfm-updation > div:first-of-type {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  grid-gap: 15px;
}

.small {
  padding-top: 0.5rem;
  font-size: 85%;
}

/* .center {
    text-align: center;
} */

/* .right {
    text-align: right;
} */

.mr-4 {
  margin-right: 1.2rem;
}

/*  PAY WITH CRYPTO  */

.payment-method {
  --pdxy: 1rem;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  height: 100%;
}

.order_pay_method {
  overflow: hidden;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #08143e;
}

.order_pay_method_title {
  padding: var(--pdxy);
  font-size: 1.2rem;
  font-weight: 600;
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 1rem;
}

.order_pay_methods {
  padding: var(--pdxy);
}

.order_pay_methods > a img {
  --size: 20px;
  height: var(--size);
  width: var(--size);
  border-radius: 50%;
}

.order_pay_methods > a {
  padding: 1rem;
  margin: 1rem auto;
}

.order_pay_methods > a:hover {
  background-color: #981ede70;
  border-radius: 5px;
}

.order_pay_methods i {
  display: none;
}

.order_pay_methods > a.active {
  background-color: #981ede;
  border-radius: 5px;
}

.order_pay_methods > a.active i {
  display: block;
}

.order_pay_methods > a > div {
  display: flex;
  align-items: center;
}

.order_pay_methods > a > div img {
  margin-right: 0.4rem;
}

.order_pay_methods :is(i) {
  color: #fff;
}

.order_description {
  padding: 1rem 4rem;
  display: grid;
  grid-template-columns: 1fr 0.9fr;
  margin-top: 4rem;
}

.pay-method-section {
  max-width: 90%;
  padding-top: 1.5rem;
}

.pay-method-section p {
  line-height: 1.6;
}

.pay-method-section p:last-of-type {
  margin: 1rem 0;
}

.mt-4 {
  margin-top: 1.5rem;
}

.no-border {
  border: none;
}

.bill-detail-section {
  /* border-left: 1px solid rgb(202, 202, 202); */
  height: max-content;
  padding: 1.5rem;
}

.bill-detail-section h4 {
  color: var(--color-dark-grey);
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
}

.bill-detail-section p {
  line-height: 1.6;
  margin-bottom: 0.2rem;
}

.bill-detail-section .borded-tb {
  margin-top: 0.5rem;
  padding: 0.3rem 0;
  border: var(--light-border);
  border-left: none;
  border-right: none;
}

/* TEMP */
.submenu.show {
  margin-left: 1rem;
  display: block;
}

.submenu.show i {
}

.submenu {
  display: none;
}

.submenu a {
  margin: 0.5rem 0;
  margin-left: 1rem;
  display: block;
}

.menu.active ~ .submenu {
  display: block !important;
}

.menu.active .right i {
  transform: rotate(-90deg);
}

.submenu a {
  border-radius: 5px;
  padding: 5px 5px;
  margin: 0.2rem 0;
  margin-left: 1.5rem;
  color: var(--color-dark-grey);
  font-weight: 400;
}

.submenu a p::before {
  content: "";
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
}

.submenu a:hover {
  background-color: var(--color-primary);
  color: #fff;
}

.submenu a.active {
  background-color: var(--color-primary);
  color: var(--white);
}

/* TO FIX BROKEN STYLING */
#nitro-nft-miner {
  background-color: transparent;
}

.full-content-box {
  background-color: transparent;
}

.my-account h1:first-of-type {
  padding-top: 0;
}

.my-account .mainbreadcumb {
  padding-top: 0px;
}

.my-account .menus {
  padding-left: 0;
}

/* .my-account {
    height: calc(100vh - var(--top-gap));
    overflow: scroll;
    position: sticky;
    top: var(--top-gap);
    overflow: hidden;
    grid-template-columns: 1fr;
}

.sidebar-floating {
    position: sticky;
    top: var(--top-gap);
    left: 1rem;
    width: 300px;
    height: 100%;
}

.my-account-expanded-view {
    height: calc(100vh - var(--top-gap));
    padding-left: 320px;
    overflow: scroll;
} */

.close-sidebar {
  display: none;
}

.mobile-view-sidebar-open {
  display: none;
}

@media (max-width: 1028px) {
  .sidebar-floating {
    display: none;
  }

  .my-account {
    margin-top: var(--top-gap);
  }

  .my-account .jumbotron {
    padding: 0;
    margin-bottom: -15px;
  }

  .my-account .sorting {
    padding-top: 0;
  }

  .my-account {
    grid-template-columns: 1fr;
  }

  .mobile-view-sidebar-open {
    display: block;
    position: fixed;
    top: var(--top-gap);
    left: 0;
    background-color: var(--color-primary);
    color: var(--white);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transform: translateY(-20%);
    font-size: 1.5rem;
    padding: 1rem 0.5rem;
    z-index: 2;
    cursor: pointer;
  }

  .sidebar-floating {
    z-index: 2;
    left: -350px;
    border: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    transition: all 0.4s linear;
  }

  .sidebar-floating.active {
    position: fixed;
    left: 0;
    top: 90px;
    width: 300px;
    height: 100%;
    display: block;
    background-color: var(--sidebar-bg);
    overflow: auto;
  }

  .sidebar-floating.active .close-sidebar {
    text-align: right;
    display: block;
  }

  .my-account .menu {
    padding: 0.3rem 0.4rem;
    margin: 0.4rem auto;
  }

  .my-account .menu p {
    padding: 4px;
  }

  .close-sidebar i {
    font-size: 1.5rem;
  }
  .submenu .dfjb p {
    text-align: left;
    padding: 3px;
  }
}

/*  Desktop Sticky Solution */
@media (min-width: 1029px) {
  .my-account {
    position: sticky;
    width: 100%;
    height: 100vh;
    margin-top: var(--top-gap);
  }

  .my-account .my-account-expanded-view {
    overflow-y: auto;
    overflow-x: hidden;
    isolation: isolate;
  }

  .my-account .my-account-expanded-view::-webkit-scrollbar {
    display: none;
  }
}




// Payment BAr

.payment-bar {
  background-color: var(--sidebar-bg);
  color: var(--color-dark-grey);
  border-radius: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.5fr;
  margin-top: 3rem;
  position: relative;
  z-index: 1;

  > div{
    z-index: 1;
    padding: 20px;
    border-right: var(--light-border);

    h1 {
      text-align: center;
    }
    
    p {
      color: var(--color-dark-grey);
      font-weight: 600;
      /* text-align: center; */
      svg{
        fill: currentColor;
      }
    }


    &:last-of-type{
    border-right: none;

    }
  }

  .row-style {
    display: flex;
    justify-content: space-between;

    a{
      color: var(--color-dark-grey);
      display: inline-block;
      text-align: center;
      height: 40px;
      margin-left: 0.7rem;
      margin-top: 1.5rem;
    }
  }

  @media (max-width: 854px) {
   
    grid-template-columns: 1fr;

    > div {
      padding: 20px;
      border-right: none;
      border-bottom: var(--light-border);

      &:last-of-type{
        border-right: none;
      border-bottom: none;
      }
    }

  }
  
}

.payment-bar-info {
  padding-top: unset;
  margin-bottom: 1rem;
  span{
    display: block;
    text-align: left;
    text-decoration: line-through;
    font-size: 50%;
    visibility: hidden;
    line-height: 1;
    &.visible{
      visibility: visible;
    }
  }
}

.custom-nfm-calculator{
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 1rem;
  // flex-wrap: ;
}

.nfm-calculator{
  height: max-content;
  background-color: var(--white);
  border: var(--light-border);
  border-radius: 10px;
  padding: 1rem;
   h5{
    border-bottom: var(--light-border);
    padding-bottom: 0.5rem;

   }
   &__inputs{
      display: grid;
      grid-template-columns: repeat(auto-fit , minmax(100px, 1fr));
      grid-gap: 0.2rem 1rem ;
      align-items: center;
      margin: 0.8rem 0;
    p{
      margin-bottom: 0;
      font-size: .9rem;
      line-height: 1;
    }

    input, select{
      padding: 0.3rem 0.5rem;
      border: var(--light-border);
      color: var(--color-dark-grey) !important;
      border-radius: 5px;
    }
    input::placeholder{
      color: var(--light-grey);
    }
   }


}

// Pending Payment Page Screen
.pending-payment-page{
  display: flex;
  flex-direction: column;

  .pending-payments-nfm{
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 1rem;
  
    .order-details-nfm{
      @include whiteBoxStyle;
      color: var(--color-dark-grey);
      
    }

    @media (max-width:768px) {
      grid-template-columns: 1fr;
    }
  }

  h2{
    margin-top: 0;
  }

  .order-summary-nfm{
    margin-bottom: 1.2rem;
  }
}


//My Nest Page Screen
.My-nest-page{
  @include whiteBoxStyle;

  table{
    border-radius: 15px;
    overflow: hidden;
    tr{
      background-color: var(--white);

    }
    
  }
}

.My-activity-page{
  @include whiteBoxStyle;
}
.My-collection-page{
  @include whiteBoxStyle;
}
.iot-my-orders{
  @include whiteBoxStyle;
}
.Pending-orders-page{
  // @include whiteBoxStyle;
}
.Referral-page{
  @include whiteBoxStyle;
}
.Reward-page{
  @include whiteBoxStyle;
}
.analytics-page{
  // @include whiteBoxStyle;

}
