.cms-blog{


  &__dropdown{
    max-width: 400px;
    margin-left: auto;
    display: grid;
    align-items: center;
    grid-gap: 0 1rem;
    grid-template-columns: 150px 1fr;
  }
  &__detail{
    max-width: 55rem;
    margin: auto;
    display: grid;
    grid-template-columns:  1fr;
    // grid-template-columns: 2.5fr 1fr;
    grid-gap: 1rem;

    // @media (max-width:1060px) {
    // grid-template-columns:  1fr;
      
    // }
    & > *{
      padding: 0 1rem;

    }
  }
  &__left{

      figure{
        max-width: 95%;
        width: 100%;
        margin: .5rem auto;
        img{
          width: 100%;
        }
      }
  }
  &__title{
    text-align: left;
    margin-bottom: 0;
    padding: 0.5rem;
    // margin: 1rem auto;
    h1{
      margin-bottom: 0.2rem;
      font-weight: 500;
      
    }
  }
  &__right{
    // background-color: var(--color-primary);
  }
  &-page{
    max-width: 72rem;
    margin: 2rem auto;
    padding-top: var(--top-gap);

    li{
      list-style: disc !important;
    }
    @media (max-width:540px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .slick-list{
      position: relative;
    }
    
    .slick-slide{
      position: relative;
      
      &.slick-active{
        // padding: 0 0.5rem;
        // padding-right: 1rem;
      }
    }
    .slick-next , .slick-prev{
      position: absolute;
      z-index: 2;
    }
    .slick-next{
      right: -18px;
    }
    .slick-prev{
      left: -10px;
    }

    .wp-block-heading{
      @media (max-width:320px) {
        font-size: 25px;
        line-height: 35px;
      }
    }
    @media (max-width:540px) {
      .slick-next{
        right: 0;
      }
      .slick-prev{
        left: 0;
      }
    }
    .slick-prev::before{
      font-size: 1.5rem;
      color: #000 !important;
    }
    .slick-next::before{
      font-size: 1.5rem;
      color: #000 !important;
    }
  }

  &__allBlogs{
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
      // background-color: yellow;
  }
  &__blogs{
    // background-color: red;
    margin: 2rem 0;

   h3{
    // padding-left: 1rem;
   }

  }

  &__grid{
    @include gridLayout(320px , 1.5rem 0rem );
    align-items: flex-start;
    align-content: start;
     > div{
      height: 100%;
     }
  }
  &__blog{
    
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // max-width: 290px;
    width: 97%;
    margin: auto;
    // margin: 0 1rem !important;
    margin-left: 1.2rem;
    padding-right: 1.5rem;
    img{
      width: 100%;
      height: 235px;
    }
    h6{
      margin: 1rem 0 0.2rem 0;
    }
    p{
      color: var(--color-dark-grey);
    }

    // &:first-of-type{
    //   margin-left: 0;
    // }
  }
}


.blog-header{
  background: url('../../../components/pages/images/blogs/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  // min-height: 90vh;

  h1{
    text-align: center;
    font-size: 8rem;
    text-transform: uppercase;
    font-weight: 900;

    @media (max-width:798px) {
      font-size: 3.5rem;
    }
    @media (max-width:998px) {
      font-size: 2rem;
    }
  }

  &__blogs{
    display: grid;
    @include gridLayout(150px , 2.5rem);

    margin: 2rem auto;
    padding: 1rem;
  }
  &__blog{
    img{
      width: 100%;
      max-height: 240px;

    }
    p{
      font-size: 1.5rem;
      text-align: center;
      font-weight: 600;
      margin: 0.5rem 0;
    }
  }
}

.apperrance-auto{
  appearance: auto !important;
}