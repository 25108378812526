.popup {
  &.backlayer {
    background-color: rgba(63, 184, 225, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }

  &__box {
    // min-width: 90%;
    width: 90%;
    max-width: 45rem;
    margin: 0 auto;
    background-color: var(--white);
    border-radius: 5px;
    padding: 1.5rem;
    box-shadow: 0 5px 20px rgba(63,184,225, 0.7);
    color: var(--color-dark-grey);
    overflow: auto;
    position: relative;
  }

  &__head {
    display: block;
    margin-bottom: 1.5rem;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    position: relative;
    h6{
        padding: 0.5rem 0;
        width: 100%;
        border-bottom:  var(--light-border);
    }
  }

  &__close-btn {
    position: absolute;
    top:0;
    right: 0;
    padding: 1rem;
    font-weight: 600;
    cursor: pointer;
    z-index: 2;
    font-size: 1.3rem;
  }
  &__input{
    color: var(--color-dark-grey);
    margin-bottom: 1.5rem;

    &.input-onerow{
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        grid-gap:  0.5rem 1rem;

        @media (max-width:500px) {
            grid-template-columns: 1fr;
        }
    }

    label{
        font-size: 0.9rem;
        color: var(--color-dark-grey);
    }
  }



//   Offer Miss Out

.offer-miss-out{
    .payment-bar{
        h2{
            font-size: 1.5rem;
        }
    }
}   
}

.input-box{
    width: 100%;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    outline: 1px solid transparent;
    box-shadow: none;
    color: var(--color-dark-grey) !important;
    border: var(--light-border);
    &.dark{

        border: 1px solid rgba(44, 57, 118, 0.9);
        background-color: rgba(20, 31, 80, 1);
    }
    &.light{

    }
    &:focus{
        // outline: 1px solid rgba(44, 57, 118, 0.9) !important;

        // border: none;
        // box-shadow: 0 2px 4px rgba(20, 31, 80, 0.7);

        border: 2px solid var(--color-primary);
    }
    &:focus-visible{
        // outline: 1px solid rgba(44, 57, 118, 0.9) !important;
        // border: none;
        // box-shadow: 0 2px 4px rgba(20, 31, 80, 0.7);
        border: 2px solid var(--color-primary);
    }
    &::placeholder{
     color: var(--color-dark-grey) !important;

    }
}


.grad-button{
    font-family: inherit;   
    font-size: 15px;
    background-image: linear-gradient(to right, #de2ef0, #440bc9);
    color: #fff;    
    text-align: center; 
    padding: 6px;   
    margin-top: 10px;
    min-width: 120px;   
    border: none;
    outline: none;
    transition: all .1s;
    &.no-round{
        border-radius: 0;
    }
    &.little-round{

        border-radius:   8px;
    }
    .rounded{
        border-radius: 50px;
    }

    &:hover{
        transform: scale(1.03);
    }

    &:active{
        transform: scale(0.97);
    }
    
}


.two-center-button{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80%;
    margin: auto;
    button{
        &:last-of-type{
            margin-left: 1rem;
        }
    }
}