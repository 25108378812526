$size :250px;
$gap: 1rem;

@mixin whiteBoxStyle {
  background-color: var(--white);
  padding: 1rem;
  border-radius: 10px;
  color: var(--color-dark-grey);
}

@mixin maxWidthContainer {
  max-width: 70rem;
  margin: auto;
}

@mixin gridLayout($size :250px , $gap: 1rem) {
  display: grid;
  grid-template-columns: repeat(auto-fit , minmax(min($size , 100%) , 1fr));
  grid-gap: $gap;
}