.map-container {
  height: 100vh;
  width: 100%;
  background-color: #eee;
}

.first-submit-btn p.gradeint-text {
  margin-left: 1rem;
}

.map-container-wrap .mapboxgl-ctrl-top-left {
  top: 140px !important;
  left: 50% !important;
  transform: translateX(-50%);
  /* margin-left: -190px !important; */
}

#nitro-heading {
  position: absolute;
  top: 105px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  font-family: "Inter", sans-serif !important;
}

.map-container-wrap .mapboxgl-ctrl-top-left .mapboxgl-ctrl {
  float: right !important;
  background: #232749;
}

.map-container-wrap .mapboxgl-ctrl-top-left > .mapboxgl-ctrl-group:not(:empty) {
  padding: 3.5px !important;
}

.map-container-wrap .mapboxgl-ctrl-top-left > .mapboxgl-ctrl {
  clear: none;
}

.map-container-wrap
  svg.mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search {
  fill: #fff !important;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: url("https://img.icons8.com/ios-filled/20/ffffff/location-off.png") !important;
}

.style-container {
  /* Fixed For Flaoting map satelite */
  position: absolute;
  bottom: 5px;
  left: 10px;
}
.style-container input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.style-container label {
  display: inline-block;
  background-color: #ddd;
  padding: 10px 20px;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border: 2px solid #444444;
  border-radius: 4px;
}

.style-container label:hover {
  background-color: #fff;
}

.style-container input[type="radio"]:focus + label {
  border: 2px solid black;
}

.style-container input[type="radio"]:checked + label {
  font-weight: 700;
  color: black;
  background-color: white;
}

.mapboxgl-ctrl-zoom-in span {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E %3C/svg%3E") !important;
}

.mapboxgl-ctrl-zoom-out span {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E %3C/svg%3E") !important;
}

@media (max-width: 1199px) {
  .earth > svg:first-of-type {
    top: 152px;
  }
  .earth > svg:nth-child(2) {
    top: 152px;
  }
}

@media (max-width: 982px) {
  .mapboxgl-ctrl-geocoder {
    font-size: 18px;
    line-height: 24px;
    font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
    position: relative;
    background-color: #fff;
    width: 100%;
    min-width: 290px !important;
    z-index: 1;
    border-radius: 4px;
    transition: width 0.25s, min-width 0.25s;
  }
  .mapboxgl-ctrl-top-left {
    display: flex;
    flex-direction: row-reverse;
  }
  .earth > svg:first-of-type {
    transform: translate(-660%, 4%) !important;
  }
  .earth > svg:nth-child(2) {
    transform: translateX(460%) !important;
  }
}

@media (max-width: 768px) {
  .mapboxgl-ctrl-top-left {
    display: flex;
    flex-direction: row-reverse;
  }
}
a.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl.mapboxgl-ctrl-group {
  /* //Fix For Floating + - */
  position: absolute;

  bottom: 19px;
  right: 5px;
}

.earth > svg:first-of-type {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 152px;
  left: 50%;
  z-index: 1;
  transform: translateX(-550%);
}

.earth > svg:last-of-type {
  display: none;
}
.earth > svg:nth-child(2) {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 152px;
  left: 50%;
  z-index: 3;
  transform: translateX(405%);
}

@media (max-width: 640px) {
  .mapboxgl-ctrl.mapboxgl-ctrl-group {
    display: flex;
    align-items: center;
  }
  .earth > svg:first-of-type,
  .earth > svg:nth-child(2) {
    top: 160px;
  }
}

@media (max-width: 478px) {
  .earth {
    position: relative;
  }

  .earth > svg:first-of-type {
    /* width: 20px; */
    position: absolute;
    /* height: 20px; */
    transform: translateX(21%);
    z-index: 3;
    top: 110px;
    left: 16rem;
  }
  /* .earth > svg:first-of-type{
    width: 20px;
    position: absolute;
    height: 20px;
    transform: translateX(-846%);
    z-index: 3;
    top: 192px;
  } */
  /* .earth > svg:nth-child(2){
    position: absolute;
    width: 20px;
    height: 20px;
    transform: translateX(-600%);
    top: 192px; */

  .earth > svg:nth-child(2) {
    position: absolute;
    top: 110px;
    /* FONT-WEIGHT: 100; */
  }

  #username .menu-item {
    max-width: 120px;
  }

  #username > span:first-of-type {
    display: block;
    /* width: 77px; */
    /* height: max-content; */
    /* text-align: left !important; */
    font-size: 0.8rem;
    word-break: break-all !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  #nitro-heading {
    width: 90%;
    font-size: 1.3rem;
    margin: auto;
    text-align: center;
  }
}

@media (max-width: 400px) {
  .earth {
    position: relative;
  }
  .earth > svg:first-of-type {
    position: absolute;
    left: 65%;
  }

  .earth > svg:nth-child(2) {
    left: 46%;
  }
  .mapboxgl-ctrl-geocoder {
    min-width: 240px !important;
  }
}
