#map {
  height: 100%;
}

.btns-main {
  /* margin-top: 1rem; */
  /* padding: 0 5rem; */
  /* max-width: 80em; */
  /* margin: auto auto; */
  position: absolute;
  width: 100%;
  padding: 0 1rem;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  /* right: 0; */
  z-index: 999;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.btns-main .gradient-box {
  position: relative;
}

.btns-main .field {
  position: relative;
}

.side-icon {
  width: 100%;
  display: block;
  text-align: left !important;
  /* display: flex; */
  /* justify-content: center; */
}

.side-icon i {
  display: inline-block;
  text-align: center;
}

.confirm-details-sidebar {
  background-size: cover;
  /* background-color: #232749; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* font-size: 1.5em; */
}

.address #pac-input {
  font-size: 16px;
}


.address-location {
  text-align: center;
}

.address {
  text-align: center;
}

.address input {
  font-size: 1rem;
  text-align: center;
}

.two-nest-btn {
  display: flex;
  justify-content: space-between;
  /*  */
}

.first-submit-btn {
  width: max-content;
  /* padding-left: 14px; */
  /* padding-right: 14px; */
}

.first-submit-btn .gradeint-text {
  margin-left: 1rem;
}

.box-det {
  margin: 1.2rem 0;
}

.box-det.margin-0 {
  margin: 0.5rem 0;
}
@media (max-width: 1240px) {
  .first-submit-btn .gradeint-text {
    display: none;
    /* margin-left: 1rem; */
  }
}
@media (max-width: 870px) {
  .confirm-details-page {
    min-height: unset;
    height: 89vh;

    display: grid;
    grid-template-columns: 1fr !important;
    grid-template-rows: 2fr 1fr !important;
    position: relative;
  }
  .mapboxgl-canvas {
    height: 100% !important;
  }
  header {
    /* position: absolute !important; */
    /* top: 0; */
    /* left: 0; */
    /* background: transparent !important; */
  }

  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .sm-pt10 {
    padding-top: 0;
  }

  #map {
    position: absolute;
    top: 0;
  }

  .confirm-details-sidebar {
    border-top-left-radius: 37px;
    border-top-right-radius: 37px;
    /* border-top: 1px solid #fff; */
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
    height: max-content !important;
    position: absolute;
  }

  .submit-btn {
    /* display: block; */
    background-color: var(--btn-bg);
    padding: 12px 25px;
    border-radius: 15px;
    /* width: 48%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem auto;
    margin-top: 1rem;
    /* margin-top: auto; */
  }

  .first-submit-btn .gradeint-text {
    display: none;
    margin-left: 1rem;
  }

  .confirm-details-page h4 {
    max-width: 100%;
    margin-top: 0rem !important;
    font-size: 1.2rem;
  }

  .margin-0 {
    margin-top: 0 !important;
    margin-bottom: 0.5rem !important;
  }

  .center-in-mobile {
    text-align: center;
  }

  .border-field {
    resize: horizontal;
  }

  .two-nest-btn {
    margin-bottom: 2rem;
  }

  .field.long-one {
    background-size: cover;
    /* resize: both; */
    max-height: 100px !important;
    overflow: scroll;
    padding: 1rem 0.5rem;
    padding-top: 4rem;
  }
  .location-search-input {
    /* height: unset !important; */
    /* font-size: 0.9rem; */
    margin-bottom: -0.5rem;
  }
}

@media (min-width: 780px) {
  .miner-boxes {
    width: 80%;
    background-size: cover;
    margin: 0 auto;
  }
  .confirm-details-sidebar {
    margin-top: 90px;
  }
}

#product-tour-sidebar {
  position: fixed !important;
  left: 10%;
  /* transform: translateX(-385%); */
  top: 90px;
  background-color: #232749;
  width: 150px;
  margin-top: 1rem;
  padding: 0.7rem;
  height: 50px;
  /* width: 45px; */
  outline: none;
  border: none;
  border: 1px solid #3f425fc4;
  color: #fff;
  border-radius: 5px;
}

.mapboxgl-ctrl-group button {
  background-color: #232749 !important;
}
.location-search-input {
  height: unset !important;
  font-size: 0.9rem;
}
