
.fl-rt {
  float: right;
}

.fl-lt {
  float: left;
}

.pd-10 {
  padding-left: 12px;
}

.col-md-9 > img {
  width: unset;
}

#cart-page-container {
  padding-top: 0px;
}

.bg-dark-blue {
  background-color: #152752;
}

input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}

.topright-icon {
  position: absolute;
  top: 8px;
  right: 12px;
}

.tabcontent {
  display: none;
}

.tabcontentnew {
  display: none;
}

/* #multi-step-form-container {
  margin-top: 5rem;
} */

.mt-3 {
  margin-top: 2rem;
}

.d-none {
  display: none;
}

.font-normal {
  font-weight: normal;
}

.card-ship-new {
  position: relative;
  width: 100%;
  height: auto;
  padding: 30px;
  color: #fff;
  font-size: 18px;
  background: #07103b;
}

input#number {
  text-align: center;
  border: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 30px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
