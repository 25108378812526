@media (max-width: 982px) {
  .main-page {
    flex-direction: column;
  }
  .main-page-section {
    margin-top: 0rem;
  }
  .main-page .text-side {
    margin-top: 2rem;
  }
  .main-page .text-side h1 {
    max-width: 90%;
    border-bottom: 2px solid #fff;
    padding-bottom: 1.5rem;
  }
  .what-nfm-section {
    padding: 1rem;
  }
  .what-nfm-section h1 {
    margin-bottom: 1rem;
    font-size: 35px;
  }
  .what-nfm {
    flex-direction: column;
    /* padding: 1rem ; */
  }
  p {
    line-height: 1.4;
  }
  .what-nfm-section .what-nfm .what-nfm-text-side p {
    line-height: 1.6;
  }
  .what-nfm-section .what-nfm .what-nfm-text-side {
    margin-right: 0;
  }

  .what-nfm-section .what-nfm.reverse {
    flex-direction: column-reverse;
  }
  .what-nfm-section .what-nfm.reverse .what-nfm-text-side {
    flex: 0.9;
    margin-left: 0;
  }

  #form {
    padding: 1rem;
  }

  .half-fields > a {
    min-width: unset;
    margin-bottom: 2rem;
  }
}

@media (max-width: 500px) {
}
