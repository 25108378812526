
.term-page {
  padding: 90px 1rem;
  .container-term{
    max-width: 70rem;
    margin: 0 auto;
    width: 90%;
  }
  .terms {
    flex-direction: column;

    h1{
      color: var(--color-primary);
      font-size: 3.5rem;
      text-align: center;
      margin: 2rem 0;
    }
    h3{
      margin-top: 1.5rem;

    }
    p{
      color: var(--color-dark-grey);

    }
  }

 
  .nft-terms {
    margin: 4rem 0;
  }
  .nft-terms h2 {
    color: var(--color-primary);
    font-size: 2.5rem;
  }
  
  h3.blue {
    color: var(--color-primary);

    text-align: center;
    font-size: 2rem;
  }
  .terms ul,
  .terms ol {
    padding-left: 1rem;
  }
  .terms li {
    list-style: decimal;
    padding-left: 3px;
  }
  /* style="font-size:30px;margin-bottom:1.5rem" */
  .nft-terms ul{
    padding-left: 1rem;
  }
 ul li{
    list-style: disc;
    line-height: 1.6;
    margin-left: 1rem;
    color: var(--color-dark-grey);
  }
   ol li{
    color: var(--color-dark-grey);

    line-height: 1.6;
    margin: 0.3rem 0;
   }
   ol li b{
    font-weight: bold;
    color: var(--color-dark-grey);

   }
  .nft-terms ul li a{
    color: var(--color-primary);
    &:hover{
      color: inherit;
      text-decoration: underline;
    }
  }
  @media (max-width: 982px) {
    .terms h1 {
      font-size: 2rem;
  
      margin: 2rem 0;
    }
    .terms h3 {
      margin-top: 1.5rem;
    }
  
    .nft-terms {
      margin: 3rem 0;
    }
    .nft-terms h2 {
      font-size: 1.5rem;
    }
  
    h3.blue {
      font-size: 1.35rem;
    }
  }
  
}
