
//Hero Section
.hero-section{
  max-width: 80rem;
  margin: 0 auto 0 auto;

  min-height: 85vh;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  // display: grid;
  // grid-template-columns: repeat(auto-fit , minmax(250px , 1fr));
  // grid-gap: 1.5rem;
  // align-items: center;
  padding: 1.5rem;

  &__text{
    flex: 0.8;
    max-width: 350px;
    margin-right: 2rem;
    h1{
      font-weight: 700;
    }
    p{
      font-size: 1rem;
      line-height: 1.6;
    }
  }

  &__image{
    height: 100%;
    flex:1.4;
    max-height: 100%; 
    max-width: 650px;
    transform: scale(1);
    img{
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width:672px) {
    padding: 1rem;
    padding-top: 90px;
    flex-direction: column-reverse;
    min-height: unset;
    &__text{
      flex: 1;
      margin-right: 0;
      width: 100%;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    &__image{
      flex: 1;
      max-width: 90%;
      transform: scale(1);
    }
  }
  .cta-buttonss{
    display: flex;
    flex-wrap: wrap;
    & > *{
      margin-top: 0.5rem;
      &:first-of-type{
      margin-right: 0.8rem;
    }
    }
  }
  .home-play-btn {
    display: flex;
    align-items: center;
    // padding: 0.5rem 1.2rem;
    // width: 160px;
    // border-radius: 8px;
    // color: #fff;
    // font-size: 1.1rem;
    // margin-bottom: 1rem;

    img {
      margin-right: 0.5rem;
      height: 20px;
      width: 20px;
    }
  }

  // img{
  //   width: auto;
  //   height: auto;
  // }
}



// THE MISSION
.the-mission{
  &__container{
    @include maxWidthContainer;
    @include gridLayout(250px , 1.5rem);

    align-items: center;

    padding: 2rem 0;

  }

  &__text{
    max-width: 550px;
    padding: 0 2rem;
    > *{
      line-height: 1.6;
      // word-break: break-all;
      text-align: justify;
      text-align-last: left;  
      hyphens: auto;
      -webkit-hyphens: auto;

      @media (max-width:550px) {
        word-break: break-all;
        
      }
    }
    @media (max-width:550px) {
      padding: 0;
      
    }
    p.big-bold{
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.5;
    }
    p{
      font-size: 0.95rem;
      margin-top: 1.5rem;
    }
  }
  &__image{

  }
}


// IoT Mass Adoption
.made-for-iot{
  &__stats{
    @include maxWidthContainer;
    @include whiteBoxStyle;

    @include gridLayout(150px , 1rem);

    align-items: center;

    margin: 2rem auto;
    padding: 2rem 1.2rem;

    @media (max-width:500px) {
        grid-template-columns: 1fr;
        
    }
  }
  &__stat{
    text-align: center;
    padding: 1rem;
    h5{
      margin-bottom: 0.2rem;
      font-weight: 600;
      
    }
    p.big-bold{
      font-weight: 700;
      font-size: 1.5rem;
    }
    p{
      font-weight: 500;
    }
  }
}

// Transform the world
.transform-world{

  @include maxWidthContainer;

  &__possible{
    @include whiteBoxStyle;

    display: grid !important;
    @include gridLayout(250px , 1.5rem);

    align-items: center;
    padding: 2rem ;
    margin: 1.5rem auto;

    h4{
      font-weight: 700;

    }
    p.big-bold{
      font-weight: 700;
      font-size: 1.5rem;
    }
    p{
      max-width: 450px;
      font-size: 1rem;
      line-height: 1.6
    }
  }


  .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 30px;
    height: 30px;
    z-index: 99;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: var(--color-dark-grey);
    
    border: none;
    outline: none;
    background: transparent;
    // border: var(--primary-border);
    // border-radius:50%;
  }
  .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 30px;
    line-height: 1;
    opacity: .75;
    color: var(--color-dark-grey);
  }
}


//Nitro World
.discover-nitro{
  @include maxWidthContainer;

  &__boxes{
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(min(220px, 100%), 1fr));
    // grid-gap: 1rem 1rem;
    margin: 2rem auto;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;


  }
  &__box{
    max-width: 240px;
    margin: 1rem;
    position: relative;
    cursor: pointer;
    opacity: .9;
    transition: all .2s;
    &:hover{
      opacity: 1;
      transform: scale(1.05);
    }
    img{
      width: 100%;
      height: 100%;
    }
    p{
      position: absolute;
      bottom:15px;    
      width: 100%;  
      text-align: center;
      font-weight: 700;
      color: var(--white);
      font-size: 1.3rem;
    }
  }

  @media (max-width:540px) {
    &__boxes{
      @include gridLayout(150px , 1rem);

    }

    &__box{
      max-width: unset;
      margin: 0;
      p{
        font-size: 0.95rem;
      }
    }
  }
}


// Resources
.resources{
  @include maxWidthContainer;
  &__cards {
    @include gridLayout(220px , 1.5rem 2rem);
    margin: 2rem auto;
  }

  &__card {
    @include whiteBoxStyle;
    border: var(--light-border);

    text-align: center;
    display: flex;
    flex-direction: column;
    /* padding: 2rem 1.2rem; */
    p {
      text-align: center;
      font-size: 1rem;
      margin: 1.5rem auto 0rem auto;
    }
    &:hover{
      background-color: var(--sidebar-bg);

    }

    @media (max-width:550px) {
    
      @include gridLayout(150px , 1rem);

      align-items: center;
      p{
        margin-top: 0;
      }
    }
  }
  &__image {
    height: 100%;
    margin: auto;
    padding: 0.8rem 0;
    img {
      height: 100%;
    }
  }

  
}

.head-second{
  color: var(--color-dark-grey);
}
// Media Partners
.media-partners{

  @include maxWidthContainer;
  padding: 2rem 1rem !important; 
  &__logos{
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 1rem;
    flex-wrap: wrap;

    @media (max-width:560px) {
      @include gridLayout(150px);

    
      }
  }

  &__logo{
    max-width: 190px;
    margin: 1rem;
    transition: all .2s;
    img{
      width: 100%;
    }
    &:hover{

      filter: drop-shadow(0px 13px 40px rgba(60, 60, 60, 0.08));
    }
  }
}


//Join Community
.join-community{
  @include maxWidthContainer;
  @include whiteBoxStyle;
  
  padding: 1rem;
  h2{
    padding-top: 0;
  }

}
//Stay Tuned Box
.stay-updated{
  text-align: center;
  @include maxWidthContainer;

  h3{
    font-weight:600;
    margin-bottom:0.8rem;
  }
  p{
    margin-bottom: 1rem;
    line-height:1.6;
    font-size:1rem;
  }
  .form-box{
    max-width:550px;
    position:relative;
    margin:auto;
  }

  .subscribe-form{
    max-width: 550px;
    margin: auto;
    display: flex;
    align-items: flex-start;

    > .ant-form-item{
      width: 100%;
      // height: 40px;
      // > *{
      //   height: 100%;
      //   > *{
      //     height: 100%;
      //   }
      // }

      input[type="text"]{
        height: 40px;
        font-weight: 600;
      }
    }

    

    > button{
      height: 40px;
      display: grid;
      place-content: center;
      // width: 100%;
      z-index: 2;
      outline: none;
      padding: 0.5rem 1.5rem;
      border: 1px solid var(--color-primary);
      background-color: var(--color-primary);
      color: var(--white);
      // font-weight: 500;
      border-radius: 5px;
      transition: all .2s;
      margin-left: -1rem;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      font-weight: 600;
    }
  }
}
.form-box{
  overflow: hidden;
  --border-radius: 5px; 
  border-radius: var(--border-radius);
  &--input{
    background-color: #fff;
    color: #000 !important;
    border-radius: var(--border-radius);
    padding: 0.5rem 1.5rem;
    overflow: hidden;
    border: var(--light-border);
    outline: none;
    font-weight: 400;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);

    height: 33px;
    &:focus-within{
        border: none;
        outline: none;
        transform: scale(1);
    }
    &::placeholder{
        color: #000 ;
    }
}
&--submit{
  height: 32px;
  display: grid;
  place-content: center;

   @media (min-width:560px) {
    
       position: absolute;
       right: -1px;
       top: 0;
       border-bottom-right-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: max-content;
        margin-top: 0;
   }
    color: #fff;
    background: var(--color-primary);
    padding: 0.5rem 1.5rem;
    border: 1px solid var(--color-primary);
    outline: none;
    cursor: pointer;
    border-radius: var(--border-radius);
    width: 100%;
    margin-top: 1.2rem;
    margin-bottom: 0;
    transition: .2s all;

    &:hover{
     filter: brightness(95%);   
    }
}

@media (max-width:600px) {
    &--input{
    padding: 0.5rem 0.5rem;
        
    }
    &--submit{
    padding: 0.5rem 0.5rem;

    }
}
}

// Contact Us
.contact-section {
  @include maxWidthContainer;
  margin: 2rem auto 0 auto;

  @include whiteBoxStyle;

  /* padding-bottom: 2rem; */

  .contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    width: 90%;
    max-width: 85rem;
    margin: 0 auto;
    padding-bottom: 2rem;
    padding-top: 1rem;

    .image-logo {
      flex: 0.9;
      text-align: center;
      margin-top: 1rem;
      padding: 2rem;
      background-color: var(--sidebar-bg);
    }

    .form-side {
      flex: 1.1;
      width: 100%;

      form {
        input,textarea{
          width: 100%;
        }
      }
      label {
        display: none;
      }
      .half-field {
        @include gridLayout(200px , 0 25px);

        margin-top: 1.5rem;
        .form-field {
          margin-bottom: -15px !important;
        }
      }
      .ant-form-item-has-error:is(input, textarea) {
         border-color: rgb(255, 129, 130);

      }
    }
  }
}
